import { useContext, useEffect, useState, useRef } from 'react'
import { StateContext } from './AppContext'
import ChartBarDashboard from './ChartBarDashboard'
import ChartPieDashboard from './ChartPieDashboard'
import ExportPdfCsv from './ExportPdfCsv'
import axios from 'axios'
import '../styles/Dashboard.css'

function Dashboard() {
    const stateCont = useContext(StateContext);
    const [projects, setProjects] = useState([]);
    const [idxProject, setIdxProject] = useState(0);
    const [project, setProject] = useState({ projet: '', type: '', dateLivraison: '' });
    const [tachesProject, setTachesProject] = useState([]);
    const [avancement, setAvancement] = useState(0);
    const formatAvancement = avancement.toFixed(2);
    const pdfRef = useRef();

    useEffect(() => {
        const getProjects = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
                withCredentials: true
            }).then((res) => {
                if (res.data.projectTaches !== null && res.data.projectTaches.length > 0)
                    setProjects(res.data.projectTaches)
            })
        }
        getProjects()
    }, [])

    useEffect(() => {
        if (projects.length > 0) {
            const getProjectById = async () => {
                await axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/projectTache/${projects[idxProject]._id}`,
                    withCredentials: true
                }).then((res) => {
                    setProject({ projet: res.data.projectTache[0].projet, type: res.data.projectTache[0].type, dateLivraison: res.data.projectTache[0].dateLivraison })
                })
            }
            getProjectById()
        }
    }, [projects, idxProject])

    useEffect(() => {
        if (projects.length > 0) {
            const getTachesProject = async () => {
                await axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/tache/project/${projects[idxProject]._id}`,
                    withCredentials: true
                }).then((res) => {
                    setTachesProject(res.data.taches)
                })
            }
            getTachesProject()
        }
    }, [projects, idxProject])

    useEffect(() => {
        let avance = 0;
        if (tachesProject.length > 0) {
            tachesProject.forEach(tache => {
                avance += Number(tache.avancement);
            });
            setAvancement(avance / tachesProject.length)
        } else {
            setAvancement(0);
        }
    }, [tachesProject])



    return (
        <div id="Dashboard" className="tabcontent">
            <div ref={pdfRef}>
                <div className="dashSelect">
                    <label>Projets</label>
                    <select className="form-select" name="idresponsable" onChange={(e) => setIdxProject(e.target.value)}>
                        {
                            projects.map((project, idProject) => <option key={idProject} value={idProject}>{project.projet}</option>)
                        }
                    </select>
                </div>
                <div className="dashTable1">
                    <table className="dashContenu">
                        <thead>
                            <tr>
                                <th>Nom Projet</th>
                                <th>Type Projet</th>
                                <th>Date de Livraison</th>
                                <th>Pourcentage Avancement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{project.projet}</td>
                                <td>{project.type}</td>
                                <td>{stateCont.formatDate(new Date(project.dateLivraison))}</td>
                                <td>{formatAvancement}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ChartBarDashboard tachesProject={tachesProject} />
                <div className='dashChart'>
                    <div className="dashTable2">
                        <p>Listes des responsables</p>
                        <div className="dashTable">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Responsable</th>
                                        <th>Tâche</th>
                                        <th>Avancement</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {
                                        tachesProject.map((tache, idTache) => {
                                            return (
                                                <tr key={idTache}>
                                                    <td>{tache.responsable}</td>
                                                    <td>{tache.tache}</td>
                                                    <td>{tache.avancement}%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='dashChartPie'>
                        <p>Statut des tâches</p>
                        <ChartPieDashboard tachesProject={tachesProject} />
                    </div>
                </div>
            </div>
            <div className="dashExport">
                <ExportPdfCsv dom={pdfRef} projetTache={tachesProject} formatAvancement={formatAvancement} />
            </div>
        </div >
    )
}

export default Dashboard
