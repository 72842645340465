import DateWeekDays from "./DateWeekDays";
import BlockWeekHour from "./BlockWeekHour";
import { getStartWeek } from "../Helper";
import '../styles/ViewWeek.css';
import { StateContext } from "./AppContext";
import { useContext, useEffect, useState } from "react";
import { MONTHNAMES } from "../Constants";
import axios from "axios";

function ViewWeek() {
    const [refresh, setRefresh] = useState(false)
    const stateCont = useContext(StateContext)
    const [eventsWeek, seteventsWeek] = useState([])
    let startDate = getStartWeek(stateCont.day, MONTHNAMES[stateCont.month], stateCont.year);
    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/api/event`,
            withCredentials: true
        }).then((res) => {
            seteventsWeek(res.data.events)
        })

    }, [refresh])

    const refreshevent = () => {
        setRefresh(!refresh)
    }
    return (
        <div className="d-flex ">
            <table className="table layoutFixed mx-2 border-week">
                <tbody>
                    <DateWeekDays startDate={startDate} />
                    <BlockWeekHour startDate={startDate} events={eventsWeek} refreshevent={refreshevent} />
                </tbody>
            </table>
        </div>
    );
}

export default ViewWeek