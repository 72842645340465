import { useContext, useEffect, useState } from "react";
import { StateContext } from './AppContext';
import axios from 'axios';

function ModalLog({ handleCloseModal }) {
    const stateCont = useContext(StateContext)
    const [formObjLog, setFormObjLog] = useState({ idTache: stateCont.task, date: '', time: '', detail: '', avancement: '0', user: stateCont.userLogged.id });

    useEffect(() => {
        // Met à jour currentTime toutes les secondes
        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        let formattedHours = hours.toString().padStart(2, '0');
        let formattedMinutes = minutes.toString().padStart(2, '0');
        let timeFormat = `${formattedHours}:${formattedMinutes}`;
        setFormObjLog({ ...formObjLog, date: date, time: timeFormat })
    }, []);



    const submitLog = async () => {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/log`,
            data: formObjLog,
            withCredentials: true
        }).then((res) => { handleCloseModal(); })
            .catch((err) => { })
    }

    return (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Veuillez insérer les détails du Log</h5>
                        <button type="button" className="btn-close" onClick={() => handleCloseModal()} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <label htmlFor="detail">Détail</label>
                            <textarea type="text" name="detail" id="detail" placeholder="Detail" value={formObjLog.detail} required={true} onChange={(e) => setFormObjLog({ ...formObjLog, detail: e.target.value })} className="form-control" style={{ border: "1px solid #ccc", width: "60vh", height: "16vh" }} />
                        </div>
                        <div>
                            <label htmlFor="avancement">Avancement</label>
                            <select name="avancement" id="avancement" value={formObjLog.avancement} required={true} onChange={(e) => setFormObjLog({ ...formObjLog, avancement: e.target.value })} className="form-select">
                                <option value="0">0%</option>
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                                <option value="60">60%</option>
                                <option value="70">70%</option>
                                <option value="80">80%</option>
                                <option value="90">90%</option>
                                <option value="100">100%</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => handleCloseModal()}>Fermer</button>
                        <button type="button" className="btn btn-primary" onClick={() => submitLog()}>Enregistrer les changements</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalLog