import { useContext } from 'react';
import { HOURS, MONTHNAMES } from '../Constants';
import { StateContext } from './AppContext';
import Cell from './Cell';
import { getStartWeek } from '../Helper';
import { rrulestr } from 'rrule';

function BlockWeekHour({ startDate, events }) {
    const stateCont = useContext(StateContext)
    let startWeek = getStartWeek(stateCont.day, MONTHNAMES[stateCont.month], stateCont.year);
    let startDateTime = stateCont.formatDate(startWeek) + " 00:00";
    let endWeek = startWeek;
    endWeek.setDate(endWeek.getDate() + 6);
    let endDateTime = stateCont.formatDate(endWeek) + " 23:59";

    let arrAllEventsToDisplay = [];
    let startWeekDateTime = new Date(startDateTime);
    let endWeekDateTime = new Date(endDateTime);

    events.forEach((event) => {
        let startDateTime = new Date(event.startDateTime);
        let eventHour = startDateTime.getHours().toString();
        let eventMinutes = startDateTime.getMinutes().toString();
        eventHour = ("0" + eventHour.toString()).slice(-2);
        eventMinutes = ("0" + eventMinutes.toString()).slice(-2);
        let startHourStartDateTime = eventHour + ":" + eventMinutes;
        if (event.recurringRule) {
            let dtStart = stateCont.formatTzDate(startDateTime);
            let stringRule = event.recurringRule + "\nDTSTART:" + dtStart;
            const rule = rrulestr(stringRule);
            const allRecurrency = rule.between(startWeekDateTime, endWeekDateTime);
            allRecurrency.forEach((recurrency) => {
                let setStartDateTime = stateCont.formatDate(recurrency) + " " + eventHour + ":" + eventMinutes;
                let setEndDateTime = "";
                if (event.allDay) {
                    setStartDateTime = stateCont.formatDate(recurrency) + " 00:00";
                    setEndDateTime = stateCont.formatDate(recurrency) + " 23:59";
                } else {
                    let endDateTime = new Date(event.endDateTime);
                    let eventHourEndDateTime = endDateTime.getHours().toString();
                    let eventMinutesEndDateTime = endDateTime.getMinutes().toString();
                    eventHourEndDateTime = ("0" + eventHourEndDateTime.toString()).slice(-2);
                    eventMinutesEndDateTime = ("0" + eventMinutesEndDateTime.toString()).slice(-2);
                    let endHourEndDateTime = eventHourEndDateTime + ":" + eventMinutesEndDateTime;
                    if (stateCont.diffMinute(startHourStartDateTime, endHourEndDateTime) <= 0) {
                        endDateTime = recurrency;
                        eventHourEndDateTime = "23";
                        eventMinutesEndDateTime = "59";
                    }
                    setEndDateTime = stateCont.formatDate(recurrency) + " " + eventHourEndDateTime + ":" + eventMinutesEndDateTime;
                }
                let newEvent = {
                    startDateTime: setStartDateTime,
                    endDateTime: setEndDateTime,
                    id: event.id,
                    title: event.title,
                    eventDay: setStartDateTime,
                    eventStartDateTime: (event.startDateTime) ? event.startDateTime : '',
                    eventEndDateTime: (event.endDateTime) ? event.endDateTime : '',
                    allDay: (event.allDay) ? event.allDay : 0,
                    location: (event.location) ? event.location : '',
                    description: (event.description) ? event.description : '',
                    createdOn: (event.createdOn) ? event.createdOn : '',
                    createdBy: (event.createdBy) ? event.createdBy : '',
                    recurringRule: (event.recurringRule) ? event.recurringRule : '',
                    timeZone: (event.timeZone) ? event.timeZone : '',
                    parentId: (event.parentId) ? event.parentId : '',
                    updatedOn: (event.updatedOn) ? event.updatedOn : '',
                    updatedBy: (event.updatedBy) ? event.updatedBy : ''
                };
                arrAllEventsToDisplay.push(newEvent);
            });
        } else {
            let setStartDateTime = stateCont.formatDate(startDateTime) + " " + eventHour + ":" + eventMinutes;
            let setEndDateTime = "";
            if (event.allDay) {
                setStartDateTime = stateCont.formatDate(startDateTime) + " 00:00";
                setEndDateTime = stateCont.formatDate(startDateTime) + " 23:59";
            } else {
                let endDateTime = new Date(event.endDateTime);
                let eventHourEndDateTime = endDateTime.getHours().toString();
                let eventMinutesEndDateTime = endDateTime.getMinutes().toString();
                eventHourEndDateTime = ("0" + eventHourEndDateTime.toString()).slice(-2);
                eventMinutesEndDateTime = ("0" + eventMinutesEndDateTime.toString()).slice(-2);
                let endHourEndDateTime = eventHourEndDateTime + ":" + eventMinutesEndDateTime;
                if (stateCont.diffMinute(startHourStartDateTime, endHourEndDateTime) <= 0) {
                    endDateTime = startDateTime;
                    eventHourEndDateTime = "23";
                    eventMinutesEndDateTime = "59";
                }
                setEndDateTime = stateCont.formatDate(endDateTime) + " " + eventHourEndDateTime + ":" + eventMinutesEndDateTime;
            }

            if ((new Date(setStartDateTime) > new Date(startWeekDateTime)) && (new Date(setEndDateTime) < new Date(endWeekDateTime))) {
                let newEvent = {
                    startDateTime: setStartDateTime,
                    endDateTime: setEndDateTime,
                    id: event.id,
                    title: event.title,
                    eventDay: setStartDateTime,
                    eventStartDateTime: (event.startDateTime) ? event.startDateTime : '',
                    eventEndDateTime: (event.endDateTime) ? event.endDateTime : '',
                    allDay: (event.allDay) ? event.allDay : 0,
                    location: (event.location) ? event.location : '',
                    description: (event.description) ? event.description : '',
                    createdOn: (event.createdOn) ? event.createdOn : '',
                    createdBy: (event.createdBy) ? event.createdBy : '',
                    recurringRule: (event.recurringRule) ? event.recurringRule : '',
                    timeZone: (event.timeZone) ? event.timeZone : '',
                    parentId: (event.parentId) ? event.parentId : '',
                    updatedOn: (event.updatedOn) ? event.updatedOn : '',
                    updatedBy: (event.updatedBy) ? event.updatedBy : ''
                }
                arrAllEventsToDisplay.push(newEvent);
            }

        }
    });

    // sort arrAllEventsToDisplay
    arrAllEventsToDisplay.sort(function (a, b) {
        return new Date(a.eventDay) - new Date(b.eventDay);
    });

    // console.log('arrAllEventsToDisplay', arrAllEventsToDisplay);

    let levelStartDate = startWeekDateTime;
    let levelEndDate = new Date(startWeekDateTime);
    levelEndDate.setDate(levelEndDate.getDate() + 6);
    let level = { "distanceOrigin": 0, "startDate": stateCont.formatDate(levelStartDate) + " 00:00", "endDate": stateCont.formatDate(levelEndDate) + " 23:59", distanceY: "1.00" };
    let arrEventsToDisplay = [];
    let countEvents = arrAllEventsToDisplay.length;

    do {
        let initialLength = arrAllEventsToDisplay.length;
        for (let k = 0; k < arrAllEventsToDisplay.length; k++) {
            let start = new Date(arrAllEventsToDisplay[k].startDateTime);
            let end = new Date(arrAllEventsToDisplay[k].endDateTime);
            let levelStart = new Date(level.startDate);

            // 1hour -> 1 cell , 10 min ->1/6 cell
            if (stateCont.formatDate(start) !== stateCont.formatDate(end)) {
                let appendEvent = {
                    start: arrAllEventsToDisplay[k].startDateTime,
                    end: arrAllEventsToDisplay[k].endDateTime,
                    distanceX: 0,
                    startDateTime: arrAllEventsToDisplay[k].eventStartDateTime,
                    endDateTime: arrAllEventsToDisplay[k].eventEndDateTime,
                    id: arrAllEventsToDisplay[k].id,
                    title: arrAllEventsToDisplay[k].title,
                    allDay: arrAllEventsToDisplay[k].allDay,
                    location: arrAllEventsToDisplay[k].location,
                    description: arrAllEventsToDisplay[k].description,
                    createdOn: arrAllEventsToDisplay[k].createdOn,
                    createdBy: arrAllEventsToDisplay[k].createdBy,
                    recurringRule: arrAllEventsToDisplay[k].recurringRule,
                    timeZone: arrAllEventsToDisplay[k].timeZone,
                    parentId: arrAllEventsToDisplay[k].parentId,
                    updatedOn: arrAllEventsToDisplay[k].updatedOn,
                    updatedBy: arrAllEventsToDisplay[k].updatedBy,
                    startHour: arrAllEventsToDisplay[k].startDateTime.split(' ')[1],
                    endHour: arrAllEventsToDisplay[k].endDateTime.split(' ')[1],
                    distanceY: level.distanceY,
                    height: 0.5,
                    cellDate: arrAllEventsToDisplay[k].startDateTime.split(' ')[0].toString(),
                    cellHour: "11:00 PM"
                }
                arrEventsToDisplay.push(appendEvent);
                level.distanceY = Number(level.distanceY) + 0.5;
                arrAllEventsToDisplay.splice(k, 1);
                break;
            } else if (start.getTime() === levelStart.getTime()) {
                let getHours = end.getHours() - start.getHours();
                let getMinutes = end.getMinutes() - start.getMinutes();
                let heightCell = getHours + getMinutes / 60;
                let cellHour = (Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) !== 12) ? Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) % 12 : 12;
                let amOrPm = (Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) > 11) ? "PM" : "AM";
                let appendEvent = {
                    start: arrAllEventsToDisplay[k].startDateTime,
                    end: arrAllEventsToDisplay[k].endDateTime,
                    distanceX: level.distanceOrigin,
                    startDateTime: arrAllEventsToDisplay[k].eventStartDateTime,
                    endDateTime: arrAllEventsToDisplay[k].eventEndDateTime,
                    id: arrAllEventsToDisplay[k].id,
                    title: arrAllEventsToDisplay[k].title,
                    allDay: arrAllEventsToDisplay[k].allDay,
                    location: arrAllEventsToDisplay[k].location,
                    description: arrAllEventsToDisplay[k].description,
                    createdOn: arrAllEventsToDisplay[k].createdOn,
                    createdBy: arrAllEventsToDisplay[k].createdBy,
                    recurringRule: arrAllEventsToDisplay[k].recurringRule,
                    timeZone: arrAllEventsToDisplay[k].timeZone,
                    parentId: arrAllEventsToDisplay[k].parentId,
                    updatedOn: arrAllEventsToDisplay[k].updatedOn,
                    updatedBy: arrAllEventsToDisplay[k].updatedBy,
                    startHour: arrAllEventsToDisplay[k].startDateTime.split(' ')[1],
                    endHour: arrAllEventsToDisplay[k].endDateTime.split(' ')[1],
                    distanceY: (start.getMinutes() / 60).toFixed(2),
                    height: heightCell.toFixed(2),
                    cellDate: arrAllEventsToDisplay[k].startDateTime.split(' ')[0].toString(),
                    cellHour: cellHour + ":00 " + amOrPm
                }
                arrEventsToDisplay.push(appendEvent);
                level.startDate = arrAllEventsToDisplay[k].endDateTime;
                arrAllEventsToDisplay.splice(k, 1);
                break;
            } else if (start > levelStart) {
                let getHours = end.getHours() - start.getHours();
                let getMinutes = end.getMinutes() - start.getMinutes();
                let heightCell = getHours + getMinutes / 60;
                let cellHour = (Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) !== 12) ? Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) % 12 : 12;
                let amOrPm = (Number(arrAllEventsToDisplay[k].startDateTime.split(' ')[1].split(':')[0]) > 11) ? "PM" : "AM";
                let appendEvent = {
                    start: arrAllEventsToDisplay[k].startDateTime,
                    end: arrAllEventsToDisplay[k].endDateTime,
                    distanceX: level.distanceOrigin,
                    startDateTime: arrAllEventsToDisplay[k].eventStartDateTime,
                    endDateTime: arrAllEventsToDisplay[k].eventEndDateTime,
                    id: arrAllEventsToDisplay[k].id,
                    title: arrAllEventsToDisplay[k].title,
                    allDay: arrAllEventsToDisplay[k].allDay,
                    location: arrAllEventsToDisplay[k].location,
                    description: arrAllEventsToDisplay[k].description,
                    createdOn: arrAllEventsToDisplay[k].createdOn,
                    createdBy: arrAllEventsToDisplay[k].createdBy,
                    recurringRule: arrAllEventsToDisplay[k].recurringRule,
                    timeZone: arrAllEventsToDisplay[k].timeZone,
                    parentId: arrAllEventsToDisplay[k].parentId,
                    updatedOn: arrAllEventsToDisplay[k].updatedOn,
                    updatedBy: arrAllEventsToDisplay[k].updatedBy,
                    startHour: arrAllEventsToDisplay[k].startDateTime.split(' ')[1],
                    endHour: arrAllEventsToDisplay[k].endDateTime.split(' ')[1],
                    distanceY: (start.getMinutes() / 60).toFixed(2),
                    height: heightCell.toFixed(2),
                    cellDate: arrAllEventsToDisplay[k].startDateTime.split(' ')[0].toString(),
                    cellHour: cellHour + ":00 " + amOrPm
                }
                arrEventsToDisplay.push(appendEvent);
                level.startDate = arrAllEventsToDisplay[k].endDateTime;
                arrAllEventsToDisplay.splice(k, 1);
                break;
            }
        }

        if (initialLength === arrAllEventsToDisplay.length) {
            level.distanceOrigin = level.distanceOrigin + 1;
            level.startDate = stateCont.formatDate(levelStartDate) + " 00:00";
            level.endDate = stateCont.formatDate(levelEndDate) + " 23:59";
        }
    } while (countEvents !== arrEventsToDisplay.length);


    let allRows = HOURS.map((hour) => {
        let rowCells = [];
        let currDate = startDate;
        for (let j = 0; j < 7; j++) {
            let currDateFormat = stateCont.formatDateCalendar(currDate);
            rowCells.push(<Cell key={currDateFormat + hour} numero={j + 1} date={currDateFormat} hour={hour} classCell="cellWeek" events={arrEventsToDisplay} />);
            currDate.setDate(currDate.getDate() + 1);
        }
        currDate.setDate(currDate.getDate() - 7);
        // console.log(rowCells);
        return (
            <tr key={startDate + hour.toString()} height="60px" className="text-center border">
                <Cell content={hour} classCell="hourCell text-nowrap" />
                {rowCells}
            </tr>
        )
    });

    return (
        <>
            {allRows}
        </>
    );
}

export default BlockWeekHour