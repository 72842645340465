import Design1 from '../components/Design1'
import Design2 from '../components/Design2'
import Design3 from '../components/Design3'
import '../styles/Demo2.css'

const MyDemo = () => {
    return (
        <>
            <Design1 />
            <Design2 />
            <Design3 />
        </>
    )
}

export default MyDemo