const Pdc = () => {
    return (
        <div className='container'>
            <br />
            <div>
                <img src="./img/rougesmiley.png" alt="Avatar" width={80} />
            </div>
            <br />
            <h1 className='text-center'>Politique de confidentialité</h1>
            <p className='ident-text'>Cette politique de confidentialité ("Politique") couvre notre collecte, notre utilisation et notre partage de "Données personnelles" (définies ci-dessous) et s'applique à tous les sites Web, produits, services, ainsi qu'à toutes les applications mobiles (collectivement, les "Services") fournis par LANZOcode ou tout membre de sa famille corporative. Les termes tels que « nous » et « notre » font référence à LANZOcode et à chacune de ses sociétés affiliées.</p>
            <p>Cette politique s'applique si vous êtes un client individuel réel ou potentiel, quelle que soit votre nationalité ou votre résidence. Elle ne s'applique pas aux entreprises/organisations (bien qu'elle puisse s'appliquer à des individus spécifiques au sein de ces entreprises/organisations). Il ne couvre pas non plus la manière dont vous ou d'autres utilisateurs pouvez utiliser ou partager les données qu'ils collectent à l'aide de nos Services.</p>
            <p>En accédant ou en utilisant les Services, vous acceptez cette Politique. En acceptant la Politique, vous certifiez également que vous avez l'âge légal de la majorité dans la juridiction où vous résidez.</p>
            <p>Cette politique peut changer de temps à autre. Votre utilisation continue de ce site Web et/ou de nos Services après que nous ayons apporté des modifications est considérée comme une acceptation de ces modifications. Nous vous invitons ainsi à vérifier périodiquement la politique pour les mises à jour.</p>
            <p>Cette politique s'applique aux Données Personnelles que nous collectons :</p>
            <ul>
                <li style={{ listStyleType: "circle" }}>Sur nos sites Web.</li>
                <li style={{ listStyleType: "circle" }}> Dans les e-mails, SMS et autres messages électroniques échangés entre nous et/ou via nos sites Web.</li>
                <li style={{ listStyleType: "circle" }}> Lorsque vous interagissez avec des publicités et des applications de sites Web et de services tiers apparaissant sur nos sites Web. Cependant, il ne couvre aucun aspect de votre interaction avec un site tiers (même si vous y accédez via notre site Web) ou toute collecte ou utilisation de données par ces tiers.</li>
            </ul>
            <h5>Qu'est-ce que les données personnelles ?</h5>
            <p>Toutes les données ne sont pas des "Données personnelles". Aux fins de la présente politique, le terme « données personnelles » désigne les informations qui, agissant seules ou avec d'autres éléments de données dont nous disposons, sont suffisantes pour vous identifier en tant que personne physique spécifique. Des exemples d'éléments de données personnelles peuvent inclure : un nom, un numéro d'identification, des données de localisation, un identifiant en ligne ou un ou plusieurs facteurs spécifiques à votre identité physique, physiologique, génétique, mentale, économique, culturelle ou sociale en tant que personne physique particulière.</p>
            <p>Bien que plus de détails soient fournis ci-dessous, nous utilisons généralement les informations que nous collectons à votre sujet (ou que vous nous fournissez), y compris les données personnelles :</p>
            <ul>
                <li style={{ listStyleType: "circle" }}> Pour vous présenter notre (nos) site(s) Web et son contenu.</li>
                <li style={{ listStyleType: "circle" }}> Pour vous permettre de participer à des fonctions interactives sur nos sites Web.</li>
                <li style={{ listStyleType: "circle" }}> Pour vous fournir les informations ou les Services que vous nous demandez.</li>
                <li style={{ listStyleType: "circle" }}> Pour vous fournir des avis concernant votre compte, y compris des avis d'expiration et de renouvellement.</li>
                <li style={{ listStyleType: "circle" }}> Pour exécuter nos obligations et faire valoir nos droits découlant de tout contrat conclu entre vous et nous, y compris pour la facturation et le recouvrement.</li>
                <li style={{ listStyleType: "circle" }}> Pour vous informer des changements apportés à notre/nos site(s) Web ou à tout service que nous offrons ou fournissons par son intermédiaire.</li>
                <li style={{ listStyleType: "circle" }}> Pour remplir toute autre finalité pour laquelle vous les fournissez.</li>
                <li style={{ listStyleType: "circle" }}> De toute autre manière que nous pouvons décrire quand vous nous fournissez les informations.</li>
                <li style={{ listStyleType: "circle" }}> Pour toute autre fin compatible avec votre consentement.</li>
            </ul>
            <p>Nous pouvons collecter et traiter les catégories d'informations suivantes, dont certaines ou toutes peuvent inclure des données personnelles :</p>
            <b><span>Données de compte</span>: </b> Nous pouvons obtenir et traiter des données concernant tout processus d'inscription ou d'abonnement concernant l'un de nos Services. Les données de compte peuvent être obtenues et traitées dans le but d'exploiter notre ou nos sites Web, de fournir nos services de la manière que vous avez demandée, d'assurer la sécurité de nos services, de prévenir ou d'enquêter sur les attaques et/ou les fraudes, de conserver des sauvegardes de nos bases de données et communiquer avec vous.
            <b><span>Données de contenu utilisateur</span>: </b> Nous pouvons également collecter et traiter les informations que vous publiez pour publication sur nos sites Web ou via l'utilisation de nos services en tant que contributions d'utilisateurs - par exemple, et si disponible, en soumettant une publication de blog ou un ticket d'assistance.
            <p>Vous devez généralement considérer vos données de contenu utilisateur que vous partagez avec ce site Web comme étant de nature publique et vous ne devez pas partager d'informations que vous ne voudriez pas que le grand public connaisse. La discrétion est un élément clé de vos responsabilités. De plus, si vous supprimez vos contributions d'utilisateur de nos sites Web, des copies de vos contributions d'utilisateur et de toutes les données de contenu d'utilisateur qui y sont contenues peuvent rester visibles dans les pages mises en cache et archivées, ou peuvent avoir été copiées ou stockées par d'autres utilisateurs du site Web.</p>
            <p><b><span>Coordonnées du client</span>: </b> Nous pouvons collecter et traiter les informations contenues dans toute demande que vous nous soumettez concernant les Services.</p>
            <p><b><span>Données de vente</span>: </b> Si vous effectuez des transactions avec nos sites Web, nous pouvons obtenir et traiter les informations que vous fournissez concernant ces transactions, y compris l'achat et l'expédition de services, que vous nous demandez ou via nos sites Web.</p>
            <p><b><span>Données d'affiliation</span>: </b> Nous entretenons des relations avec d'autres prestataires à partir de notre ou nos sites Web. Un exemple de ces services comprend l'enregistrement de noms de domaine. Dans le cadre de l'achat de ces services ou de l'interaction avec ces tiers, vous pouvez être amené à leur fournir des données personnelles. Dans ce cas, vos droits concernant ces données seront soumis à la politique de confidentialité de ce tiers.</p>
            <p><b><span>Données des médias sociaux</span>: </b> Nous pouvons recevoir, utiliser et conserver des informations provenant de sites de réseaux sociaux tiers avec votre consentement (comme vous l'avez fourni au site de médias sociaux), y compris les informations que les sites de réseaux sociaux nous fournissent si vous utilisez vos informations d'identification sur ces sites de réseaux sociaux pour vous connecter à certains de nos Services (tels que votre nom et votre adresse e-mail pour pré-remplir notre formulaire d'inscription).</p>
            <p>Les informations auxquelles vous nous autorisez à accéder varient selon le site de réseau social et dépendent du niveau de paramètres de confidentialité que vous avez mis en place sur le site de réseau social. Vous pouvez contrôler et en savoir plus sur ces paramètres de confidentialité sur le site de réseau social applicable que vous utilisez.</p>
            <p><b><span>Données d'utilisation - Cookies et autres technologies de collecte automatique de données</span>: </b> Lorsque vous naviguez et interagissez avec nos sites Web et nos services, nous pouvons utiliser des technologies de collecte automatique de données pour collecter des données d'utilisation et d'autres informations sur vous, votre équipement, vos actions de navigation et vos habitudes. Ces données sont collectées automatiquement lorsque vous entrez et naviguez sur nos sites par notre plate-forme à l'aide de divers outils et applications logicielles, y compris des cookies et d'autres technologies de suivi. A noter que les cookies ne contiennent généralement aucune donnée personnelle.</p>
            <h5>AUTRES SOURCES</h5>
            <ul>
                <li style={{ listStyleType: "circle" }}> Les informations que vous nous fournissez (par exemple lors de salons ou via des enquêtes) ou à nos partenaires ;</li>
                <li style={{ listStyleType: "circle" }}> Les informations accessibles au public ;</li>
                <li style={{ listStyleType: "circle" }}> Les informations que vous consentez à ce que nous recevions de tiers.</li>
                <li style={{ listStyleType: "circle" }}> Nous utilisons également des conversations téléphoniques enregistrées à des fins de contrôle qualité, pour former nos employés et pour notre propre protection.</li>
            </ul>
            <h5>AUTRES RAISONS POUR LA COLLECTE ET LE TRAITEMENT DES DONNÉES</h5>
            <p>Nous pouvons obtenir, traiter et conserver n'importe laquelle de vos données personnelles, qu'elles soient ou non identifiées dans cette politique dans la mesure du raisonnable, pour se conformer à une obligation légale à laquelle nous sommes soumis, ou pour protéger vos intérêts vitaux, des nôtres ou de tout tiers.</p>
            <h5>DIVULGATION DE DONNÉES PERSONNELLES À DES TIERS</h5>
            <p>Nous pouvons divulguer vos données personnelles à des tiers dans la mesure où cela est raisonnablement nécessaire aux fins pertinentes et sur la base juridique pertinente énoncée dans la présente politique. Dans tous les cas, cette divulgation est:</p>
            <ul>
                <li style={{ listStyleType: "circle" }}> Conforme à l'objectif pour lequel vous avez accepté de le fournir ; et/ou,</li>
                <li style={{ listStyleType: "circle" }}> Avec votre consentement.</li>
            </ul>
            <h5>PARTAGE AVEC DES FOURNISSEURS ET FOURNISSEURS DE SERVICES TIERS</h5>
            <p>Nous comptons sur d'autres pour fournir une partie de l'assistance et des services que nous vous fournissons. Nous concluons des contrats avec ces fournisseurs et prestataires de services à cette fin (par exemple, en fournissant des services bancaires, de traitement/passerelle de paiement, d'informatique et de stockage, y compris des sauvegardes ou pour nous aider dans nos mesures de sécurité ou nos activités de marketing.</p>
            <p>Si vous achetez un produit ou un service auprès d'un tiers via l'un de nos sites Web, nous transmettrons vos informations personnelles à ce tiers afin qu'il puisse exécuter votre commande.</p>
            <p>De temps à autre, notre ou nos sites Web peuvent présenter des publicités ou des liens vers des sites Web et des sources de tiers. Si vous activez ce lien, vous interagirez avec ce tiers et nous conserverons un enregistrement de votre réponse à la publicité ou au lien. Nous pouvons fournir à ce tiers des données générales non personnelles nécessaires pour identifier notre ou nos sites Web comme source de toute interaction que vous pourriez avoir avec ce tiers. Cependant, chacun de ces tiers agira en tant que responsable du traitement des données par rapport aux données qu'il obtient ou reçoit et lorsque vous interagissez avec ce tiers, vous serez soumis à sa politique, qui régira toutes vos données personnelles que vous fournissez. pour eux. Nous n'avons aucun contrôle sur les politiques et pratiques de confidentialité des tiers et ne sommes pas responsables de celles-ci.</p>
            <h5>IMPORTANT</h5>
            <p>Veuillez noter que si vous accédez à nos services via un outil qui masque votre emplacement, tel qu'un VPN, ou si vous accédez à nos services via un emplacement autre que celui où vous résidez, vous ne recevrez peut-être pas notre demande d'autorisation car nous n'avons pas pu vous identifier comme étant situé dans une juridiction où votre permission est requise.</p>
            <h5>RÉORGANISATIONS D'ENTREPRISE</h5>
            <p>Si nous sommes impliqués dans une fusion, une acquisition, une vente de toute partie de notre entreprise, nous pouvons être tenus de divulguer des informations à la contrepartie.</p>
            <h5>UTILISATEURS AUTORISÉS</h5>
            <p>Toute personne que vous (ou si vous êtes une entreprise/organisation, votre entreprise) autorise à accéder à votre compte ou à tout service, peut être en mesure de consulter les données personnelles stockées en relation avec le compte ou tout sous-compte auquel elle peut avoir accès.</p>
            <h5>PROCÉDURE LÉGALE</h5>
            <p>Si nous sommes, ou pensons de bonne foi, que la divulgation est raisonnablement nécessaire pour nous conformer à nos obligations légales dans toute juridiction concernée, nous pouvons divulguer des informations vous concernant aux tribunaux, aux personnes agissant sous le couvert de la loi, aux autorités chargées de l'application de la loi. et d'autres tiers concernés, tels que des fournisseurs de services Internet, des avocats ou des enquêteurs, pour mener une enquête, répondre à une assignation à comparaître ou à une ordonnance d'un tiers ou d'un organisme d'application de la loi, intenter une action en justice, empêcher de nuire à autrui ou demander d'autres réparations lorsque vous ou un tiers sont ou, en toute bonne foi, peuvent :</p>
            <ul>
                <li style={{ listStyleType: "circle" }}> enfreindre nos termes et conditions d'utilisation ;</li>
                <li style={{ listStyleType: "circle" }}> causer des blessures ou d'autres dommages à, ou autrement violer la propriété ou d'autres droits légaux, de nous, d'autres utilisateurs ou de tiers ; ou alors</li>
                <li style={{ listStyleType: "circle" }}> enfreindre la loi fédérale, étatique, locale ou toute autre loi applicable.</li>
            </ul>
            <p>Dans la mesure où nous sommes légalement autorisés à le faire, notre politique est de vous informer dans le cas où nous sommes tenus de fournir vos informations personnelles à des tiers dans le cadre d'une citation à comparaître.</p>
            <h5>LIENS ET COLLECTE D'INFORMATIONS DE TIERS ET UTILISATION PAR NOS UTILISATEURS</h5>
            <p>Certains de nos Services fournissent des liens vers d'autres sites Web. Nous ne contrôlons pas les politiques d'information ou les pratiques de ces sites tiers et vous devez examiner attentivement leurs politiques de confidentialité pour savoir comment ils collectent, utilisent et partagent vos informations personnelles.</p>
            <p>De temps à autre, nous pouvons vous autoriser à vous connecter à votre compte et à accéder à nos Services à l'aide de services de connexion unique tels que Facebook. Ces services authentifieront votre identité auprès de nous et pourront vous permettre de contrôler les informations que ces services partagent avec nous, vous offrant ainsi des options concernant le partage de certaines informations personnelles avec nous. Vous devez examiner attentivement les politiques de confidentialité applicables de ces services tiers pour savoir comment ils collectent, utilisent et partagent vos informations personnelles.</p>
            <h5>SÉCURITÉ</h5>
            <p>La transmission d'informations via Internet, e-mail ou SMS n'est pas totalement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons pas garantir la sécurité de vos informations transmises via les Services ou par e-mail. Une fois que nous aurons reçu vos informations, nous prendrons des mesures de bonne foi pour protéger vos informations personnelles dans nos systèmes contre la perte, le vol et l'utilisation, l'accès ou la modification non autorisés.</p>
            <h5>Google Analytics</h5>
            <p>Par l'intermédiaire de notre fournisseur, Google Analytics, des données sont collectées sur l'utilisation de nos sites Web. Nous attribuons un numéro aléatoire à votre utilisation afin que Google Analytics puisse identifier un utilisateur unique de notre (nos) site(s) Web, bien que Google Analytics n'associe pas ce numéro à vos informations personnellement identifiables. Nous reconnaissons que vous préférerez peut-être limiter ou éliminer les données que Google Analytics collecte sur votre utilisation de nos sites Web. Google Analytics propose une option "Opt-out". Vous pouvez consulter les instructions et les informations pertinentes sur Google Analytics à l'adresse : http://www.google.com/intl/en/analytics/privacyoverview.html.</p>
            <h5>Politique de conservation des données</h5>
            <p>Nous ne conserverons pas vos données personnelles pendant des périodes plus longues que nécessaire aux fins pour lesquelles elles ont été obtenues et/ou conservées pour traitement.</p>
            <p>D'une manière générale, et selon leur nature, nous conserverons vos données personnelles pendant une période minimale pouvant aller jusqu'à cinq (05) ans après le dernier jour au cours duquel vous avez interagi avec nous concernant la finalité pour laquelle les données personnelles ont été obtenues et traitées.</p>
            <p>Dans tous les cas, nous pouvons conserver vos données personnelles lorsque la conservation est nécessaire à des fins de conformité légale ou réglementaire ou afin de protéger les intérêts vitaux de l'un de nous ou d'un tiers.</p>
            <h5>Accès et correction de vos informations</h5>
            <p>Vous pouvez consulter et modifier certaines de vos données personnelles et d'autres données non personnelles via votre compte. Cependant, votre droit de modifier ou de supprimer des informations est soumis à des exceptions généralement référencées dans la présente politique.</p>
            <p>Si votre adresse physique ou e-mail change, veuillez nous en informer par écrit ou mettre à jour les informations de votre compte utilisateur afin que nos dossiers restent à jour et qu'il n'y ait aucun retard dans votre réception des Services. Nous ne sommes pas responsables des services incomplets en raison de votre incapacité à maintenir avec précision ces informations.</p>
            <p>Les noms d'utilisateurs ne peuvent pas être modifiés une fois créés. Certaines données personnelles ne peuvent être supprimées que si vous supprimez également vos comptes chez nous, ce qui signifie la résiliation de nos services. Il existe des exceptions à notre obligation de supprimer ou de modifier vos données personnelles. En général, nous ne pouvons pas répondre à une demande de suppression ou de modification d'informations si la modification enfreint une loi ou un règlement ou si la conservation est nécessaire pour exercer le droit à la liberté d'expression et d'information, se conformer à une obligation légale, s'occuper des questions de sécurité et prévenir ou enquêter sur les attaques et/ou les fraudes, conserver nos archives et administrer correctement notre ou nos sites Web et nos activités, pour protéger vos intérêts vitaux, ceux de nous ou de tout tiers, ou pour l'établissement, l'exercice ou la défense d'un droit réclamations.</p>
            <p>Si vous supprimez les données de contenu utilisateur de nos sites Web, des copies de vos données de contenu utilisateur peuvent rester visibles dans les pages mises en cache et archivées, ou peuvent avoir été copiées ou stockées par d'autres utilisateurs du site Web ou des tiers sur lesquels nous n'avons aucun contrôle (par ex. Google).</p>
            <h5>Sécurité des données</h5>
            <p>Nous avons mis en place des mesures conçues pour protéger vos données personnelles contre la perte accidentelle et contre l'accès, l'utilisation, la modification et la divulgation non autorisés. Toutes les informations que vous nous fournissez sont stockées sur nos serveurs sécurisés derrière des pare-feux. Toutes les transactions de commande/paiement seront cryptées à l'aide de la technologie SSL à l'aide de passerelles/processeurs tiers.</p>
            <p>La sûreté et la sécurité de vos informations dépendent également de vous. Lorsque nous vous avons donné (ou lorsque vous avez choisi) un mot de passe pour accéder à certaines parties de notre (nos) site(s) Web, vous êtes responsable d'en choisir un qui est fort et de le garder confidentiel. Nous vous demandons de ne partager votre mot de passe avec personne. Nous vous invitons également à faire preuve de prudence lorsque vous divulguez des informations personnelles dans les zones publiques de nos sites Web. Les informations que vous partagez dans les espaces publics peuvent être consultées par tout utilisateur de nos sites Web.</p>
            <p>La transmission d'informations via Internet (par exemple par e-mail) n'est pas totalement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons pas garantir la sécurité de vos informations personnelles lors de leur transmission à nos sites Web. Toute transmission d'informations personnelles se fait à vos risques et périls. Nous ne sommes pas responsables du contournement des paramètres de confidentialité ou des mesures de sécurité contenus sur nos sites Web.</p>
            <h5>Modifications de notre politique</h5>
            <p>Nous pouvons modifier cette politique à tout moment en publiant une nouvelle version sur notre ou nos sites Web. Notre politique est de publier toute modification que nous apportons à notre politique sur cette page. Si nous apportons des modifications importantes à la manière dont nous traitons les informations personnelles de nos utilisateurs, nous vous en informerons par le biais d'un avis sur la page d'accueil de notre site Web et/ou par e-mail. La date d'entrée en vigueur de la dernière révision de la Politique est indiquée en haut de la page. Vous êtes responsable de vous assurer que nous avons une adresse e-mail active et livrable à jour pour vous, et de visiter périodiquement nos sites Web et cette politique pour vérifier tout changement.</p>
            <h5>Coordonnées</h5>
            <p>Pour poser des questions ou commenter cette politique et nos pratiques de confidentialité, contactez-nous sur notre formulaire www.lanzocode.com</p>
            <h5>Divers</h5>
            <p>Si une disposition de la présente politique est jugée invalide, illégale ou inapplicable par un tribunal ou un autre tribunal compétent pour quelque raison que ce soit, cette disposition sera éliminée ou limitée dans la mesure minimale telle que les autres dispositions de la politique, telle que modifiée de temps à autre, se poursuivra pleinement en vigueur et de plein effet. Les liens vers d'autres sites Web et pages Web apparaissant dans cette politique sont à la date d'entrée en vigueur de cette politique et sont sujets à changement sans préavis. En conséquence, nous ne garantissons pas que ces liens restent exacts ou corrects après la date d'entrée en vigueur de la présente Politique.</p>
            <p>Les dispositions de nos conditions d'utilisation sont incorporées dans la présente politique et en font partie intégrante. Vous pouvez consulter une copie de nos conditions d'utilisation ICI.</p>
            <div className="text-center">
                <a href="/" className="btn-click">Revenir vers le site <img src="./img/Smileynoir.png" alt="smileynoir" className="btn-smiley" /></a>
            </div>
            <br />
        </div>
    )
}

export default Pdc