import axios from 'axios'
import { useState, useEffect, useRef } from "react"
import '../styles/Project.css'

export default function Project() {
    const [projects, setProject] = useState([])
    const [displayedProject, setDisplayedProject] = useState([])
    const [description, setDescription] = useState([])
    const [filterProject, setFilterProject] = useState('')

    const [projectPage, setProjectPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [pagination, setPagination] = useState([])

    const modalRef = useRef()
    useEffect(() => {
        const getProject = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/project`,
                withCredentials: true
            }).then((res) => {
                let countPage = Math.ceil(res.data.project.length / 10)
                setProject(res.data.project)
                setPageCount(countPage)
            })
        }
        getProject()
    }, [])

    useEffect(() => {
        let startProject = (projectPage - 1) * 10
        let endProject = projectPage * 10 - 1
        let allPages = []
        for (let i = 1; i <= pageCount; i++)
            allPages.push(i)
        let newPagination =
            <ul className="pagination justify-content-center text-dark" >
                {
                    (projectPage !== 1) ?
                        <li className='page-item'><button className="page-link" onClick={() => (setProjectPage(projectPage - 1))}>&laquo;</button></li>
                        : ""
                }
                {allPages.map((page, idxP) => <li className='page-item' key={idxP}><button className="page-link" onClick={() => { setProject(projects); setProjectPage(page); }}>{page}</button></li>)}
                {
                    (projectPage < pageCount) ?
                        <li className='page-item'><button className="page-link" onClick={() => (setProjectPage(projectPage + 1))}>&raquo;</button></li>
                        : ""
                }
            </ul>
        setDisplayedProject(projects.slice(startProject, endProject))
        setPagination(newPagination)
    }, [projectPage, projects, pageCount])

    const showDescription = (descriptionValue) => {
        modalRef.current.style.display = "block";
        setDescription(descriptionValue);
    }

    const updateDisplayedProject = (filter) => {
        setFilterProject(filter)
        //TO DO filter
    }
    const updateProject = (projectId) => {
        //TO DO axios update Project according to projectId then finally update projects
        console.log("projectId", projectId);
    }

    return (
        <div id="Project" className="tabcontent" style={{ flexDirection: "column" }}>
            <div className="container">
                <div className="d-md-flex d-sm-block justify-content-between">
                    <input type="hidden" name="command" value="select-orders" />
                    <h1 className="h5 align-self-center col-3">Rechercher Demande</h1>
                    <div className="btn-group align-self-center col-12 col-sm-12 col-md-8 col-lg-6">
                        <select name="searchType" className="btn btn-outline-dark col-3 col-sm-3">
                            <option value="created">Date demande</option>
                            <option value="customer">Client</option>
                            <option value="customer">Télephone</option>
                            <option value="status">Status</option>
                        </select>
                        <input type="search" name="searchBy" className="border border-dark col-6 col-sm-6" value={filterProject} onChange={(e) => updateDisplayedProject(e.target.value)} />
                        <input type="submit" value="Search" className="btn btn-outline-dark col-3 col-sm-3" />
                    </div>
                </div>
                <div className="d-md-flex d-none justify-content-md-between justify-content-sm-center align-content-center border-bottom border-2 my-2 bg-redGradient text-light p-3 rounded-3">
                    <div className="col-2 align-self-center">
                        <h1 className="h6 fw-bold">Date demande</h1>
                    </div>
                    <div className="col-2 align-self-center">
                        <h1 className="h6 fw-bold">Client</h1>
                    </div>
                    <div className="col-2 align-self-center">
                        <h1 className="h6 fw-bold">Télephone</h1>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <h1 className="h6 fw-bold">Description</h1>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <h1 className="h6 fw-bold">Status</h1>
                    </div>
                </div>
                {displayedProject.map((project, idxP) =>
                    <div key={idxP} className="d-md-flex d-sm-block justify-content-md-between justify-content-sm-center text-center border-bottom border-2 my-2 bg-light p-2 rounded-3">
                        <div className="col-md-2 text-sm-center text-md-start align-self-center my-2">
                            <h1 className="h6">{new Date(project.createdAt).toLocaleDateString()}</h1>
                        </div>
                        <div className="col-md-2 text-sm-center text-md-start align-self-center my-2">
                            <h1 className="h6">{project.nameCompany}</h1>
                        </div>
                        <div className="col-md-2 text-sm-center text-md-start align-self-center my-2">
                            <h1 className="h6">{project?.tel}</h1>
                        </div>
                        <div className="col-md-2 text-sm-center text-md-start align-self-center my-2">
                            <button className="btn btn-outline-dark w-75" onClick={() => showDescription(project.description)}>Voir</button>
                        </div>
                        <div className="col-md-2 text-sm-center text-md-start align-self-center my-2">
                            <form className="d-flex btn-group">
                                <input type="hidden" name="command" value="refresh-order-status" />
                                <select name="status" className="btn btn-outline-dark">
                                    <option value="unread" className="form-select-button">Non lu</option>
                                    <option value="negotiation" className="form-select-button">Négociation</option>
                                    <option value="loading" className="form-select-button">En cours</option>
                                    <option value="remove" className="form-select-button">Suppression</option>
                                    <option value="complete" className="form-select-button">Terminé</option>
                                </select>
                                <input type="submit" className="btn btn-outline-dark" value="OK" onClick={() => updateProject(project._id)} />
                            </form>
                        </div>
                    </div>
                )}
                {pagination}
            </div>
            <div id="myModalDescription" className="modalDescription" ref={modalRef} onClick={() => modalRef.current.style.display = "none"}>
                <div className="modalContent">
                    <div className="modalHeader">
                        <span className="close">×</span>
                        <h2>Description</h2>
                    </div>
                    <div className="modalBody">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
