function Offre({ linkImage, name, description, body }) {
    return (
        <div className="project js-item" tabIndex="0">
            <img src={linkImage} alt="" className="project__image" />
            <h2 className="project__name">{name}</h2>
            <div className="project__description">{description}</div>
            <div className="project__body js-body">
                {body}
            </div>
        </div>
    )
}

export default Offre