import { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import $ from "jquery";
import '../styles/Navbar.css'

const ALLNAVIGATION = ['#Accueil', '#Offres', '#Demo', '#Contact']

function Navbar() {
    const [click, setClick] = useState(false)
    const [navActive, setNavActive] = useState('#Accueil')
    const halfWidth = window.innerHeight / 2

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const handleNavClick = (navigation) => {
        setNavActive(navigation)
        closeMobileMenu()
    }

    const scrollNav = () => {
        if ($('#Accueil').offset().top + $('#Accueil').outerHeight() - halfWidth > window.scrollY) {
            $('.mynavbar').removeClass("active")
            setNavActive('#Accueil');
        } else if ($('#Offres').offset().top + $('#Offres').outerHeight() - halfWidth > window.scrollY) {
            setNavActive('#Offres');
        } else if ($('#Demo').offset().top + $('#Demo').outerHeight() - halfWidth > window.scrollY) {
            setNavActive('#Demo');
            if (!$('.mynavbar').hasClass("active"))
                $('.mynavbar').addClass("active")
        } else {
            setNavActive('#Contact');
            if (!$('.mynavbar').hasClass("active"))
                $('.mynavbar').addClass("active")
        }
    }

    document.addEventListener('scroll', scrollNav)

    return (
        <nav className="mynavbar">
            <div className={click ? "menu-icon active" : "menu-icon"} onClick={handleClick}>
                <i className={click ? 'fas fa-times text-white fw-400' : 'fas fa-bars text-white'} />
            </div>
            <ul className={click ? 'mynavbar-menu active' : 'mynavbar-menu'}>
                {ALLNAVIGATION.map((currentNav, idxNav) => {
                    return (
                        <li key={idxNav} className={(currentNav === navActive) ? "mynavbar-item-active" : "mynavbar-item"}>
                            <Link to={currentNav} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} className="mynavbar-links" onClick={() => handleNavClick(currentNav)}>
                                {currentNav.indexOf("Demo") > 0 ? "Démo" : currentNav.replace('#', '')}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Navbar
