import AmChart from '../components/AmChart'
import Galery from '../components/Galery'
import ToDoList from '../components/ToDoList'
import '../styles/MyDemo.css'

const MyDemo = () => {
    return (
        <>
            <AmChart />
            <Galery />
            <ToDoList />
        </>
    )
}

export default MyDemo