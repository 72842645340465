import WeekDays from './WeekDays';
import { useContext } from "react";
import { StateContext } from "./AppContext";
import BlockDaysInMonth from './BlockDaysInMonth';

function BlockMonthYear({ month }) {
    const wkDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const stateCont = useContext(StateContext)
    return (
        <table className="m-auto border-year">
            <thead>
                <tr style={{ backgroundColor: 'white', color: 'black' }}>
                    <td colSpan="10">{month}</td>
                </tr>
            </thead>
            <tbody>
                <WeekDays days={wkDays} />
                <BlockDaysInMonth month={stateCont.month} year={stateCont.year} selectedView="Year" />
            </tbody>
        </table>
    )
}

export default BlockMonthYear