import React from 'react';
import EventWeekCell from './EventWeekCell';
import Event from './EventHandler';
import Modal from 'react-modal';

import { StateContext } from './AppContext';

export default class Cell extends React.Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCancelModal = this.handleCancelModal.bind(this);
        this.addEvent = this.addEvent.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal = async () => {
        const { formatDate, day, month, year, pullWeekEvents, getStartWeek } = this.context;
        this.setState({ showModal: false });
        let startWeek = getStartWeek(day, month, year);
        let formatsetWeek = formatDate(startWeek) + " 00:00";
        let endWeek = startWeek;
        endWeek.setDate(endWeek.getDate() + 6);
        let formatEndWeek = formatDate(endWeek) + " 23:59";
        await pullWeekEvents(formatsetWeek, formatEndWeek);
    }

    handleCancelModal() {
        this.setState({ showModal: false });
    }

    addEvent(e) {
        let id = this.props.date + this.props.hour;
        if (e.target.id.toString() === id.toString()) {
            this.handleOpenModal();
        }
    }

    render() {
        // Cell height:60px ,width:150px
        const { events, hour, date, numero } = this.props;
        let content = (this.props.content) ? this.props.content : "";
        let blockEvents = [];
        if (events && hour && date) {
            events.forEach((event, index) => {
                // console.log(event);
                if (event.cellDate === date && event.cellHour === hour) {
                    // console.log('blockEvents', event.cellDate, date, event.cellHour, hour);
                    blockEvents.push(<EventWeekCell numero={numero} key={index + hour} event={event} />);
                }
            });
        }
        const classCell = this.props.classCell ? this.props.classCell : "";
        let customStyles = {
            overlay: {
                "zIndex": "2"
            },
            content: {
                top: '10%',
                left: '20%',
                right: '20%',
                bottom: '10%'
                // marginRight: '-50%',
                // transform: 'translate(-50%, -50%)',
            }
        };
        const startDate = this.props.date + " " + this.props.hour;
        return (
            <React.Fragment>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Event"
                    style={customStyles}
                >
                    <Event

                        startDateTime={new Date(startDate)}

                        frequencyMonthThirdLevelMonthWeekDaysRanksName="monthWeekDaysRanks"

                        frequencyMonthThirdLevelWeekDayCheckboxName="weekDayFrequencyMonthThirdLevel"

                        freqYr2ndLevSlctBxMthName="slctBxMthYr"

                        freqYr2ndLevChkBxActivateMthDayName="chkBxActivateMthDayName"

                        freqYr2ndLevSlctBxMthDayName="slctBxMthDayName"

                        freqYr2ndLevChkBxActivateWkDayInMthYrRuleName="chkBxActivateWkDayInMthYrRuleName"

                        freqYr2ndLevSlctBxWkDayRkInMthYrRuleName="slctBxWkDayRkInMthYrRuleName"

                        freqYr2ndLevWkDaysInMthYrRuleName="weekDayInMthYrRule"

                        freqYr2ndLevSlctBxWkYrName="slctBxWkYr"

                        freqYr2ndLevWkDaysInWkYrRuleName="weekDayInWkYrRule"

                        freqYr2ndLevSlctBxDayYrName="slctBxDayYr"

                        handleCloseModal2={this.handleCloseModal}

                        handleCancelModal2={this.handleCancelModal}
                    />

                </Modal>
                <td id={(this.props.date + this.props.hour).toString()} className={"border " + classCell} onDoubleClick={this.addEvent}>
                    {content}{blockEvents}
                </td>
            </React.Fragment >
        )
    }
}