import { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/Login.css'

const Login = () => {
    const [login, setLogin] = useState({ email: '', password: '' })
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchToken = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/jwtid`,
                withCredentials: true
            }).then((res) => {
                if (res && res.data && res.data.userId !== null && res.data.userId !== undefined)
                    window.location.href = '/adminpage'
            }).catch(() => { })
        }
        fetchToken();
    }, [])

    const logUser = (e) => {
        e.preventDefault()
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/user/login`,
            data: login,
            withCredentials: true
        }).then((res) => res.data).then((data) => {
            if (data.err) {
                setError(data.err)
            } else {
                window.location.href = '/adminpage'
            }
        })
    }

    return (
        <form id="admin-login">
            <div className="imgcontainer">
                <img src="./img/rougesmiley.png" alt="Avatar" className="avatar" />
                <h1>LANZOcode</h1>
            </div>

            <div className="container">
                <label htmlFor="uname"><b>Mail</b></label>
                <input type="text" placeholder="Entrer email" name="uname" required value={login.email} onChange={(e) => setLogin({ ...login, email: e.target.value })} />

                <label htmlFor="psw"><b>Mot de passe</b></label>
                <input type="password" placeholder="Entrer mot de passe" name="psw" required value={login.password} onChange={(e) => setLogin({ ...login, password: e.target.value })} />

                <button type="submit" onClick={(e) => logUser(e)}>Se connecter</button>
                {error && <div className="error-contact alert alert-warning">{error}</div>}

            </div>
        </form>
    )
}

export default Login
