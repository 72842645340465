import React from 'react';
import WeekDayCheckbox from './WeekDayCheckbox';

export default class BlockWeekDays extends React.Component {

    constructor(props) {
        super(props);
        this.changeWeekDay = this.changeWeekDay.bind(this);
    }

    changeWeekDay(event) {
        this.props.changeWeekDays(event);
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        const weekDayCheckboxName = this.props.weekDayCheckboxName;
        const weekDaysToCheck = this.props.weekDaysToCheck;//This is an array

        return (
            <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'MO'} value={'MO'} label={'Lundi'} checked={weekDaysToCheck.indexOf('MO') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'TU'} value={'TU'} label={'Mardi'} checked={weekDaysToCheck.indexOf('TU') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'WE'} value={'WE'} label={'Mercredi'} checked={weekDaysToCheck.indexOf('WE') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'TH'} value={'TH'} label={'Jeudi'} checked={weekDaysToCheck.indexOf('TH') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'FR'} value={'FR'} label={'Vendredi'} checked={weekDaysToCheck.indexOf('FR') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'SA'} value={'SA'} label={'Samedi'} checked={weekDaysToCheck.indexOf('SA') >= 0} changeStateHandler={this.changeWeekDay} />

                <WeekDayCheckbox name={weekDayCheckboxName} id={weekDayCheckboxName + 'SU'} value={'SU'} label={'Dimanche'} checked={weekDaysToCheck.indexOf('SU') >= 0} changeStateHandler={this.changeWeekDay} />

            </div>
        );
    }
}