import { useEffect, useContext, useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core'
import axios from 'axios'
import { UserContext } from '../components/AppContext'

function Contract() {
    const userLogged = useContext(UserContext)
    const [contractDatas, setContractData] = useState([])
    const [defaultContractDatas, setDefaultContractData] = useState([])
    useEffect(() => {
        if (userLogged && userLogged !== null) {
            if (userLogged.role === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/contract`,
                    withCredentials: true
                }).then((res) => {
                    setDefaultContractData(res.data.contract)
                    setContractData(res.data.contract)
                })
            } else {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/contract/${userLogged.id}`,
                    withCredentials: true
                }).then((res) => {
                    setDefaultContractData(res.data.contract)
                    setContractData(res.data.contract)
                })
            }
        }
    }, [userLogged])

    return (
        <div id="Contract" className="tabcontent" style={{ flexDirection: "column" }}>
            <div className='d-flex flex-row px-2'>
                <input type="text" id="refSearch" className="refSearch" onChange={(e) => { if (e.target.value === "") { setContractData(defaultContractDatas) } else { setContractData(defaultContractDatas.filter(contract => contract.ref.includes(e.target.value))) } }} placeholder="Recherche par réference" />
            </div>
            <div className='d-flex flex-row justify-content-center px-2'>
                <b><label htmlFor="startDate">Entre</label></b>
                <input type="date" id="startDate" className="mx-2" onChange={(e) => { setContractData(defaultContractDatas.filter(contract => new Date(contract.date_contract) > new Date(e.target.value))) }} />
                <b><label htmlFor="endDate">Et</label></b>
                <input type="date" id="endDate" className="mx-4" onChange={(e) => { setContractData(defaultContractDatas.filter(contract => new Date(contract.date_contract) < new Date(e.target.value))) }} />
            </div><br />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                {
                    contractDatas.map((contractData, idContract) => {
                        return (
                            <div className='d-flex flex-row justify-content-around' key={idContract} style={{ fontSize: "20px", fontWeight: 600 }}>
                                <div>
                                    <div>Ref: {contractData.ref} </div>
                                    <div>Date: {new Date(contractData.date_contract).toLocaleDateString()}</div>
                                    <a href={contractData.path} target="_blank" rel="noreferrer">Ouvrir</a>
                                </div>
                                <div>
                                    <div className='text-center'>Aperçu</div>
                                    <Viewer fileUrl={contractData.path} />
                                </div>
                            </div>
                        )
                    })
                }
            </Worker>
        </div>
    )
}

export default Contract