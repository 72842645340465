import "../styles/Message.css";
import { format } from "timeago.js";
import axios from "axios";
import { useState, useEffect } from "react";

export default function Message({ message, own, senderId }) {
    const [sender, setSender] = useState(null);

    useEffect(() => {
        const getSender = async () => {
            try {
                const res = await axios(`${process.env.REACT_APP_API_URL}/api/user/${senderId}`);
                setSender(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getSender();
    }, [senderId]);

    return (
        <div className={own ? "message own" : "message"}>
            <div className="messageTop">
                <img
                    className="messageImg"
                    src={
                        sender?.picture
                            ? sender.picture
                            : "/data/profile/random-user.png"
                    }
                    alt=""
                />
                <p className="messageText">{message ? message.text : ""}</p>
            </div>
            <div className="messageBottom">{message ? format(message.createdAt) : ""}</div>
        </div>
    );
}