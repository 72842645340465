import { useState, createContext } from 'react'
import { MONTHNAMES } from '../Constants'
import $ from "jquery";

export const UserContext = createContext()

export const StateContext = createContext({
    selectedView: 'Day',
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    userLogged: null,
    project: '',
    task: '',
    tab: 'Calendar',
    events: []
})

export const StateProvider = ({ children }) => {
    const [state, setState] = useState({
        selectedView: 'Day',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        userLogged: null,
        project: '',
        task: '',
        tab: 'Calendar',
        events: []
    });

    const formatDate = (date) => {
        let stringDate = date.toDateString();
        let convertedStartDate = new Date(stringDate);
        let month = convertedStartDate.getMonth() + 1
        let day = convertedStartDate.getDate();
        let year = convertedStartDate.getFullYear();
        month = ("0" + month.toString()).slice(-2);
        day = ("0" + day.toString()).slice(-2);
        let shortStartDate = month + "/" + day + "/" + year;
        return shortStartDate;
    }

    const formatTzDate = (date) => {
        let month = date.getUTCMonth() + 1;
        let day = date.getUTCDate();
        let year = date.getUTCFullYear().toString();
        let hour = date.getUTCHours();
        let min = date.getUTCMinutes();
        hour = ("0" + hour.toString()).slice(-2);
        min = ("0" + min.toString()).slice(-2);
        month = ("0" + month.toString()).slice(-2);
        day = ("0" + day.toString()).slice(-2);
        let formatTz = year + month + day + "T" + hour + min + "00Z";
        return formatTz;
    }
    const diffMinute = (endHour, startHour) => {
        let startDateTime = new Date("2020/01/01 " + endHour);
        let endDateTime = new Date("2020/01/01 " + startHour);
        let min = parseInt((endDateTime - startDateTime) / 60000);
        return min;
    }

    return (
        <StateContext.Provider value={{
            ...state,
            setLoggedUser: (userObj) => setState({ ...state, userLogged: userObj }),
            changeView: (selectedView, setDay, setMonth, setYear) => {
                return (
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear,
                        selectedView: selectedView
                    })
                );
            },
            formatTzDate: (date) => formatTzDate(date),
            diffMinute: (endHour, startHour) => diffMinute(endHour, startHour),
            getToday: () => {
                let todayDate = new Date();
                let setDay = todayDate.getDate();
                let setMonth = todayDate.getMonth();
                let setYear = todayDate.getFullYear();
                setState({
                    ...state,
                    day: setDay,
                    month: setMonth,
                    year: setYear
                });
            },
            previousArrow: () => {
                if (state.selectedView === 'Day') {
                    let today = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    let previousDay = new Date(today);
                    previousDay.setDate(previousDay.getDate() - 1);
                    let setDay = previousDay.getDate();
                    let setMonth = previousDay.getMonth();
                    let setYear = previousDay.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear
                    });
                } else if (state.selectedView === 'Week') {
                    let date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    let day = date.getDay();
                    let startDate = new Date(date);
                    if (day === 0) {
                        startDate.setDate(startDate.getDate() - 13);
                    } else if (day === 1) {
                        startDate.setDate(startDate.getDate() - 7);
                    } else {
                        startDate.setDate(startDate.getDate() - (day - 1) - 7);
                    }
                    let setDay = startDate.getDate();
                    let setMonth = startDate.getMonth();
                    let setYear = startDate.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear,
                        arrEventsWeekToDisplay: []
                    });
                } else if (state.selectedView === 'Month') {
                    let date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    date.setMonth(date.getMonth() - 1);
                    let setDay = date.getDate();
                    let setMonth = date.getMonth();
                    let setYear = date.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear,
                        arrEventsToDisplay: []
                    });
                } else if (state.selectedView === 'Year') {
                    let date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    date.setFullYear(date.getFullYear() - 1);
                    let setDay = date.getDate();
                    let setMonth = date.getMonth();
                    let setYear = date.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear
                    });
                }
            },
            nextArrow: () => {
                if (state.selectedView === 'Day') {
                    let today = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    let nextDay = new Date(today);
                    nextDay.setDate(nextDay.getDate() + 1);
                    let setDay = nextDay.getDate();
                    let setMonth = nextDay.getMonth();
                    let setYear = nextDay.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear
                    });
                } else if (state.selectedView === 'Week') {
                    let date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    let day = date.getDay();
                    let startDate = new Date(date);
                    if (day === 0) {
                        startDate.setDate(startDate.getDate() + 1);
                    } else if (day === 1) {
                        startDate.setDate(startDate.getDate() + 7);
                    } else {
                        startDate.setDate(startDate.getDate() + 8 - day);
                    }
                    let setDay = startDate.getDate();
                    let setMonth = startDate.getMonth();
                    let setYear = startDate.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear,
                        arrEventsWeekToDisplay: []
                    });
                } else if (state.selectedView === 'Month') {
                    let date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    date.setMonth(date.getMonth() + 1);
                    let setDay = date.getDate();
                    let setMonth = date.getMonth();
                    let setYear = date.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear,
                        arrEventsToDisplay: []
                    });
                } else if (state.selectedView === 'Year') {
                    var date = new Date(MONTHNAMES[state.month] + ' ' + state.day + ', ' + state.year);
                    date.setFullYear(date.getFullYear() + 1);
                    let setDay = date.getDate();
                    let setMonth = date.getMonth();
                    let setYear = date.getFullYear();
                    setState({
                        ...state,
                        day: setDay,
                        month: setMonth,
                        year: setYear
                    });
                }
            },
            changeTab: (newTab) => {
                if (newTab === 'Tache')
                    return setState({ ...state, task: '', tab: newTab })
                else
                    return setState({ ...state, tab: newTab })
            },
            openProject: (project) => setState({ ...state, project: project }),
            openTask: (task) => setState({ ...state, task: task, tab: 'Tache' }),
            formatDateInput: (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`,
            formatDate: (date) => formatDate(date),
            formatDateCalendar: (date) => {
                let stringDate = date.toDateString();
                let convertedStartDate = new Date(stringDate);
                let month = convertedStartDate.getMonth() + 1
                let day = convertedStartDate.getDate();
                let year = convertedStartDate.getFullYear();
                month = ("0" + month.toString()).slice(-2);
                day = ("0" + day.toString()).slice(-2);
                let shortStartDate = month + "/" + day + "/" + year;
                return shortStartDate;
            },
            getEventsDay: (startDateTime, endDateTime) => {
                $.ajax({
                    type: "GET",
                    async: false,
                    url: `${process.env.REACT_APP_API_URL}/api/event`,
                    data: {
                        startDateTime: startDateTime,
                        endDateTime: endDateTime
                    },
                    success: async (data) => {
                        let events = data.events;
                        await setState({
                            ...state,
                            events: events
                        });
                    }
                });
            },
            getEvents: () => {

            }
        }}>
            {children}
        </StateContext.Provider >
    );
}
