import { useContext, useState, useEffect } from 'react';
import { StateContext } from './AppContext';
import axios from 'axios';
import ModalLog from './ModalLog';


function TacheLog() {
    const stateCont = useContext(StateContext);
    const [logsTache, setLogsTache] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => {
        // Attendre 3 secondes avant d'afficher le modal
        setTimeout(() => {
            setShowModal(true);
        }, 200);
    };

    useEffect(() => {
        if (!showModal)
            getLogsByIdTache()
    }, [showModal])


    const getLogsByIdTache = async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/api/log/${stateCont.task}`,
            withCredentials: true
        }).then((res) => { if (res.data.logs != null) setLogsTache(res.data.logs) })

    }

    return (
        <div className="tacheTable">
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ width: "10%" }}>Date</th>
                        <th style={{ width: "10%" }}>Heure</th>
                        <th style={{ width: "60%" }}>Détails</th>
                        <th style={{ display: 'flex', flexDirection: 'column' }}>
                            Avancement
                            <i className="far fa-plus-square" onClick={() => handleShowModal()}></i>
                            {showModal && (
                                <ModalLog handleCloseModal={handleCloseModal} />
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {
                        logsTache.map((log, idLog) => {
                            return (
                                <tr key={idLog}>
                                    <td >{stateCont.formatDate(new Date(log.date))}</td>
                                    <td >{log.time}</td>
                                    <td >
                                        <p style={{ fontSize: "16px", color: "darkgray" }}>{log.user}</p>
                                        {log.detail}
                                    </td>
                                    <td >{log.avancement}%</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TacheLog