import React from 'react';
import FreqYrMthYrRule from './FreqYrMthYrRule';
import CheckBox from './CheckBox';

export default class BlockFreqYr2ndLev extends React.Component {
    constructor(props) {
        super(props);
        this.activateMthYrCustom = this.activateMthYrCustom.bind(this);
        this.activateWkYrCustom = this.activateWkYrCustom.bind(this);
        this.activatedayYrCustom = this.activatedayYrCustom.bind(this);
        this.changeSlctBxMth = this.changeSlctBxMth.bind(this);
        this.activateMthDay = this.activateMthDay.bind(this);
        this.changeSlctBxMthDay = this.changeSlctBxMthDay.bind(this);
        this.activateWkDayInMthYrRule = this.activateWkDayInMthYrRule.bind(this);
        this.changeSlctBxWkDayRkInMthYrRule = this.changeSlctBxWkDayRkInMthYrRule.bind(this);
        this.changeWeekDaysInMthYrRule = this.changeWeekDaysInMthYrRule.bind(this);
        this.addNewMthYrRule = this.addNewMthYrRule.bind(this);
        this.removeMthYrRule = this.removeMthYrRule.bind(this);
        this.changeSlctBxWkYr = this.changeSlctBxWkYr.bind(this);
        this.changeWeekDaysInWkYrRule = this.changeWeekDaysInWkYrRule.bind(this);
        this.addNewWkYrRule = this.addNewWkYrRule.bind(this);
        this.removeWkYrRule = this.removeWkYrRule.bind(this);
        this.changeSlctBxDayYr = this.changeSlctBxDayYr.bind(this);
    }

    activateMthYrCustom(value) {
        this.props.activateMthYrCustom(value);
    }

    activateWkYrCustom(value) {
        this.props.activateWkYrCustom(value);
    }

    activatedayYrCustom(value) {
        this.props.activatedayYrCustom(value);
    }

    changeSlctBxMth(obj) {
        this.props.changeSlctBxMth(obj);
    }

    activateMthDay(obj) {
        this.props.activateMthDayCustom(obj);
    }

    changeSlctBxMthDay(obj) {
        this.props.changeSlctBxMthDay(obj);
    }

    activateWkDayInMthYrRule(obj) {
        this.props.activateWkDayInMthYrRuleCustom(obj);
    }

    changeSlctBxWkDayRkInMthYrRule(obj) {
        this.props.changeSlctBxWkDayRkInMthYrRule(obj);
    }

    changeWeekDaysInMthYrRule(obj) {
        if (this.props.chkBxActivateWkDayInMthYrRuleChecked) {
            this.props.changeWeekDaysInMthYrRule(obj);
        }
    }

    addNewMthYrRule(customIndex) {
        this.props.addNewMthYrRuleToFreqYr(customIndex);
    }

    removeMthYrRule(customIndex) {
        this.props.removeMthYrRuleFromFreqYr(customIndex);
    }

    changeSlctBxWkYr(obj) {
        this.props.changeSlctBxWkYr(obj);
    }

    changeWeekDaysInWkYrRule(obj) {
        if (this.props.activateWkYr) {
            this.props.changeWeekDaysInWkYrRule(obj);
        }
    }

    addNewWkYrRule(customIndex) {
        this.props.addNewWkYrRuleToFreqYr(customIndex);
    }

    removeWkYrRule(customIndex) {
        this.props.removeWkYrRuleFromFreqYr(customIndex);
    }

    changeSlctBxDayYr(value) {
        this.props.changeSlctBxDayYr(value);
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        const activateMthYr = this.props.activateMthYr;
        const activateWkYr = this.props.activateWkYr;

        const slctBxMthName = this.props.slctBxMthName;
        const slctBxMthId = (typeof this.props.slctBxMthId !== "undefined" ? this.props.slctBxMthId : slctBxMthName);
        const slctBxMthVal = this.props.slctBxMthVal;
        const slctBxMthDisabled = !activateMthYr;

        const chkBxActivateMthDayName = this.props.chkBxActivateMthDayName;
        const chkBxActivateMthDayDisabled = !activateMthYr;
        const chkBxActivateMthDayChecked = this.props.chkBxActivateMthDayChecked;

        const slctBxMthDayName = this.props.slctBxMthDayName;
        const slctBxMthDayVal = this.props.slctBxMthDayVal;
        const slctBxMthDayDisabled = !chkBxActivateMthDayChecked;

        const chkBxActivateWkDayInMthYrRuleName = this.props.chkBxActivateWkDayInMthYrRuleName;
        const chkBxActivateWkDayInMthYrRuleDisabled = !activateMthYr;
        const chkBxActivateWkDayInMthYrRuleChecked = this.props.chkBxActivateWkDayInMthYrRuleChecked;

        const slctBxWkDayRkInMthYrRuleName = this.props.slctBxWkDayRkInMthYrRuleName;
        const slctBxWkDayRkInMthYrRuleVal = this.props.slctBxWkDayRkInMthYrRuleVal;
        const slctBxWkDayRkInMthYrRuleDisabled = !chkBxActivateWkDayInMthYrRuleChecked;

        const weekDayCheckboxNameInMthYrRule = this.props.weekDayCheckboxNameInMthYrRule;
        const weekDaysToCheckInMthYrRule = this.props.weekDaysToCheckInMthYrRule;

        const slctBxWkYrName = this.props.slctBxWkYrName;
        const slctBxWkYrVal = this.props.slctBxWkYrVal;
        const slctBoxWkYrDisabled = !activateWkYr;
        const weekDayCheckboxNameInWkYrRule = this.props.weekDayCheckboxNameInWkYrRule;
        const weekDaysToCheckInWkYrRule = this.props.weekDaysToCheckInWkYrRule;

        const htmlOptionsYearMonths = [{ value: 1, display: "Janvier" }, { value: 2, display: "Février" }, { value: 3, display: "Mars" }, { value: 4, display: "Avril" }, { value: 5, display: "Mai" }, { value: 6, display: "Juin" }, { value: 7, display: "Juillet" }, { value: 8, display: "Aout" }, { value: 9, display: "Septembre" }, { value: 10, display: "Octobre" }, { value: 11, display: "Novembre" }, { value: 12, display: "Décembre" }];
        const htmlOptionsWeekDaysRanks = [{ value: "", display: "Chaque" }, { value: 1, display: "1st" }, { value: 2, display: "2nd" }, { value: 3, display: "3rd" }, { value: 4, display: "4th" }, { value: 5, display: "5th" }, { value: -1, display: "Last" }];
        const htmlOptionsMonthDays = [{ value: 1, display: "Premier jour" }, { value: -1, display: "Dernier jour" }];
        for (let i = 2; i <= 31; i++) {
            let option = { value: i, display: i };
            htmlOptionsMonthDays.push(option);
        }

        const weeksOfYear = [{ value: 1, display: "First Week of the Year" }, { value: -1, display: "Last Week of the Year" }];
        for (let j = 2; j <= 53; j++) {
            let cWeek = { value: j, display: 'Semaine #' + j + ' de l année' };
            weeksOfYear.push(cWeek);
        }

        const daysofYear = [{ value: 1, display: 'Premier jour de l année' }, { value: -1, display: 'Dernier jour de l année' }];
        for (let k = 2; k <= 366; k++) {
            let cDay = { value: k, display: 'Jour #' + k + ' de l année' };
            daysofYear.push(cDay);
        }

        const additionalMthYrRules = this.props.additionalMthYrRules;

        const htmlAdditionalMthYrRules = additionalMthYrRules.map((mthYrRule) =>
            <div className="form-row mb-3" key={mthYrRule.customIndex}>
                <div className="col-md-auto pt-1 ml-4">
                </div>
                <FreqYrMthYrRule customIndex={mthYrRule.customIndex} slctBxMthName={mthYrRule.slctBxMthName} slctBxMthId={mthYrRule.slctBxMthId} slctBxMthOptions={htmlOptionsYearMonths} slctBxMthVal={mthYrRule.slctBxMthVal} changeSlctBxMth={this.changeSlctBxMth} slctBxMthDisabled={mthYrRule.slctBxMthDisabled} slctBxMthAddClss="my-2" chkBxActivateMthDayName={mthYrRule.chkBxActivateMthDayName} chkBxActivateMthDayDisabled={mthYrRule.chkBxActivateMthDayDisabled} chkBxActivateMthDayChecked={mthYrRule.chkBxActivateMthDayChecked} activateMthDay={this.activateMthDay} slctBxMthDayName={mthYrRule.slctBxMthDayName} slctBxMthDayOptions={htmlOptionsMonthDays} slctBxMthDayVal={mthYrRule.slctBxMthDayVal} changeSlctBxMthDay={this.changeSlctBxMthDay} slctBxMthDayDisabled={mthYrRule.slctBxMthDayDisabled} slctBxMthDayAddClss="my-2" chkBxActivateWkDayName={mthYrRule.chkBxActivateWkDayName} chkBxActivateWkDayDisabled={mthYrRule.chkBxActivateWkDayDisabled} chkBxActivateWkDayChecked={mthYrRule.chkBxActivateWkDayChecked} activateWkDay={this.activateWkDayInMthYrRule} slctBxWkDayRkName={mthYrRule.slctBxWkDayRkName} slctBxWkDayRkOptions={htmlOptionsWeekDaysRanks} slctBxWkDayRkVal={mthYrRule.slctBxWkDayRkVal} changeSlctBxWkDayRk={this.changeSlctBxWkDayRkInMthYrRule} slctBxWkDayRkDisabled={mthYrRule.slctBxWkDayRkDisabled} slctBxWkDayRkAddClss="my-2" weekDayCheckboxName={weekDayCheckboxNameInMthYrRule} weekDaysToCheck={mthYrRule.weekDaysToCheck} changeWeekDays={this.changeWeekDaysInMthYrRule} showAddNewRuleButton={mthYrRule.showAddNewRuleButton} showRemoveCurrentRuleButton={mthYrRule.showRemoveCurrentRuleButton} addNewRule={this.addNewMthYrRule} removeRule={this.removeMthYrRule} />
            </div>
        );

        let mainFreqYrMthYrRuleProps = { customIndex: 1, slctBxMthName: slctBxMthName, slctBxMthId: slctBxMthId, slctBxMthOptions: htmlOptionsYearMonths, slctBxMthVal: slctBxMthVal, changeSlctBxMth: this.changeSlctBxMth, slctBxMthDisabled: slctBxMthDisabled, slctBxMthAddClss: "my-2", chkBxActivateMthDayName: chkBxActivateMthDayName, chkBxActivateMthDayDisabled: chkBxActivateMthDayDisabled, chkBxActivateMthDayChecked: chkBxActivateMthDayChecked, activateMthDay: this.activateMthDay, slctBxMthDayName: slctBxMthDayName, slctBxMthDayOptions: htmlOptionsMonthDays, slctBxMthDayVal: slctBxMthDayVal, changeSlctBxMthDay: this.changeSlctBxMthDay, slctBxMthDayDisabled: slctBxMthDayDisabled, slctBxMthDayAddClss: "my-2", chkBxActivateWkDayName: chkBxActivateWkDayInMthYrRuleName, chkBxActivateWkDayDisabled: chkBxActivateWkDayInMthYrRuleDisabled, chkBxActivateWkDayChecked: chkBxActivateWkDayInMthYrRuleChecked, activateWkDay: this.activateWkDayInMthYrRule, slctBxWkDayRkName: slctBxWkDayRkInMthYrRuleName, slctBxWkDayRkOptions: htmlOptionsWeekDaysRanks, slctBxWkDayRkVal: slctBxWkDayRkInMthYrRuleVal, changeSlctBxWkDayRk: this.changeSlctBxWkDayRkInMthYrRule, slctBxWkDayRkDisabled: slctBxWkDayRkInMthYrRuleDisabled, slctBxWkDayRkAddClss: "my-2", weekDayCheckboxName: weekDayCheckboxNameInMthYrRule, weekDaysToCheck: weekDaysToCheckInMthYrRule, changeWeekDays: this.changeWeekDaysInMthYrRule, addNewRule: this.addNewMthYrRule };
        if (additionalMthYrRules.length > 0) {
            mainFreqYrMthYrRuleProps['showAddNewRuleButton'] = false;
        }

        const additionalWkYrRules = this.props.additionalWkYrRules;

        let mainFreqYrWkYrRuleProps = { customIndex: 1, slctBxWkYrName: slctBxWkYrName, slctBxWkYrOptions: weeksOfYear, slctBxWkYrVal: slctBxWkYrVal, changeSlctBxWkYr: this.changeSlctBxWkYr, slctBxWkYrDisabled: slctBoxWkYrDisabled, slctBxWkYrAddClss: "my-2", weekDayCheckboxName: weekDayCheckboxNameInWkYrRule, weekDaysToCheck: weekDaysToCheckInWkYrRule, changeWeekDays: this.changeWeekDaysInWkYrRule, addNewRule: this.addNewWkYrRule };
        mainFreqYrWkYrRuleProps['showAddNewRuleButton'] = false;
        if (activateWkYr && additionalWkYrRules.length === 0) {
            mainFreqYrWkYrRuleProps['showAddNewRuleButton'] = true;
        }


        return (
            <div>
                <div className="form-row mb-3">
                    <div className="col-md-auto pt-1">
                        <CheckBox name="customYear2" id="customYear21" value="1" checked={activateMthYr} parentHandlerChange={this.activateMthYrCustom} />
                    </div>

                    <FreqYrMthYrRule {...mainFreqYrMthYrRuleProps} />
                </div>

                {htmlAdditionalMthYrRules}

            </div>
        );
    }
}