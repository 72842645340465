import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainPage from '../../pages/MainPage'
import Pdc from '../../pages/Pdc'
import Cgv from '../../pages/Cgv'
import MyDemo from '../../pages/MyDemo'
import MyDemo2 from '../../pages/MyDemo2'
import LanzoDemo from '../../pages/LanzoDemo'
import Login from '../../pages/Login'
import AdminPage from '../../pages/AdminPage'
import { StateProvider } from '../AppContext'

const index = () => {
    return (
        <StateProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/demos" element={<LanzoDemo />} />
                    <Route path="/mydemo" element={<MyDemo />} />
                    <Route path="/mydemo2" element={<MyDemo2 />} />
                    <Route path="/cgv" element={<Cgv />} />
                    <Route path="/pdc" element={<Pdc />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/adminpage" element={<AdminPage />} />

                    <Route path="*" element={<MainPage />} />
                </Routes>
            </Router>
        </StateProvider>
    )
}

export default index
