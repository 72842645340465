import { HOURS, MONTHNAMES } from '../Constants';
import CellHour from "./CellHour";
import '../styles/ViewDay.css';
import { StateContext } from './AppContext';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

function ViewDay() {
    const [refresh, setRefresh] = useState(false)
    const statecont = useContext(StateContext)
    const [eventsDay, setEventsDay] = useState([])
    const date = MONTHNAMES[statecont.month] + ", " + statecont.day + " " + statecont.year;

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/api/event`,
            withCredentials: true
        }).then((res) => {
            setEventsDay(res.data.events)
        })

    }, [refresh])

    const refreshevent = () => {
        setRefresh(!refresh)
    }

    return (
        <div className="row pb-4 ps-5">
            {HOURS.map((hour) => <CellHour key={hour} hour={hour} startDate={date + " " + hour} events={eventsDay} refreshevent={refreshevent} />)}
        </div>
    );
}

export default ViewDay