import '../styles/Demo.css'
import 'animate.css'

function Demo() {
    return (
        <div id="Demo" className="animate__animated animate__zoomInDown">
            <div className="container">
                <h1 className="demo-title">Démo</h1>
                <div className="demo-border">
                    <p className="h5 text-center">Un avant-goût de la puissance de LANZOcode</p><br />
                    <p className="ident-text">Vous imaginez, nous réalisons, vous profitez de votre outil ... Tel est le service tel que nous le concevons.</p>
                    <p className="ident-text">Sur ordinateur, tablette ou smartphone, découvrez un aperçu des fonctionnalités et graphismes que nous pouvons développer pour vous.</p>
                    <div className="text-center">
                        <a href="/demos" className="btn-click">Découvrir <img src="./img/Smileynoir.png" alt="smileynoir" className="btn-smiley" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Demo
