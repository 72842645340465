import { useState, useMemo, useEffect } from 'react'
import '../styles/Galery.css'

function Galery() {

    const menuGalery = useMemo(() => [
        { "menu": "All", "images": ["../img/car3.jpg", "../img/Game3.jpg", "../img/Nature5.jpg", "../img/Game6.jpg", "../img/Nature2.jpg", "../img/car5.jpg", "../img/Game1.jpg", "../img/Nature2.jpg"] },
        { "menu": "Game", "images": ["../img/Game1.jpg", "../img/Game2.jpg", "../img/Game3.jpg", "../img/Game4.jpg", "../img/Game5.jpg", "../img/Game6.jpg", "../img/Game7.jpg", "../img/Game8.jpg"] },
        { "menu": "Nature", "images": ["../img/Nature1.jpg", "../img/Nature2.jpg", "../img/Nature3.jpg", "../img/Nature4.jpg", "../img/Nature5.jpg", "../img/Nature6.jpg"] },
        { "menu": "Car", "images": ["../img/car1.jpg", "../img/car2.jpg", "../img/car3.jpg", "../img/car4.jpg", "../img/car5.jpg", "../img/car6.jpg"] }
    ], [])
    const [menu, setMenu] = useState("All")
    const [galery, setGalery] = useState("")
    const [listMenu, setListMenu] = useState("")

    useEffect(() => {
        const listGalery = menuGalery.map((currentMenu, idxMenu) => {
            if (menu === currentMenu.menu)
                return <li key={idxMenu} className="menu-active" onClick={() => changeRealisation(currentMenu.menu)}>{currentMenu.menu}</li>
            else
                return <li key={idxMenu} onClick={() => changeRealisation(currentMenu.menu)}>{currentMenu.menu}</li>
        })
        setListMenu(listGalery)
        const changeRealisation = (newMenu) => {
            let tempImages = [];
            for (const currentMenu of menuGalery) {
                if (newMenu === currentMenu.menu) {
                    tempImages = currentMenu.images.map((image, idxImage) => {
                        return <div key={idxImage}> <img src={image} alt="phototest" /></div>
                    })
                }
            }
            const elementGalery = <div className="imgs-galery">{tempImages}</div>
            setMenu(menu => newMenu)
            setGalery(galery => elementGalery)
        }
        changeRealisation(menu)
    }, [menu, menuGalery])

    return (
        <div className="galery-container">
            <div className="demo-name">Votre galerie d'images</div>
            <div>
                <ul className="galery-menu">
                    {listMenu}
                </ul>
                {galery}
            </div>
        </div>
    )
}

export default Galery
