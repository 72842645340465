import React from "react";
import ViewTitle from "./ViewTitle";
import { useContext } from "react";
import { StateContext } from "./AppContext";
import '../styles/ControlViewCalendar.css'

function ControlViewCalendar() {
    const stateCont = useContext(StateContext)

    return (
        <div className="view-cal d-flex pt-2 pb-4">
            <div className="w-25 text-center">
                <div className="text-left d-inline">
                    {(stateCont.selectedView === 'Day') && <button className="btn border border-dark ms-5" onClick={() => stateCont.getToday()}>Ajourd'hui</button>}
                    <button className="btn border border-dark ms-4"><i className="far fa-arrow-left" onClick={() => stateCont.previousArrow()}></i></button>
                    <button className="btn border border-dark ms-2"><i className="far fa-arrow-right" onClick={() => stateCont.nextArrow()}></i></button>
                </div>
            </div>
            <div className="w-50">
                <ViewTitle />
            </div>
            <div className="w-25 text-center">
                <div className="btn-group">
                    {(stateCont.selectedView !== 'Day') && <button className="btn border border-dark" onClick={() => stateCont.changeView('Day', stateCont.day, stateCont.month, stateCont.year)}>Jour</button>}
                    {(stateCont.selectedView !== 'Week') && <button className="btn border border-dark" onClick={() => stateCont.changeView('Week', stateCont.day, stateCont.month, stateCont.year)}>Semaine</button>}
                    {(stateCont.selectedView !== 'Month') && <button className="btn border border-dark" onClick={() => stateCont.changeView('Month', stateCont.day, stateCont.month, stateCont.year)}>Mois</button>}
                    {(stateCont.selectedView !== 'Year') && <button className="btn border border-dark" onClick={() => stateCont.changeView('Year', stateCont.day, stateCont.month, stateCont.year)}>Année</button>}
                </div>
            </div>
        </div>
    );

}

export default ControlViewCalendar