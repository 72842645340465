import { useEffect, useContext, useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core'
import axios from 'axios'
import { UserContext } from '../components/AppContext'

function Invoice() {
    const [invoiceDatas, setInvoiceData] = useState([])
    const [defaultInvoiceDatas, setDefaultInvoiceData] = useState([])
    const userLogged = useContext(UserContext)
    useEffect(() => {
        if (userLogged && userLogged !== null) {
            if (userLogged.role === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/invoice`,
                    withCredentials: true
                }).then((res) => {
                    setInvoiceData(res.data.invoice)
                    setDefaultInvoiceData(res.data.invoice)
                })
            } else {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/api/invoice/${userLogged.id}`,
                    withCredentials: true
                }).then((res) => {
                    setInvoiceData(res.data.invoice)
                    setDefaultInvoiceData(res.data.invoice)
                })
            }
        }
    }, [userLogged])

    return (
        <div id="Invoice" className="tabcontent" style={{ flexDirection: "column" }}>
            <div className='d-flex flex-row px-2'>
                <input type="text" id="refSearch" className="refSearch" onChange={(e) => { if (e.target.value === "") { setInvoiceData(defaultInvoiceDatas) } else { setInvoiceData(defaultInvoiceDatas.filter(invoice => invoice.ref.includes(e.target.value))) } }} placeholder="Recherche par réference" />
            </div>
            <div className='d-flex flex-row justify-content-center px-2'>
                <b><label htmlFor="startDate">Entre</label></b>
                <input type="date" id="startDate" className="mx-2" onChange={(e) => { setInvoiceData(defaultInvoiceDatas.filter(invoice => new Date(invoice.date_invoice) > new Date(e.target.value))) }} />
                <b><label htmlFor="endDate">Et</label></b>
                <input type="date" id="endDate" className="mx-4" onChange={(e) => { setInvoiceData(defaultInvoiceDatas.filter(invoice => new Date(invoice.date_invoice) < new Date(e.target.value))) }} />
            </div><br />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                {invoiceDatas.map((invoiceData, idInvoice) => {
                    return (
                        <div className='d-flex flex-row justify-content-around' key={idInvoice} style={{ fontSize: "20px", fontWeight: 600 }}>
                            <div>
                                <div>Ref: {invoiceData.ref} </div>
                                <div>Date: {new Date(invoiceData.date_invoice).toLocaleDateString()}</div>
                                <a href={invoiceData.path} target="_blank" rel="noreferrer">Ouvrir</a>
                            </div>
                            <div>
                                <div className='text-center'>Aperçu</div>
                                <Viewer key={idInvoice} fileUrl={invoiceData.path} />
                            </div>
                        </div>
                    )
                })}
            </Worker>
        </div>
    )
}

export default Invoice