function PaginationProject({ nameProject, lenProject, idxProject, next, previous, setPage }) {
    if (lenProject === 0) {
        return (
            <div className="menu">
                <span className="text" onClick={() => setPage(2)}>Veuillez Ajouter Nouveau Projet</span>
            </div>
        )

    } else {
        return (
            <>
                {
                    (idxProject === 0) ?
                        (
                            <></>
                        ) :
                        (
                            <div className="previous" onClick={() => previous()}>
                                <i className="far fa-chevron-circle-left" style={{ color: '#D40808', fontSize: '22px' }}></i>
                            </div>
                        )
                }
                <div className="menu">
                    <span className="text" onClick={() => setPage(3)}>{nameProject}</span>
                </div>
                <div className="icon">
                    <span className="plus" onClick={() => setPage(2)}>+</span>
                </div>
                {
                    ((lenProject - 1) === idxProject) ?
                        (
                            <></>
                        ) :
                        (
                            <div className="next" onClick={() => next()}>
                                <i className="far fa-chevron-circle-right" style={{ color: '#D40808', fontSize: '22px' }}></i>
                            </div>
                        )
                }

            </>
        );
    }
}

export default PaginationProject