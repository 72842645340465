import axios from "axios";
import { useEffect, useState } from "react";
import "../styles/ChatOnline.css";

export default function ChatOnline({ onlineUsers, currentId, setCurrentChat }) {
    const [friends, setFriends] = useState([]);
    const [onlineFriends, setOnlineFriends] = useState([]);

    useEffect(() => {
        const getFriends = async () => {
            if (currentId) {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/friends/${currentId}`);
                setFriends(res.data);
            }
        };
        getFriends();
    }, [currentId]);

    useEffect(() => {
        setOnlineFriends(friends.filter((f) => onlineUsers?.includes(f._id)));
    }, [friends, onlineUsers]);

    const handleClick = async (user) => {
        try {
            const res = await axios.get(
                `/conversations/find/${currentId}/${user._id}`
            );
            setCurrentChat(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="chatOnline">
            <div className="text-center">En ligne</div>
            {onlineFriends.map((o, idxO) => (
                <div key={idxO} className="chatOnlineFriend" onClick={() => handleClick(o)}>
                    <div className="chatOnlineImgContainer">
                        <img
                            className="chatOnlineImg"
                            src={
                                o?.picture
                                    ? o.picture
                                    : "person/noAvatar.png"
                            }
                            alt=""
                        />
                        <div className="chatOnlineBadge"></div>
                    </div>
                    <span className="chatOnlineName">{o?.pseudo}</span>
                </div>
            ))}
        </div>
    );
}