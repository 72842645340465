import React from 'react';
import RadioButton from './RadioButton';
import SelectBox from './SelectBox';

export default class BlockFrequency extends React.Component {
    constructor(props) {
        super(props);

        this.changeFreqInterval = this.changeFreqInterval.bind(this);
        this.frequencyHandlerChange = this.frequencyHandlerChange.bind(this);
        this.hideAllBlockFrequenciesHandler = this.hideAllBlockFrequenciesHandler.bind(this);
        this.showBlockFrequencyWeekFirstLevelHandler = this.showBlockFrequencyWeekFirstLevelHandler.bind(this);
        this.showBlockFrequencyMonthFirstLevelHandler = this.showBlockFrequencyMonthFirstLevelHandler.bind(this);
        this.showBlockFreqYr1stLevHandler = this.showBlockFreqYr1stLevHandler.bind(this);
    }

    changeFreqInterval(value) {
        this.props.changeFreqInterval(value);
    }

    frequencyHandlerChange(value) {
        this.props.frequencyHandlerChange(value);
    }

    hideAllBlockFrequenciesHandler(value) {
        this.props.hideAllBlockFrequenciesHandler(value);
    }

    showBlockFrequencyWeekFirstLevelHandler(value) {
        this.props.showBlockFrequencyWeekFirstLevelHandler(value);
    }

    showBlockFrequencyMonthFirstLevelHandler(value) {
        this.props.showBlockFrequencyMonthFirstLevelHandler(value);
    }

    showBlockFreqYr1stLevHandler(value) {
        this.props.showBlockFreqYr1stLevHandler(value);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        let options = [];
        for (let i = 1; i <= 366; i++) {
            let option = { value: i, display: i };
            options.push(option);
        }

        const freqInterval = this.props.freqInterval;
        const selectedFrequency = this.props.selectedFrequency;

        return (
            <div className="form-row mb-3">
                <div className="col-md-auto">
                    <label className="my-1 mr-2" htmlFor="Every">Intervalle:</label>
                    <SelectBox name="Every" id="Every" options={options} value={freqInterval} additionalClasses="my-1 w-50" parentHandlerChange={this.changeFreqInterval} />
                </div>
                <div className="col-auto my-2">

                    <RadioButton name="FREQ" id="FREQ1" value="DAILY" checked={selectedFrequency === "DAILY"} parentHandlerChange={this.frequencyHandlerChange} label="Jour" isInline="true" hideAllBlockFrequenciesHandler={this.hideAllBlockFrequenciesHandler} />

                    <RadioButton name="FREQ" id="FREQ2" value="WEEKLY" checked={selectedFrequency === "WEEKLY"} parentHandlerChange={this.frequencyHandlerChange} label="Semaine" isInline="true" hideAllBlockFrequenciesHandler={this.hideAllBlockFrequenciesHandler} showBlockFrequencyWeekFirstLevelHandler={this.showBlockFrequencyWeekFirstLevelHandler} />

                    <RadioButton name="FREQ" id="FREQ3" value="MONTHLY" checked={selectedFrequency === "MONTHLY"} parentHandlerChange={this.frequencyHandlerChange} label="Mois" isInline="true" hideAllBlockFrequenciesHandler={this.hideAllBlockFrequenciesHandler} showBlockFrequencyMonthFirstLevelHandler={this.showBlockFrequencyMonthFirstLevelHandler} />

                    <RadioButton name="FREQ" id="FREQ4" value="YEARLY" checked={selectedFrequency === "YEARLY"} parentHandlerChange={this.frequencyHandlerChange} label="Année" isInline="true" hideAllBlockFrequenciesHandler={this.hideAllBlockFrequenciesHandler} showBlockFreqYr1stLevHandler={this.showBlockFreqYr1stLevHandler} />

                </div>
            </div>
        );
    }
}