import '../styles/Design3.css'

function Design3() {
    return (
        <div className="design3">
            <ul>
                <li className='liRed'>
                    <span data-text="&nbsp;&nbsp;Accueil">&nbsp;&nbsp;Accueil&nbsp;</span>
                </li>
                <li className='liBlue'>
                    <span data-text="&nbsp;&nbsp;Offres">&nbsp;&nbsp;Offres&nbsp;</span>
                </li>
                <li className='liGreen'>
                    <span data-text="&nbsp;&nbsp;Demo">&nbsp;&nbsp;Demo&nbsp;</span>
                </li>
                <li className='liYellow'>
                    <span data-text="&nbsp;&nbsp;Contact">&nbsp;&nbsp;Contact&nbsp;</span>
                </li>
                <li className='liWhite'>
                    <span data-text="&nbsp;&nbsp;LANZOcode">&nbsp;&nbsp;LANZOcode&nbsp;</span>
                </li>
            </ul>
        </div>
    )
}

export default Design3