import React from 'react';
import InputDate from './InputDate';

export default class InputDateRange extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeStartDateTime = this.handleChangeStartDateTime.bind(this);
        this.handleChangeEndDateTime = this.handleChangeEndDateTime.bind(this);
    }

    handleChangeStartDateTime(value) {
        this.props.handleChangeStartDateTime(value);
    }

    handleChangeEndDateTime(value) {
        this.props.handleChangeEndDateTime(value);
    }

    render() {

        const startDateTime = this.props.startDateTime;
        const endDateTime = this.props.endDateTime;
        const endDateTimeDisabled = this.props.endDateTimeDisabled;

        return (
            <div className="form-row mb-3 d-flex">

                <InputDate name="startDateTime" id="startDateTime" value={startDateTime} handleChange={this.handleChangeStartDateTime} />
                <div style={{ width: '20px ' }}></div>
                <InputDate name="endDateTime" id="endDateTime" value={endDateTime} handleChange={this.handleChangeEndDateTime} disabled={endDateTimeDisabled} />
            </div>
        );
    }
}