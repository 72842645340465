import axios from "axios";
import { useState, useEffect } from "react";
import "../styles/Conversation.css";

export default function Conversation({ conversation, currentUser, filterFriend }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const friendId = conversation.members.find((m) => m !== currentUser.id);
        const getUser = async () => {
            try {
                const res = await axios(`${process.env.REACT_APP_API_URL}/api/user/${friendId}`);
                setUser(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getUser();
    }, [currentUser, conversation]);


    if (filterFriend === "" || (user && user.pseudo && user.pseudo.includes(filterFriend))) {
        return (
            <div className="conversation" >
                <img
                    className="conversationImg"
                    src={
                        user?.picture
                            ? user.picture
                            : "/data/profile/random-user.png"
                    }
                    alt=""
                />
                <span className="conversationName">{user?.pseudo}</span>
            </div>
        )
    } else
        return ""
}


