import React from 'react';
import BlockWeekDays from './BlockWeekDays';

export default class BlockFrequencyWeekSecondLevel extends React.Component {

    constructor(props) {
        super(props);

        this.changeWeekDays = this.changeWeekDays.bind(this);
    }

    changeWeekDays(event) {
        this.props.changeWeekDays(event);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const weekDayCheckboxName = this.props.weekDayCheckboxName;
        const weekDaysToCheck = this.props.weekDaysToCheck;
        return (
            <BlockWeekDays weekDayCheckboxName={weekDayCheckboxName} weekDaysToCheck={weekDaysToCheck} show={this.props.show} changeWeekDays={this.changeWeekDays} />
        );
    }
}