import { useContext, useEffect, useState } from 'react'
import '../styles/TaskManager.css'
import { StateContext } from './AppContext'
import axios from 'axios'

export default function TaskManager() {
    const stateCont = useContext(StateContext)
    const [taches, setTaches] = useState([])
    useEffect(() => {
        if (stateCont.task != null) {
            let urlTask = (stateCont.userLogged.role === 1) ? `${process.env.REACT_APP_API_URL}/api/tache` : `${process.env.REACT_APP_API_URL}/api/tache/user/${stateCont.userLogged.id}`
            const getTaches = async () => {
                const res = await axios({
                    method: "get",
                    url: urlTask,
                    withCredentials: true
                })
                setTaches(res.data.taches)
            }
            getTaches()
        }
    }, [])
    return (
        <div id="To_Do_List" className="tabcontent" style={{ flexDirection: "column" }}>
            <div className="container bg-light">
                <div className="table-wrap table-responsive">
                    <div className='d-flex flex-row justify-content-between'>
                        <h3 className="title">Tâches</h3>
                    </div>
                    <table className="table table-borderless">
                        <thead>
                            <tr className="p-0">
                                <th className="text-left w100 p-0 py-2" scope="col">
                                    <small className="text-muted">Nom</small>
                                </th>
                                <th className="text-center w100 p-0 py-2" scope="col">
                                    <small className="text-muted">Priorité</small>
                                </th>
                                <th className="text-center w100 p-0 py-2" scope="col">
                                    <small className="text-muted">Avancement</small>
                                </th>
                                <th className="text-center w100 p-0 py-2" scope="col">
                                    <small className="text-muted">Statut</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {taches.map((task, idTask) => {
                                let taskColor = ''
                                if (task.status === 'En cours')
                                    taskColor = 'yellow'
                                else if (task.status === 'Terminé')
                                    taskColor = 'green'
                                else if (task.status === 'En retard')
                                    taskColor = 'pink'
                                else if (task.status === 'Non assigné')
                                    taskColor = 'gray'
                                return (
                                    <tr key={idTask} className="border-bottom bg-white">
                                        <td className="row">
                                            <div className="d-flex align-items-center">
                                                <span className={"bg-" + taskColor + " mx-2"}></span>
                                                <span onClick={() => stateCont.openTask(task._id)}>{task.tache}</span>
                                            </div>
                                        </td>
                                        <td className="text-center"><span className="text-muted">{task.priorite}</span></td>
                                        <td className="text-center"><span className="text-muted">{task.avancement}%</span></td>
                                        <td className="text-center"><span className={"btn btn-secondary " + taskColor}>{task.status}</span></td>
                                    </tr>
                                )
                            })}

                            {/* <tr>
                                <td colSpan={4}>
                                    <ul class="pagination justify-content-center text-dark">
                                        <li class="page-item"><button class="page-link">1</button></li>
                                        <li class="page-item"><button class="page-link">2</button></li>
                                        <li class="page-item"><button class="page-link">»</button></li>
                                    </ul>
                                </td>
                            </tr> */}

                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}
