import React from 'react';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import $ from "jquery";
import Event from './EventHandler';
import '../styles/ViewDay.css';
import { rrulestr } from 'rrule';
import { StateContext } from './AppContext';

export default class EventDayCell extends React.Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        const { event } = this.props;
        this.state = {
            showModal: false,
            showModal2: false,
            boundTop: 0,
            boundBottom: 0,
            currId: event.id,
            startHour: event.startHour,
            endHour: event.endHour,
            activeDrags: 0,
            recurringRule: event.recurringRule,
            controlledPosition: {
                x: 0, y: 0
            },
            checkedRecurrency: "1"
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
        this.handleCancelModal = this.handleCancelModal.bind(this);
        this.handleCancelModal2 = this.handleCancelModal2.bind(this);
        this.recurrencyEvent = this.recurrencyEvent.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleOpenModal2() {
        this.setState({ showModal2: true, showModal: false, });
    }

    handleCloseModal = async () => {
        this.setState({ showModal: false });
        const { event } = this.props;
        const { formatDate, day, month, year, pullDayEvents, formatTzDate } = this.context;
        let newStartDateTime = new Date(formatDate(new Date(event.cellDate)) + " " + this.state.startHour);
        let newEndDateTime = "";
        if (event.allDay !== 1) {
            newEndDateTime = new Date(formatDate(new Date(event.cellDate)) + " " + this.state.endHour);
        }
        if (this.state.checkedRecurrency === "1") {
            let excludeDate = new Date(event.cellDate + " " + event.startHour);
            let excludeTzDate = formatTzDate(excludeDate);
            let strDate = excludeTzDate;
            const regexDate = RegExp(strDate);
            const regexExDate = RegExp('EXDATE');
            let newEventRecurringRule = "";
            if (regexExDate.test(event.recurringRule)) {
                if (!regexDate.test(event.recurringRule)) {
                    newEventRecurringRule = event.recurringRule + "," + excludeTzDate;
                }
            } else {
                newEventRecurringRule = event.recurringRule + "\nEXDATE:" + excludeTzDate;
            }

            $.ajax({
                url: "../getEvent.cfm",
                method: "GET",
                async: false,
                data: { id: this.state.currId },
                success: (response) => {
                    let result = JSON.parse(response);
                    if (result.recurringRule) {
                        let eventDataObj = {
                            id: result.id,
                            title: result.title,
                            allDay: result.allDay,
                            startDateTime: result.startDateTime,
                            endDateTime: result.endDateTime,
                            recurrencyRule: newEventRecurringRule,
                            location: result.location,
                            description: result.description,
                            parentId: result.parentId
                        };
                        let newEventDataObj = {
                            title: result.title,
                            allDay: result.allDay,
                            startDateTime: newStartDateTime,
                            endDateTime: newEndDateTime,
                            recurrencyRule: "",
                            location: result.location,
                            description: result.description,
                            parentId: result.id
                        }
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            async: false,
                            data: eventDataObj,
                            success: function () {
                                console.log('succes event', eventDataObj);
                            }
                        });
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            data: newEventDataObj,
                            async: false,
                            success: (data) => {
                                let eventData = JSON.parse(data);
                                console.log('success new event' + eventData.id, eventData);
                                this.setState({
                                    currId: eventData.id,
                                    recurringRule: ''
                                })
                            }
                        });
                    } else {
                        let eventDataObj = {
                            id: this.state.currId,
                            title: event.title,
                            allDay: event.allDay,
                            startDateTime: newStartDateTime,
                            endDateTime: newEndDateTime,
                            recurrencyRule: "",
                            location: event.location,
                            description: event.description,
                            parentId: event.parentId
                        };
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            async: false,
                            data: eventDataObj,
                            success: function () {
                                console.log('not reccuring rule', eventDataObj);
                            }
                        });
                    }
                }
            });
        } else if (this.state.checkedRecurrency === "2") {
            let untilDate = new Date(event.cellDate);
            let untilTzDate = formatTzDate(untilDate);
            let strDate = untilTzDate;
            const regexDate = RegExp(strDate);
            const regexExDate = RegExp('EXDATE');
            let regexUntilDateVal = RegExp(';UNTIL=(.+?)*');
            let newEventRecurringRule = "";
            if (regexExDate.test(event.recurringRule)) {
                if (!regexDate.test(event.recurringRule)) {
                    let regexExDateVal = RegExp('\nEXDATE:(.+?)*');
                    let strExDate = regexExDateVal.exec(event.recurringRule)[0];
                    newEventRecurringRule = event.recurringRule.replace(regexExDateVal, '');
                    if (regexUntilDateVal.test(event.recurringRule)) {
                        newEventRecurringRule = newEventRecurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilTzDate + strExDate;
                    } else {
                        newEventRecurringRule = newEventRecurringRule + ";UNTIL=" + untilTzDate + strExDate;
                    }
                }
            } else {
                if (regexUntilDateVal.test(event.recurringRule)) {
                    newEventRecurringRule = event.recurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilTzDate;
                } else {
                    newEventRecurringRule = event.recurringRule + ";UNTIL=" + untilTzDate;
                }
            }

            $.ajax({
                url: "../getEvent.cfm",
                method: "GET",
                async: false,
                data: { id: this.state.currId },
                success: (response) => {
                    let result = JSON.parse(response);
                    if (result.recurringRule) {
                        if (result.parentId) {
                            let startDate = new Date(result.startDateTime);
                            let dtStart = formatTzDate(startDate);
                            let stringRule = result.recurringRule + "\nDTSTART:" + dtStart;
                            const rule = rrulestr(stringRule);
                            const allRecurrency = rule.between(new Date(formatDate(new Date(result.startDateTime)) + " 00:00"), new Date(formatDate(newStartDateTime) + " 23:59"));
                            console.log('rank recurrency:' + allRecurrency.length);
                            if (allRecurrency.length <= 1) {
                                let eventDataObj = {
                                    id: result.id,
                                    title: result.title,
                                    allDay: result.allDay,
                                    startDateTime: newStartDateTime,
                                    endDateTime: newEndDateTime,
                                    recurrencyRule: result.recurringRule,
                                    location: result.location,
                                    description: result.description,
                                    parentId: result.parentId
                                };
                                $.ajax({
                                    url: "../saveEvent.cfm",
                                    method: "POST",
                                    async: false,
                                    data: eventDataObj,
                                    success: () => {
                                        console.log('success update event' + result.id, eventDataObj);
                                    }
                                });
                            } else {
                                const dateCellUntil = new Date(allRecurrency[allRecurrency.length - 1]);
                                let regexUntilDateVal = RegExp(';UNTIL=(.+?)*');
                                let untilCellTzDate = formatTzDate(new Date(formatDate(new Date(dateCellUntil)) + " 00:00"));
                                if (regexExDate.test(result.recurringRule)) {
                                    if (!regexDate.test(result.recurringRule)) {
                                        let regexExDateVal = RegExp('\nEXDATE:(.+?)*');
                                        let strExDate = regexExDateVal.exec(result.recurringRule)[0];
                                        newEventRecurringRule = result.recurringRule.replace(regexExDateVal, '');
                                        if (regexUntilDateVal.test(result.recurringRule)) {
                                            newEventRecurringRule = newEventRecurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilCellTzDate + strExDate;
                                        } else {
                                            newEventRecurringRule = newEventRecurringRule + ";UNTIL=" + untilCellTzDate + strExDate;
                                        }
                                    }
                                } else {
                                    if (regexUntilDateVal.test(result.recurringRule)) {
                                        newEventRecurringRule = result.recurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilCellTzDate;
                                    } else {
                                        newEventRecurringRule = result.recurringRule + ";UNTIL=" + untilCellTzDate;
                                    }
                                }

                                let eventDataObj = {
                                    id: result.id,
                                    title: result.title,
                                    allDay: result.allDay,
                                    startDateTime: result.startDateTime,
                                    endDateTime: result.endDateTime,
                                    recurrencyRule: newEventRecurringRule,
                                    location: result.location,
                                    description: result.description
                                };
                                let newEventDataObj = {
                                    title: result.title,
                                    allDay: result.allDay,
                                    startDateTime: newStartDateTime,
                                    endDateTime: newEndDateTime,
                                    recurrencyRule: result.recurringRule,
                                    location: result.location,
                                    description: result.description,
                                    parentId: result.id
                                };
                                $.ajax({
                                    url: "../saveEvent.cfm",
                                    method: "POST",
                                    async: false,
                                    data: eventDataObj,
                                    success: function () {
                                        console.log('succes event', eventDataObj);
                                    }
                                });
                                $.ajax({
                                    url: "../saveEvent.cfm",
                                    method: "POST",
                                    data: newEventDataObj,
                                    async: false,
                                    success: (data) => {
                                        let eventData = JSON.parse(data);
                                        console.log('succes new event' + eventData.id, eventData);
                                        this.setState({
                                            currId: eventData.id,
                                        });
                                    }
                                });
                            }
                        } else {
                            let eventDataObj = {
                                id: result.id,
                                title: result.title,
                                allDay: result.allDay,
                                startDateTime: event.startDateTime,
                                endDateTime: event.endDateTime,
                                recurrencyRule: newEventRecurringRule,
                                location: event.location,
                                description: event.description
                            };
                            let newEventDataObj = {
                                title: result.title,
                                allDay: result.allDay,
                                startDateTime: newStartDateTime,
                                endDateTime: newEndDateTime,
                                recurrencyRule: result.recurringRule,
                                location: result.location,
                                description: result.description,
                                parentId: result.id
                            };
                            $.ajax({
                                url: "../saveEvent.cfm",
                                method: "POST",
                                async: false,
                                data: eventDataObj,
                                success: function () {
                                    console.log('succes event', eventDataObj);
                                }
                            });
                            $.ajax({
                                url: "../saveEvent.cfm",
                                method: "POST",
                                async: false,
                                data: newEventDataObj,
                                success: (data) => {
                                    let eventData = JSON.parse(data);
                                    console.log('succes new event' + eventData.id, eventData);
                                    this.setState({
                                        currId: eventData.id
                                    });
                                }
                            });
                        }
                    } else {
                        let eventDataObj = {
                            id: this.state.currId,
                            title: event.title,
                            allDay: event.allDay,
                            startDateTime: newStartDateTime,
                            endDateTime: newEndDateTime,
                            recurrencyRule: event.recurringRule,
                            location: event.location,
                            description: event.description,
                            parentId: event.parentId
                        };
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            async: false,
                            data: eventDataObj,
                            success: function () {
                                console.log('not reccuring rule', eventDataObj);
                            }
                        });
                    }
                }
            });
        } else if (this.state.checkedRecurrency === "3") {
            if (event.recurringRule) {
                newStartDateTime = new Date(formatDate(new Date(event.startDateTime)) + " " + this.state.startHour);
                if (event.allDay !== 1) {
                    newEndDateTime = new Date(formatDate(new Date(event.endDateTime)) + " " + this.state.endHour);
                }
            }
            $.ajax({
                url: "../getEvent.cfm",
                method: "GET",
                async: false,
                data: { id: this.state.currId },
                success: (response) => {
                    let result = JSON.parse(response);
                    if (result.recurringRule) {
                        $.ajax({
                            url: "../deleteParentEvent.cfm",
                            method: "POST",
                            async: false,
                            data: {
                                parentId: result.id
                            },
                            success: function () {
                                console.log('succes remove event with parentId:' + result.id + "without recurring rule");
                            }
                        });
                    }
                    let eventDataObj = {
                        id: result.id,
                        title: result.title,
                        allDay: result.allDay,
                        startDateTime: newStartDateTime,
                        endDateTime: newEndDateTime,
                        recurrencyRule: result.recurringRule,
                        location: result.location,
                        description: result.description,
                        parentId: result.parentId
                    };
                    $.ajax({
                        url: "../saveEvent.cfm",
                        method: "POST",
                        async: false,
                        data: eventDataObj,
                        success: function () {
                            console.log('succes event', eventDataObj);
                        }
                    });
                }
            });
        }
        let date = month + ", " + day + " " + year;
        const startDay = new Date(date);
        const endDay = new Date(date);
        await pullDayEvents(formatDate(startDay) + " 00:00", formatDate(endDay) + " 23:59");
    }

    handleCloseModal2 = async () => {
        const { formatDate, day, month, year, pullDayEvents } = this.context;
        this.setState({ showModal2: false, showModal: false });
        let date = month + ", " + day + " " + year;
        const startDay = new Date(date);
        const endDay = new Date(date);
        await pullDayEvents(formatDate(startDay) + " 00:00", formatDate(endDay) + " 23:59");
    }

    handleCancelModal() {
        const { event } = this.props;
        this.setState({
            showModal: false,
            controlledPosition: {
                x: 0, y: 0
            },
            startHour: event.startHour,
            endHour: event.endHour
        });
    }

    handleCancelModal2() {
        this.setState({ showModal2: false });
    }

    recurrencyEvent(e) {
        this.setState({
            checkedRecurrency: e.target.value
        });
    }

    onControlledDrag = (e, position) => {
        const { y } = position;
        const { event } = this.props;
        let startDateTime = new Date(event.cellDate + " " + event.startHour);
        let newStartDateTime = new Date(startDateTime.getTime() + y * 60000);
        let newStartHour = ("0" + newStartDateTime.getHours()).slice(-2) + ":" + ("0" + newStartDateTime.getMinutes()).slice(-2);
        let endDateTime = new Date(event.cellDate + " " + event.endHour);
        let newEndDateTime = new Date(endDateTime.getTime() + y * 60000);
        let newEndHour = ("0" + newEndDateTime.getHours()).slice(-2) + ":" + ("0" + newEndDateTime.getMinutes()).slice(-2);
        this.setState({
            startHour: newStartHour,
            endHour: newEndHour,
            controlledPosition: { x: 0, y }
        });
    };

    onStart = () => {
        let activeDrags = this.state.activeDrags + 1;
        this.setState({ activeDrags });
    };

    onStop = () => {
        const { event } = this.props;
        const { formatDate, day, month, year, pullDayEvents } = this.context;
        if ((this.state.controlledPosition.x !== 0 && this.state.controlledPosition.y === 0) || (this.state.controlledPosition.x === 0 && this.state.controlledPosition.y !== 0)) {
            if (this.state.recurringRule) {
                this.setState({ showModal: true });
            } else {
                let newStartDateTime = new Date(formatDate(new Date(event.startDateTime)) + " " + this.state.startHour);
                let newEndDateTime = "";
                if (event.allDay !== 1) {
                    newEndDateTime = new Date(formatDate(new Date(event.endDateTime)) + " " + this.state.endHour);
                }
                let eventDataObj = {
                    id: event.id,
                    title: event.title,
                    allDay: event.allDay,
                    startDateTime: newStartDateTime,
                    endDateTime: newEndDateTime,
                    recurrencyRule: event.recurringRule,
                    location: event.location,
                    description: event.description
                };
                console.log('succes not recurring rule', eventDataObj);
                $.ajax({
                    url: "../saveEvent.cfm",
                    method: "POST",
                    data: eventDataObj,
                    async: false,
                    success: function () {
                        console.log('succes', eventDataObj);
                    }
                });
                let date = month + ", " + day + " " + year;
                const startDay = new Date(date);
                const endDay = new Date(date);
                pullDayEvents(formatDate(startDay) + " 00:00", formatDate(endDay) + " 23:59");
            }
        }
    };

    formatHourAmPm = (hour) => {
        let amOrPm = (Number(hour.split(':')[0]) > 11) ? "PM" : "AM";
        let formatHour = (Number(hour.split(':')[0]) % 12).toString() + ':' + hour.split(':')[1].toString() + amOrPm;
        return formatHour;
    }

    componentDidMount() {
        const { diffMinute } = this.context;
        const { event } = this.props;
        this.setState({
            boundTop: diffMinute(event.startHour, "00:00"),
            boundBottom: diffMinute(event.endHour, "23:59")
        });
    }

    render() {
        // Cell height:60px ,width:300px
        // const { formatHourAmPm } = this.context;
        const { event } = this.props;
        const { controlledPosition } = this.state;
        const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
        let heightBlock = (event.height * 60).toString() + "px";
        let widthBlock = (300 - (event.distanceX * 10)).toString() + "px";
        let marginBlock = (event.distanceY * 60).toString() + "px 0px 0px " + (event.distanceX * 10).toString() + "px";
        let divStyle = {
            height: heightBlock,
            width: widthBlock,
            margin: marginBlock,
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "backgroundColor": "#2383df",
            "zIndex": "1"
        };
        let disabled = false;
        let displayTime = "";
        if (typeof event.allDay !== 'undefined' && event.allDay) {
            displayTime = "All day";
        } else {
            displayTime = this.formatHourAmPm(this.state.startHour) + "-" + this.formatHourAmPm(this.state.endHour);
        }
        if (event.allDay) {
            disabled = true;
        }
        let customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            }
        };
        let customStyles2 = {
            overlay: {
                "zIndex": "2"
            },
            content: {
                top: '10%',
                left: '20%',
                right: '20%',
                bottom: '10%'
                // marginRight: '-50%',
                // transform: 'translate(-50%, -50%)',
            }
        };
        return (
            <React.Fragment>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Event"
                    style={customStyles}
                >
                    <div className="custom-control custom-radio">
                        <input type="radio" id="1" name="recurrencyEvent" value="1" checked={this.state.checkedRecurrency === "1"} onChange={this.recurrencyEvent} />
                        <label htmlFor="1"> &nbsp; This event </label><br />

                        <input type="radio" id="2" name="recurrencyEvent" value="2" checked={this.state.checkedRecurrency === "2"} onChange={this.recurrencyEvent} />
                        <label htmlFor="2"> &nbsp; This and following events </label><br />

                        <input type="radio" id="3" name="recurrencyEvent" value="3" checked={this.state.checkedRecurrency === "3"} onChange={this.recurrencyEvent} />
                        <label htmlFor="3"> &nbsp; All events </label><br />

                        <button onClick={this.handleCloseModal} className="btn btn-primary mr-2">Ok</button>
                        <button onClick={this.handleCancelModal} className="btn btn-primary ml-2">Cancel</button>
                    </div>
                </Modal>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal2}
                    contentLabel="Event"
                    style={customStyles2}
                >
                    <Event
                        id={event.id}

                        frequencyMonthThirdLevelMonthWeekDaysRanksName="monthWeekDaysRanks"

                        frequencyMonthThirdLevelWeekDayCheckboxName="weekDayFrequencyMonthThirdLevel"

                        freqYr2ndLevSlctBxMthName="slctBxMthYr"

                        freqYr2ndLevChkBxActivateMthDayName="chkBxActivateMthDayName"

                        freqYr2ndLevSlctBxMthDayName="slctBxMthDayName"

                        freqYr2ndLevChkBxActivateWkDayInMthYrRuleName="chkBxActivateWkDayInMthYrRuleName"

                        freqYr2ndLevSlctBxWkDayRkInMthYrRuleName="slctBxWkDayRkInMthYrRuleName"

                        freqYr2ndLevWkDaysInMthYrRuleName="weekDayInMthYrRule"

                        freqYr2ndLevSlctBxWkYrName="slctBxWkYr"

                        freqYr2ndLevWkDaysInWkYrRuleName="weekDayInWkYrRule"

                        freqYr2ndLevSlctBxDayYrName="slctBxDayYr"

                        handleCloseModal2={this.handleCloseModal2}

                        handleCancelModal2={this.handleCancelModal2}
                    />

                </Modal>
                <Draggable
                    disabled={disabled}
                    position={controlledPosition}
                    axis="y"
                    bounds={{ top: this.state.boundTop, bottom: this.state.boundBottom }}
                    {...dragHandlers}
                    onDrag={this.onControlledDrag}
                >
                    <div className="blockEvent pl-2 box" style={divStyle} onDoubleClick={this.handleOpenModal2}>
                        {event.title} <br />
                        {displayTime}
                    </div>
                </Draggable>
            </React.Fragment >
        )
    }
}