function WeekDays({ days }) {
    const allDays = days.map((day, index) => {
        return (
            <td key={index} className="border">
                {day}
            </td>
        )
    });

    return (
        <tr className="text-center border" style={{ backgroundColor: '#504c4c', color: 'white' }}>
            {allDays}
            <td></td>
        </tr>
    );
}

export default WeekDays 