import React, { useEffect, useState, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5Percent from "@amcharts/amcharts5/percent.js";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ChartPieDashboard({ tachesProject }) {
    const [chartHeight, setChartHeight] = useState(0);
    const chartRef = useRef(null);
    useEffect(() => {
        if (tachesProject.length > 0) {
            //instance d'une nouvelle graphique amChart5
            const chart = am5.Root.new('chart');

            //configure theme graphique
            chart.setThemes([
                am5themes_Animated.new(chart)
            ]);

            var myChart = chart.container.children.push(
                am5Percent.PieChart.new(chart, {
                    layout: chart.verticalLayout
                })
            );

            let status = ['Non assigné', 'En cours', 'Terminé', 'En retard'];
            // Define data
            var data = status.map((stat) => {
                return (
                    {
                        status: stat,
                        nbstatus: tachesProject.filter(tache => tache.status === stat).length
                    }
                )
            })
            console.log("data", data)

            // Create series
            var series = myChart.series.push(
                am5Percent.PieSeries.new(chart, {
                    name: "Series",
                    valueField: "nbstatus",
                    categoryField: "status"
                })
            );

            // Fonction pour récupérer la hauteur du graphique après son rendu
            const updateChartHeight = () => {
                if (chartRef.current) {
                    const height = chartRef.current.chartInstance.height;
                    setChartHeight(height);
                }
            };
            updateChartHeight();
            const currentHeight = chartHeight;
            // Récupération automatique de la hauteur du graphique
            var cellSize = 80;
            series.events.on("datavalidated", function (ev) {
                var series = ev.target;
                var chart = series.chart;
                var chartHeightCurrent = series.data.length * cellSize + currentHeight + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);
                chart.root.dom.style.height = chartHeightCurrent + "px";
            });
            // Définition des données pour la série
            series.data.setAll(data);

            // Disabling labels and ticks
            series.labels.template.set("visible", false);
            series.ticks.template.set("visible", false);

            series.slices.template.states.create("active", {
                shiftRadius: 0,
                stroke: am5.color(0x995522),
                strokeWidth: 5
            });

            // Add legend
            var legend = myChart.children.push(
                am5.Legend.new(chart, {
                    centerX: am5.percent(50),
                    x: am5.percent(55),
                    y: am5.percent(100),
                    centerY: am5.percent(100),
                    layout: chart.gridLayout,
                    fontSize: '1px'
                })
            );

            legend.data.setAll(series.dataItems);
            legend.labels.template.setAll({
                fontSize: 15
            });
            legend.valueLabels.template.setAll({
                fontSize: 15,
            });
            return () => {
                myChart.dispose()
            }
        }
    }, [chartHeight, tachesProject]);

    if (tachesProject.length > 0) {
        return (
            <div style={{ position: 'relative', height: '90vh', width: '44vw' }}>
                <div id="chart"></div>
            </div>
        );
    } else {
        return <></>
    }
}

export default ChartPieDashboard;