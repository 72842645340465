import '../styles/Design1.css'

function Design1() {
    return (
        <div className="design1">
            <div className="text">LANZOcode</div>
            <div className='slogan'>imagine... realize... enjoy...</div>
        </div>
    )
}

export default Design1