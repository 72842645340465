import { UserContext, StateContext } from '../components/AppContext'
import { useState, useEffect, useCallback, useContext } from 'react'
import Chat from '../components/Chat'
import ChatDiscussion from '../components/ChatDiscussion'
import Calendar from '../components/Calendar'
import ProjectTache from '../components/ProjectTache'
import Tache from '../components/Tache'
import Dashboard from '../components/Dashboard'
import User from '../components/User'
import TaskManager from '../components/TaskManager'
import Contract from '../components/Contract'
import Project from '../components/Project'
import Invoice from '../components/Invoice'
import axios from 'axios'
import '../styles/AdminPage.css'

function AdminPage() {
    const stateCont = useContext(StateContext)
    const [userPseudo, setUserPseudo] = useState('')

    useEffect(() => {
        const checkJWT = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/jwtid`,
                withCredentials: true
            }).then((res) => {
                if (stateCont.userLogged === null || (stateCont.userLogged.id !== res.data.userId && stateCont.userLogged.role !== res.data.role && stateCont.userLogged.followings !== res.data.followings))
                    stateCont.setLoggedUser({ id: res.data.userId, role: res.data.role, followings: res.data.followings })
            }).catch((err) => window.location.href = '/login');
        }
        checkJWT()
        const verifyToken = setInterval(() => {
            checkJWT()
        }, 5000)
        return () => {
            clearInterval(verifyToken)
        }
    }, [stateCont.userLogged])

    useEffect(() => {
        const getConversations = async () => {
            try {
                if (stateCont.userLogged && stateCont.userLogged.id) {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${stateCont.userLogged.id}`);
                    setUserPseudo(res.data.pseudo);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getConversations();
    }, [stateCont.userLogged]);


    const logoutAdmin = useCallback(async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/api/user/logout`,
            withCredentials: true
        }).then((res) => {
            window.location.href = '/login'
        })
    }, [])

    window.addEventListener("resize", function () {
        if (document.getElementById(stateCont.tab)) {
            document.getElementById(stateCont.tab).parentElement.style.left = document.getElementsByClassName("tab-links")[0].offsetWidth + "px";
            document.getElementById(stateCont.tab).parentElement.style.width = "80%";
        }
    })

    return (
        <UserContext.Provider value={stateCont.userLogged}>
            <div className="tab-font">
                <div className="tab-links">
                    <div className='d-flex justify-content-center align-items-center mt-3 flex-wrap px-2'>
                        <img src="./img/rougesmiley.png" alt="logoLanzo" className='logo' />
                        <span className='text-white text-center ms-3 tab-title'>LANZOcode</span>
                    </div>
                    <div className='text-white text-center mt-3 mb-5 px-2'>Bienvenue {userPseudo}</div>

                    <>
                        <button className={'tab-link User' + ((stateCont.tab === "User") ? 'active' : '')} onClick={() => { stateCont.changeTab('User'); }}><i className="far fa-user-circle"></i> Utilisateur</button>
                        <button className={'tab-link Project ' + ((stateCont.tab === "Project") ? 'active' : '')} onClick={() => { stateCont.changeTab('Project'); }}><i className="far fa-keynote"></i> Demande en ligne</button>
                    </>

                    <button className={'tab-link Chat ' + ((stateCont.tab === "Chat") ? 'active' : '')} onClick={() => { stateCont.changeTab('Chat'); }}><i className="fab fa-rocketchat"></i> Discussion</button>
                    <button className={'tab-link ChatDiscussion ' + ((stateCont.tab === "ChatDiscussion") ? 'active' : '')} onClick={() => { stateCont.changeTab('ChatDiscussion'); }}><i className="fab fa-rocketchat"></i> Groupe de discussion</button>
                    <button className={'tab-link Calendar ' + ((stateCont.tab === "Calendar") ? 'active' : '')} onClick={() => { stateCont.changeTab('Calendar'); }}><i className="fal fa-calendar-alt"></i> Calendrier</button>
                    <button className={'tab-link ProjectTache ' + ((stateCont.tab === "ProjectTache") ? 'active' : '')} onClick={() => { stateCont.changeTab('ProjectTache'); }}><i className="fal fa-calendar-alt"></i> Projet</button>
                    {<button className={'tab-link Tache ' + ((stateCont.tab === "Tache") ? 'active' : '')} onClick={() => { stateCont.changeTab('Tache'); }}><i className="far fa-ticket-alt"></i> Gestion tâches</button>}
                    <button className={'tab-link Dashboard ' + ((stateCont.tab === "Dashboard") ? 'active' : '')} onClick={() => { stateCont.changeTab('Dashboard'); }}><i className="fas fa-chart-line"></i> Tableau de bord</button>
                    <button className={'tab-link Contract ' + ((stateCont.tab === "Contract") ? 'active' : '')} onClick={() => { stateCont.changeTab('Contract'); }}><i className="fas fa-file-contract"></i> Contrat</button>
                    <button className={'tab-link Invoice ' + ((stateCont.tab === "Invoice") ? 'active' : '')} onClick={() => { stateCont.changeTab('Invoice'); }}><i className="fas fa-file-invoice-dollar"></i> Facture</button>
                    <button className={'tab-link ' + ((stateCont.tab === "User") ? 'active' : '')} onClick={() => logoutAdmin()}><i className="far fa-sign-out"></i> Déconnexion</button>
                </div>
                <div className="tab-content">

                    <>
                        {(stateCont.tab === "User") && <User />}
                        {(stateCont.tab === "To_Do_List") && <TaskManager />}
                        {(stateCont.tab === "Project") && <Project />}
                    </>

                    {(stateCont.tab === "Chat") && <Chat />}
                    {(stateCont.tab === "ChatDiscussion") && <ChatDiscussion />}
                    {(stateCont.tab === "Calendar") && <Calendar />}
                    {(stateCont.tab === "ProjectTache") && <ProjectTache />}
                    {(stateCont.tab === "Tache") && <Tache />}
                    {(stateCont.tab === "Dashboard") && <Dashboard />}
                    {(stateCont.tab === "Contract") && <Contract />}
                    {(stateCont.tab === "Invoice") && <Invoice />}
                </div>
            </div>
        </UserContext.Provider >
    );
}

export default AdminPage;
