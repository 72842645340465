export const getStartWeek = (day, month, year) => {
    let date = new Date(month + ' ' + day + ', ' + year);
    let getday = date.getDay();
    let startDate = new Date(date);
    if (getday === 0) {
        startDate.setDate(startDate.getDate() - 6);
    } else if (getday === 1) {
        startDate.setDate(startDate.getDate());
    } else {
        startDate.setDate(startDate.getDate() - (getday - 1));
    }
    return startDate;
}

export const formatDate = (date) => {
    let stringDate = date.toDateString();
    let convertedStartDate = new Date(stringDate);
    let month = convertedStartDate.getMonth() + 1
    let day = convertedStartDate.getDate();
    let year = convertedStartDate.getFullYear();
    month = ("0" + month.toString()).slice(-2);
    day = ("0" + day.toString()).slice(-2);
    let shortStartDate = month + "/" + day + "/" + year;
    return shortStartDate;
}
