import WeekDays from "./WeekDays";
import { WEEKDAYS } from '../Constants';
import BlockDaysInMonth from "./BlockDaysInMonth";
import { useContext } from "react";
import { StateContext } from "./AppContext";
import '../styles/ViewMonth.css';

function ViewMonth() {
    const stateCont = useContext(StateContext)
    return (
        <div className="d-flex w-100 justify-content-center">
            <table className="mb-3 border-month" id="ViewMonth">
                <tbody>
                    <WeekDays days={WEEKDAYS} />
                    <BlockDaysInMonth month={stateCont.month} year={stateCont.year} />
                </tbody>
            </table>
        </div>
    );
}

export default ViewMonth