import Navbar from '../components/Navbar'
import Accueil from '../components/Accueil'
import Offres from '../components/Offres'
import Demo from '../components/Demo'
import Contact from '../components/Contact'
import FbChatBot from '../components/FbChatBot'
import Footer from '../components/Footer'

const MainPage = () => {
    return (
        <>
            <Navbar />
            <Accueil />
            <Offres />
            <Demo />
            <Contact />
            <FbChatBot />
            <Footer />
        </>
    )
}

export default MainPage
