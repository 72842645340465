import ControlViewCalendar from "./ControlViewCalendar";
import '../styles/Calendar.css'
import ViewDay from "./ViewDay";
import ViewWeek from "./ViewWeek";
import ViewMonth from "./ViewMonth";
import ViewYear from "./ViewYear";
import { useContext } from "react";
import { StateContext } from "./AppContext";

function Calendar() {
    const stateCont = useContext(StateContext)
    return (
        <div id="Calendar" className="tabcontent">
            <ControlViewCalendar selectedView={stateCont.selectedView}/>
            {stateCont.selectedView === 'Day' && <ViewDay />}
            {stateCont.selectedView === 'Week' && <ViewWeek />}
            {stateCont.selectedView === 'Month' && <ViewMonth />}
            {stateCont.selectedView === 'Year' &&  <ViewYear />}
        </div>
    );
}

export default Calendar