import { useEffect, useState, useRef, useContext } from 'react'
import { StateContext } from '../components/AppContext'
import { io } from "socket.io-client"
import axios from 'axios'
import Message from '../components/Message'
import ModalUser from '../components/ModalUser'
import '../styles/ChatDiscussion.css'

function ChatDiscussion() {
    const stateCont = useContext(StateContext)
    const [groupeActive, setGroupeActive] = useState(0)
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([]);
    const [groupes, setGroupe] = useState([])
    const [openedGroup, setOpenedGroup] = useState("")
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const socket = useRef();
    const scrollRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => {
        // Attendre 3 secondes avant d'afficher le modal
        setTimeout(() => {
            setShowModal(true);
        }, 200);
    };

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`);
        socket.current.on("getGroupeMessage", (data) => {
            setArrivalMessage({
                sender: data.senderId,
                text: data.text,
                createdAt: Date.now(),
            });
        });
        return () => {
            socket.current.off("getGroupeMessage", () => {

            })
        }
    })
    useEffect(() => {
        const getGroupes = async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/groupe`);
            if (res.data.groupes != null && res.data.groupes.length > 0) {
                setGroupe(res.data.groupes)
                setOpenedGroup(res.data.groupes[groupeActive].tache.tache)
            }
        };
        getGroupes();
    }, []);

    useEffect(() => {
        if (groupes.length > 0) {
            setOpenedGroup(groupes[groupeActive].tache?.tache)
            setMessage("")
        }
    }, [groupeActive])

    useEffect(() => {
        if (groupes.length > 0) {
            socket.current.emit("addGroup", groupes[groupeActive]._id);
            const getConversations = async () => {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/groupemessage/${groupes[groupeActive]._id}`);
                setMessages(res.data);
            };
            getConversations();
        }
    }, [openedGroup])

    useEffect(() => {
        if (groupes.length > 0) {
            arrivalMessage &&
                groupes[groupeActive].members.includes(arrivalMessage.sender) &&
                setMessages((prev) => [...prev, arrivalMessage]);
        }
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const setActiveChat = (idGroupe) => {
        setGroupeActive(idGroupe)
        // friends.list.querySelector('.active').classList.remove('active')
        // f.classList.add('active')
        // chat.current = chat.container.querySelector('.active-chat')
        // chat.person = f.getAttribute('data-chat')
        // chat.current.classList.remove('active-chat')
        // chat.container.querySelector('[data-chat="' + chat.person + '"]').classList.add('active-chat')
        // friends.name = f.querySelector('.name').innerText
        // chat.name.innerHTML = friends.name
    }

    const sendMessage = async () => {
        if (groupes.length > 0) {
            socket.current.emit("sendGroupeMessage", {
                senderId: stateCont.userLogged.id,
                groupeId: groupes[groupeActive]._id,
                text: message,
            });
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/groupemessage/${groupes[groupeActive]._id}`, { senderId: stateCont.userLogged.id, newMessage: message });
                setMessages([...messages, res.data]);
                setMessage("");
            } catch (err) {
                console.log(err);
            }
        }
    }

    const addGroup = async (newMember) => {
        if (newMember != '')
            await axios.post(`${process.env.REACT_APP_API_URL}/api/groupe/${groupes[groupeActive]._id}`, { newMember: newMember });
        setShowModal(false);
    }

    return (
        <div id="ChatDiscussion" className="tabcontent">
            <div className="wrapper">
                <div className="container">
                    <div className="left">
                        <div className="top">
                            <input type="text" placeholder="Search" />
                            <a href="#" className="search"></a>
                        </div>
                        <ul className="people">
                            {groupes.map((groupe, idGroupe) => {
                                return (
                                    <li key={idGroupe} className={'person' + ((groupeActive === idGroupe) ? ' active' : '')} data-chat={'person' + idGroupe} onMouseDown={(e) => setActiveChat(idGroupe)}>
                                        <img src={groupe.src} alt="" />
                                        <span className="name">{groupe?.tache?.tache}</span>
                                        <span className="time">{groupe?.time}</span>
                                        <span className="preview">{groupe?.preview}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="right">
                        <div className="top" style={{ display: 'flex' }}>
                            <span className="name">{openedGroup}</span>
                            <i className="fas fa-user-plus" onClick={() => handleShowModal()}></i>
                            {showModal && (
                                <ModalUser handleCloseModal={handleCloseModal} idGroupe={groupes[groupeActive]._id} addGroup={addGroup} />
                            )}
                        </div>
                        <div style={{ overflow: 'scroll', height: '80%' }}>
                            {messages.map((m, idxm) => (
                                <div key={idxm} ref={scrollRef}>
                                    <Message message={m} own={m.sender === stateCont.userLogged.id} senderId={m.sender} />
                                </div>
                            ))}
                            <div className="write d-flex justify-content-end mr-3">
                                <input className="w-75" type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                                <div className="d-flex justify-content-center w-25">
                                    <a href="#" className="write-link smiley"></a>
                                    <a href="#" className="write-link send" onClick={() => sendMessage()}></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatDiscussion