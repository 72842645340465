import "../styles/LanzoDemo.css"

const LanzoDemo = () => {
    const ListeDemo = [
        {
            url: "https://site1.lanzocode.com",
            Title: "Site 1",
            image: "./img/site2.jpg",
            text: "Blog",
            tags: ["wordpress", "blog"]
        },
        {
            url: "https://site2.lanzocode.com",
            Title: "Site 2",
            image: "./img/site2.png",
            text: "Site vitrine",
            tags: ["react", "vitrine"]
        },
        {
            url: "/mydemo",
            Title: "Demo 1",
            image: "./img/environmental.jpg",
            text: "Fonctionnalités",
            tags: ["react", "vitrine"]
        },
        {
            url: "/mydemo2",
            Title: "Demo 2",
            image: "./img/site4.png",
            text: "Design",
            tags: ["react", "vitrine"]
        },
    ]

    return (
        <div id="LanzoDemo">
            <div className="container">
                <div className="lanzodemo-button">
                    <a href="/" className="btn-click">Retour <img src="./img/Smileynoir.png" alt="smileynoir" className="btn-smiley" /></a>
                </div>
                <div className="allDemo">
                    {ListeDemo.map((data, key) => {
                        return (
                            <div className='box' key={key}>
                                <a href={data.url} target="_blank" rel="noreferrer">
                                    <div className='imgBx'>
                                        <img src={data.image} alt="card" />
                                    </div>
                                    <div className='content'>
                                        <h2> {data.Title}
                                            <br /> <span>{data.text}</span>
                                        </h2>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default LanzoDemo;