import DayCell from './DayCell';
import EventContentCell from './EventContentCell';
import { useContext } from "react";
import { StateContext } from "./AppContext";
import { MONTHNAMES } from '../Constants';

function BlockDaysInMonth({ month, year }) {
    const stateCont = useContext(StateContext)
    const newDate = new Date(month + " 1, " + year);
    let firstOfWeek = new Date(newDate);
    let firstDay = newDate.getDay(newDate); // Sunday - Saturday : 0 - 6 date begining by Sunday
    firstDay = (firstDay === 0) ? 6 : firstDay - 1;

    let monthNumber = newDate.getMonth() + 1;
    let countDay = 0;
    if (monthNumber <= 7) {
        if (monthNumber % 2 !== 0) {
            countDay = 31;
        } else {
            countDay = 30;
        }
    } else {
        if (monthNumber % 2 === 1) {
            countDay = 30;
        } else {
            countDay = 31;
        }
    }
    if (monthNumber === 2) {
        if (year % 4 === 0) {
            if (year % 100 === 0) {
                if (year % 400 === 0) {
                    countDay = 29;
                } else {
                    countDay = 28;
                }
            } else {
                countDay = 29;
            }
        }
        else {
            countDay = 28;
        }
    }
    const nbDay = countDay;
    let firstdayRow = [1];
    let blanksBefore = [];
    let dayCellKey = 1;
    firstOfWeek.setDate(firstOfWeek.getDate() - firstDay);
    for (let i = 0; i < firstDay; i++) {
        if (stateCont.selectedView === 'Month') {
            let setDay = firstOfWeek.getDate();
            let setMonth = MONTHNAMES[firstOfWeek.getMonth()];
            let setYear = firstOfWeek.getFullYear();;
            let position = dayCellKey - 1;
            blanksBefore.push(
                <DayCell key={dayCellKey} id={stateCont.formatDateCalendar(firstOfWeek)} cellClass="cellMonth p-2">
                    <span className="text-muted" onClick={() => stateCont.changeView('Day', setDay, setMonth, setYear)}>{setMonth.slice(0, 3)} {setDay}</span>
                    <EventContentCell position={position} />
                </DayCell>
            );
            firstOfWeek.setDate(firstOfWeek.getDate() + 1);
        } else {
            blanksBefore.push(<DayCell key={dayCellKey} id={dayCellKey} />);
        }
        dayCellKey++;
    }

    let daysInMonth = [];
    let nbCell = blanksBefore.length + nbDay;
    if (nbCell % 7 !== 0) {
        nbCell = nbCell + 7 - (nbCell % 7);
    }

    for (let day = 1; day <= nbDay; day++) {
        let todayDate = new Date();
        if (stateCont.selectedView === 'Year') {
            let cellClass = (todayDate.getDate() === day && MONTHNAMES[todayDate.getMonth()] === month && todayDate.getFullYear() === year) ? "bg-primary" : "";
            daysInMonth.push(
                <DayCell key={dayCellKey} id={dayCellKey} cellClass={cellClass}>
                    <span className="text-black" onClick={() => stateCont.changeView('Day', day, month, year)}>{day}</span>
                </DayCell >
            );
        } else if (stateCont.selectedView === 'Month') {
            let linkClass = (todayDate.getDate() === day && MONTHNAMES[todayDate.getMonth()] === month && todayDate.getFullYear() === year) ? "bg-primary linkCell" : "";
            let position = dayCellKey - 1;
            let currentDate = new Date(month + " " + day + ", " + year);
            daysInMonth.push(
                <DayCell key={dayCellKey} id={stateCont.formatDateCalendar(currentDate)} cellClass="cellMonth p-2">
                    <span className={"text-black " + linkClass} onClick={() => stateCont.changeView('Day', day, month, year)}>{day}</span>
                    <EventContentCell nbCell={nbCell} position={position} />
                </DayCell>
            );
        }
        dayCellKey++;
    }

    let totalSlots = blanksBefore.length ? [...blanksBefore, ...daysInMonth] : [...daysInMonth];

    let blanksAfter = [];
    let nbSlots = totalSlots.length;
    let nextfirstWeek = new Date(newDate);
    nextfirstWeek.setDate(nextfirstWeek.getDate() + nbDay);

    if (nbSlots % 7 !== 0) {
        let remainingSlot = 7 - (nbSlots % 7);
        for (let i = 1; i <= remainingSlot; i++) {
            if (stateCont.selectedView === 'Month') {
                let setDay = nextfirstWeek.getDate();
                let setMonth = MONTHNAMES[nextfirstWeek.getMonth()];
                let setYear = nextfirstWeek.getFullYear();
                let position = dayCellKey - 1;
                blanksAfter.push(
                    <DayCell key={dayCellKey} id={stateCont.formatDateCalendar(nextfirstWeek)} cellClass="cellMonth p-2">
                        <span className="text-muted" onClick={() => stateCont.changeView('Day', setDay, setMonth, setYear)}>{setMonth.slice(0, 3)} {setDay}</span>
                        <EventContentCell position={position} />
                    </DayCell>
                );
                nextfirstWeek.setDate(nextfirstWeek.getDate() + 1);
            } else if (stateCont.selectedView === 'Year') {
                blanksAfter.push(<DayCell key={dayCellKey} id={dayCellKey} />);
            }
            dayCellKey++;
        }
    }

    totalSlots = blanksAfter.length ? [...totalSlots, ...blanksAfter] : [...totalSlots];
    let rows = [];
    let cells = [];

    let nbSlotToNextRow = 7 - blanksBefore.length;
    let nextRow = 1 + nbSlotToNextRow;

    do {
        firstdayRow.push(nextRow);
        nextRow = nextRow + 7;
    } while (nbDay > nextRow);

    totalSlots.forEach((slot, i) => {
        if (i % 7 !== 0) {
            cells.push(slot);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(slot);
        }
        if (i === totalSlots.length - 1) {
            rows.push(cells);
        }
    });

    const allDaysInMonth = rows.map((day, i) => {
        if (i !== 0) {
            const padLeft = (stateCont.selectedView === "Year") ? "pl-2" : "cellToolTipMonth pl-0";
            return (
                <tr key={i} className="text-right border-0" style={{ backgroundColor: 'white' }}>
                    {day}
                    <td className={"border text-center " + padLeft} data-toggle="tooltip" data-placement="right" title="View Week">
                        <span className="text-black" onClick={() => stateCont.changeView('Week', firstdayRow[i - 1], month, year)}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-calendar-week" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                            </svg>
                        </span>
                    </td>
                </tr >
            )
        } else {
            return (
                <tr key={i} className="text-right">
                    {day}
                </tr>
            )
        }
    });
    return (
        <>
            {allDaysInMonth}
        </>
    );

}

export default BlockDaysInMonth