import React from 'react';

export default class TextArea extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.handleChange(event.target.value);
    }

    render() {
        const name = this.props.name;
        const id = this.props.id;
        const value = this.props.value;
        const placeHolder = this.props.placeHolder;

        return (
            <textarea name={name} id={id} value={value} onChange={this.handleChange} className="form-control" placeholder={placeHolder} />
        );
    }
}