import PaginationProject from './PaginationProject';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import '../styles/ProjectTache.css'
import { StateContext } from './AppContext';

function ProjectTache() {
    const stateCont = useContext(StateContext)
    const [page, setPage] = useState(1);
    const [idxProject, setIdxProject] = useState(0);
    const [projects, setProjects] = useState([]);
    const [lenProject, setLenProject] = useState(0);
    const [project, setProject] = useState("");
    const [formObjProject, setFormProject] = useState({ nameCompany: '', mail: '', tel: '', projet: '', description: '', client: '', type: 'Intelligence Artificielle', dateLivraison: '' });
    const [formObjTache, setFormTache] = useState({ tache: '', idProjet: '' });
    const [taches, setTaches] = useState([]);
    const [displayedTache, setDisplayedTache] = useState([])
    const [tachePage, setTachePage] = useState(1)
    const [pagination, setPagination] = useState([])

    useEffect(() => {
        const getProjects = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
                withCredentials: true
            }).then((res) => {
                if (res.data.projectTaches.length > 0) {
                    setProjects(res.data.projectTaches)
                    setProject(res.data.projectTaches[idxProject].projet)
                    setLenProject(res.data.projectTaches.length)
                    setFormTache({ ...formObjTache, idProjet: res.data.projectTaches[idxProject]["_id"] })
                    getTachesByIdProject(res.data.projectTaches[idxProject]["_id"])
                }
            })
        }
        getProjects();
    }, [idxProject])

    useEffect(() => {
        if (projects.length > 0) {
            setProject(projects[idxProject].projet)
            setFormTache({ ...formObjTache, idProjet: projects[idxProject]["_id"] })
            getTachesByIdProject(projects[idxProject]["_id"])
        }
    }, [idxProject])

    useEffect(() => {
        let countPage = Math.ceil(taches.length / 5)
        let startTache = (tachePage - 1) * 5
        let endTache = startTache + 5
        let allPages = []
        for (let i = 1; i <= countPage; i++)
            allPages.push(i)
        let newPagination =
            <ul className="pagination justify-content-center text-dark" >
                {
                    (tachePage !== 1) ?
                        <li className='page-item'><button className="page-link" onClick={() => (setTachePage(tachePage - 1))}>&laquo;</button></li>
                        : ""
                }
                {allPages.map((page, idxP) => <li className='page-item' key={idxP}><button className="page-link" onClick={() => { setTaches(taches); setTachePage(page); }}>{page}</button></li>)}
                {
                    (tachePage < countPage) ?
                        <li className='page-item'><button className="page-link" onClick={() => (setTachePage(tachePage + 1))}>&raquo;</button></li>
                        : ""
                }
            </ul>
        setDisplayedTache(taches.slice(startTache, endTache))
        setPagination(newPagination)
    }, [tachePage, taches])

    useEffect(() => {
        if (page == 3)
            setFormProject(projects[idxProject])
        else if (page == 2)
            setFormProject({ nameCompany: '', mail: '', tel: '', projet: '', description: '', client: '', type: 'Intelligence Artificielle', dateLivraison: '' })
    }, [page])

    const nextProject = () => {
        setIdxProject(idxProject + 1)
    }
    const previousProject = () => {
        setIdxProject(idxProject - 1)
    }

    const submitProject = async () => {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
            data: formObjProject,
            withCredentials: true
        }).then(async (res) => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
                withCredentials: true
            }).then((res) => {
                if (res.data.projectTaches.length > 0) {
                    let newLenProjectTache = res.data.projectTaches.length - 1
                    setProjects(res.data.projectTaches)
                    setProject(res.data.projectTaches[newLenProjectTache].projet)
                    setLenProject(res.data.projectTaches.length)
                    setIdxProject(newLenProjectTache)
                    setFormTache({ ...formObjTache, idProjet: res.data.projectTaches[newLenProjectTache]["_id"] })
                    getTachesByIdProject(res.data.projectTaches[newLenProjectTache]["_id"])
                    setPage(1)
                }
            })
        })
    }

    const updateProject = async () => {
        axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/projectTache/${projects[idxProject]["_id"]}`,
            data: formObjProject,
            withCredentials: true
        }).then(async (res) => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
                withCredentials: true
            }).then((res) => {
                if (res.data.projectTaches.length > 0) {
                    setProjects(res.data.projectTaches)
                    setProject(res.data.projectTaches[idxProject].projet)
                    setFormTache({ ...formObjTache, idProjet: res.data.projectTaches[idxProject]["_id"] })
                    getTachesByIdProject(res.data.projectTaches[idxProject]["_id"])
                    setPage(1)
                }
            })
        })
    }

    const submitTache = async () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/tache`,
            data: formObjTache,
            withCredentials: true
        }).then((res) => res.data).then((data) => getTachesByIdProject(projects[idxProject]["_id"]))
            .catch((err) => { })
    }

    const getTachesByIdProject = async (projetId) => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/api/tache/project/${projetId}`,
            withCredentials: true
        }).then((res) => {
            setTaches(res.data.taches)
        })
    }

    const removeTache = async (tacheId) => {
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}/api/tache/${tacheId}`,
            withCredentials: true
        }).then((res) => {
            getTachesByIdProject(projects[idxProject]["_id"])
        })
    }

    const deleteProject = async () => {
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}/api/projectTache/${projects[idxProject]["_id"]}`,
            withCredentials: true
        }).then(async (res) => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/projectTache`,
                withCredentials: true
            }).then((res) => {
                setIdxProject(0)
                setPage(1)
                setProjects(res.data.projectTaches)
                if (res.data.projectTaches.length === 0) {
                    setProject("")
                    setFormTache({ tache: '', idProjet: '' })
                    setTaches([])
                    setLenProject(0)
                } else {
                    setProject(res.data.projectTaches[idxProject].projet)
                    setFormTache({ ...formObjTache, idProjet: res.data.projectTaches[idxProject]["_id"] })
                    getTachesByIdProject(res.data.projectTaches[idxProject]["_id"])
                }
            })
        })
    }

    return (
        <div id="ProjectTache" className="tabcontent">
            {(page === 1) && (
                <>
                    <div className="project">
                        <PaginationProject nameProject={project} lenProject={lenProject} idxProject={idxProject} next={nextProject} previous={previousProject} setPage={setPage} />
                    </div>
                    <div className="container">
                        <div className="entryArea">
                            <input type="text" name="tache" id="tache" required={true} value={formObjTache.tache} onChange={(e) => setFormTache({ ...formObjTache, tache: e.target.value })} />
                            <div className="labelline">Nom Tâche</div>
                            <button onClick={() => submitTache()}>Ajouter</button>
                        </div>
                        <div className="box">
                            <ul>
                                {
                                    displayedTache && displayedTache.map((tache, idTache) => {
                                        return <li key={idTache} value={tache._id} onClick={(e) => { if (!e.target.classList.contains("removeTach")) stateCont.openTask(tache._id) }}>{tache.tache}<span className="removeTach" onClick={() => removeTache(tache._id)}><i className="fal fa-times removeTach"></i></span></li>
                                    })
                                }
                            </ul>
                        </div>
                        {pagination}
                    </div>
                </>
            )}

            {(page === 2) && (
                <div className="body">
                    <div className="formDiv">
                        <div className="title">Décrivez votre projet</div>
                        <form>
                            <div className="projectDetails">
                                <div className="input-box" style={{ width: "100%" }}>
                                    <span className="details" htmlFor="nameCompany">Nom Société</span>
                                    <input type="text" name="nameCompany" id="nameCompany" placeholder="Nom" value={formObjProject.nameCompany} required={true} onChange={(e) => setFormProject({ ...formObjProject, nameCompany: e.target.value })} style={{ width: "65%" }} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="client">Nom Client</span>
                                    <input type="text" name="client" id="client" placeholder="Nom" value={formObjProject.client} required={true} onChange={(e) => setFormProject({ ...formObjProject, client: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="mail">Email</span>
                                    <input type="text" name="mail" id="mail" placeholder="Email" value={formObjProject.mail} required={true} onChange={(e) => setFormProject({ ...formObjProject, mail: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="tel">Téléphone</span>
                                    <input type="text" name="tel" id="tel" placeholder="Téléphone" value={formObjProject.tel} required={true} onChange={(e) => setFormProject({ ...formObjProject, tel: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="projet">Projet</span>
                                    <input type="text" name="projet" id="projet" placeholder="Nom" value={formObjProject.projet} required={true} onChange={(e) => setFormProject({ ...formObjProject, projet: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="type">Type projet</span>
                                    <select name="type" id="type" value={formObjProject.type} required={true} onChange={(e) => setFormProject({ ...formObjProject, type: e.target.value })} className="form-select" style={{ height: "59%" }}>
                                        <option value="Intelligence Artificielle">Intelligence Artificielle</option>
                                        <option value="Maintenance">Maintenance</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="Réseau">Réseau</option>
                                        <option value="Web">Web</option>
                                    </select>
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="dateLivraison">Date livraison </span>
                                    <input type="date" name="dateLivraison" id="dateLivraison" value={stateCont.formatDateInput(new Date(formObjProject.dateLivraison))} required={true} onChange={(e) => setFormProject({ ...formObjProject, dateLivraison: e.target.value })} style={{ width: "100%" }} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="description">Description du projet</span>
                                    <textarea name="nameCompany" id="description" placeholder="Dites-nous un peu plus" value={formObjProject.description} required={true} onChange={(e) => setFormProject({ ...formObjProject, description: e.target.value })} style={{ border: "1px solid #ccc", width: "110vh", height: "17vh" }} />
                                </div>
                            </div>
                            <div className="submit">
                                <input type="button" value="X  Annuler" className="btn-submit solid align-self-center" onClick={() => setPage(1)} />
                                <input type="button" value="Enregistrer" className="btn-submit solid align-self-center" onClick={() => submitProject()} />
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {(page === 3) && (
                <div className="body">
                    <div className="formDiv">
                        <div className="title">Modifier projet</div>
                        <form>
                            <div className="projectDetails">
                                <div className="input-box" style={{ width: "100%" }}>
                                    <span className="details" htmlFor="nameCompany">Nom Société</span>
                                    <input type="text" name="nameCompany" id="nameCompany" placeholder="Nom" value={formObjProject.nameCompany} required={true} onChange={(e) => setFormProject({ ...formObjProject, nameCompany: e.target.value })} style={{ width: "65%" }} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="client">Nom Client</span>
                                    <input type="text" name="client" id="client" placeholder="Nom" value={formObjProject.client} required={true} onChange={(e) => setFormProject({ ...formObjProject, client: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="mail">Email</span>
                                    <input type="text" name="mail" id="mail" placeholder="Email" value={formObjProject.mail} required={true} onChange={(e) => setFormProject({ ...formObjProject, mail: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="tel">Téléphone</span>
                                    <input type="text" name="tel" id="tel" placeholder="Téléphone" value={formObjProject.tel} required={true} onChange={(e) => setFormProject({ ...formObjProject, tel: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="projet">Projet</span>
                                    <input type="text" name="projet" id="projet" placeholder="Nom" value={formObjProject.projet} required={true} onChange={(e) => setFormProject({ ...formObjProject, projet: e.target.value })} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="type">Type projet</span>
                                    <select name="type" id="type" value={formObjProject.type} required={true} onChange={(e) => setFormProject({ ...formObjProject, type: e.target.value })} className="form-select" style={{ height: "59%" }}>
                                        <option value="Intelligence Artificielle">Intelligence Artificielle</option>
                                        <option value="Maintenance">Maintenance</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="Réseau">Réseau</option>
                                        <option value="Web">Web</option>
                                    </select>
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="dateLivraison">Date livraison</span>
                                    <input type="date" name="dateLivraison" id="dateLivraison" value={stateCont.formatDateInput(new Date(formObjProject.dateLivraison))} required={true} onChange={(e) => setFormProject({ ...formObjProject, dateLivraison: e.target.value })} style={{ width: "100%" }} />
                                </div>
                                <div className="input-box">
                                    <span className="details" htmlFor="description">Description du projet</span>
                                    <textarea name="nameCompany" id="description" placeholder="Dites-nous un peu plus" value={formObjProject.description} required={true} onChange={(e) => setFormProject({ ...formObjProject, description: e.target.value })} style={{ border: "1px solid #ccc", width: "110vh", height: "17vh" }} />
                                </div>
                            </div>
                            <div className="submit">
                                <input type="button" value="X  Annuler" className="btn-submit solid align-self-center" onClick={() => setPage(1)} />
                                <input type="button" value="Mettre à jour" className="btn-submit solid align-self-center" onClick={() => updateProject()} />
                                <input type="button" value="Supprimer" className="btn-submit solid align-self-center" onClick={() => deleteProject()} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProjectTache