import { useContext } from 'react';
import { StateContext } from './AppContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CSVLink } from 'react-csv';

function ExportPdfCsv({ dom, projetTache, formatAvancement }) {
    const stateCont = useContext(StateContext);

    const headers = [
        { label: 'Projet', key: 'projet' },
        { label: 'Type Projet', key: 'typeProjet' },
        { label: 'Date Livraison', key: 'dateLivraison' },
        { label: 'Tache', key: 'tache' },
        { label: 'Responsable', key: 'responsable' },
        { label: 'Assistant', key: 'assistant' },
        { label: 'Status', key: 'status' },
        { label: 'Priorite', key: 'priorite' },
        { label: 'Type Tache', key: 'typeTache' },
        { label: 'Avancement', key: 'avancement' },
    ];

    const data = [
        ...projetTache.map((projet) => (
            {
                projet: projet.projet,
                typeProjet: projet.typeProjet,
                dateLivraison: stateCont.formatDate(new Date(projet.dateLivraison)),
                tache: projet.tache,
                responsable: projet.responsable,
                assistant: projet.assistant,
                status: projet.status,
                priorite: projet.priorite,
                typeTache: projet.typeTache,
                avancement: `${projet.avancement}%`,
            })),
        {
            projet: '',
            typeProjet: '',
            dateLivraison: '',
            tache: '',
            responsable: '',
            assistant: '',
            status: '',
            priorite: '',
            typeTache: 'avancement total',
            avancement: `${formatAvancement}%`,
        }
    ];

    const handleDownloadPdf = async () => {
        const element = dom.current;
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('DétailsProjet.pdf');
    };

    return (
        <>
            <CSVLink data={data} headers={headers} filename="DétailsProjet.csv" style={{ marginTop: '-24%' }}>
                <button className="exportCSV">
                    <div className="button-content">
                        <div className="svg-container">
                            <i className="fas fa-download" style={{ color: 'rgb(230, 16, 16)' }}></i>
                        </div>
                        <div className="text-container">
                            <div className="text">Export CSV</div>
                        </div>
                    </div>
                </button >
            </CSVLink >
            <button className="exportPDF" onClick={handleDownloadPdf}>
                <div className="button-content">
                    <div className="svg-container">
                        <i className="fas fa-download" style={{ color: 'rgb(230, 16, 16)' }}></i>
                    </div>
                    <div className="text-container" >
                        <div className="text">Export PDF</div>
                    </div>
                </div>
            </button>
        </>
    )
}

export default ExportPdfCsv