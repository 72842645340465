import React from 'react';
// import Modal from 'react-modal';
import { StateContext } from './AppContext';


export default class DayCell extends React.Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCancelModal = this.handleCancelModal.bind(this);
        this.addEvent = this.addEvent.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal = async () => {
        const { month, year, getStartEndTimeMonth, pullMonthEvents, formatDate } = this.context;
        this.setState({ showModal: false });
        let rangeDateTime = getStartEndTimeMonth(month, year);
        let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
        let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
        await pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
    }

    handleCancelModal() {
        this.setState({ showModal: false });
    }

    addEvent(e) {
        if (e.target.id.toString() === this.props.id.toString()) {
            this.handleOpenModal();
        }
    }

    render() {
        const cellClass = this.props.cellClass ? this.props.cellClass : "";
        // const arrEventsToDisplay = this.props.arrEventsToDisplay ;

        return (
            <React.Fragment>
                {/* <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Event"
                    style={customStyles}
                >
                    <Event

                        startDateTime={new Date(startDate)}

                        frequencyMonthThirdLevelMonthWeekDaysRanksName="monthWeekDaysRanks"

                        frequencyMonthThirdLevelWeekDayCheckboxName="weekDayFrequencyMonthThirdLevel"

                        freqYr2ndLevSlctBxMthName="slctBxMthYr"

                        freqYr2ndLevChkBxActivateMthDayName="chkBxActivateMthDayName"

                        freqYr2ndLevSlctBxMthDayName="slctBxMthDayName"

                        freqYr2ndLevChkBxActivateWkDayInMthYrRuleName="chkBxActivateWkDayInMthYrRuleName"

                        freqYr2ndLevSlctBxWkDayRkInMthYrRuleName="slctBxWkDayRkInMthYrRuleName"

                        freqYr2ndLevWkDaysInMthYrRuleName="weekDayInMthYrRule"

                        freqYr2ndLevSlctBxWkYrName="slctBxWkYr"

                        freqYr2ndLevWkDaysInWkYrRuleName="weekDayInWkYrRule"

                        freqYr2ndLevSlctBxDayYrName="slctBxDayYr"

                        handleCloseModal2={this.handleCloseModal}

                        handleCancelModal2={this.handleCancelModal}
                    />

                </Modal> */}
                <td id={this.props.id} className={"border " + cellClass} onDoubleClick={this.addEvent}>{this.props.children}</td>
            </React.Fragment >
        );
    }
}