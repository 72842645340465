import BlockMonthYear from './BlockMonthYear';
import '../styles/ViewYear.css';

function ViewYear() {
    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <BlockMonthYear month="Janvier" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Fevrier" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Mars" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Avril" />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-3">
                    <BlockMonthYear month="Mai" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Juin" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Juillet" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Aout" />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-3">
                    <BlockMonthYear month="Septembre" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Octobre" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Novembre" />
                </div>
                <div className="col-md-3">
                    <BlockMonthYear month="Decembre" />
                </div>
            </div>
        </>
    )
}

export default ViewYear