import React from 'react';
import FrequencyMonthWeekDaysRule from './FrequencyMonthWeekDaysRule';
import CheckBox from './CheckBox';
import SelectBox from './SelectBox';

export default class BlockFrequencyMonthThirdLevel extends React.Component {

    constructor(props) {
        super(props);
        this.activateWeekDayCustomization = this.activateWeekDayCustomization.bind(this);
        this.changeMonthWeekDaysRanks = this.changeMonthWeekDaysRanks.bind(this);
        this.changeWeekDays = this.changeWeekDays.bind(this);
        this.addNewWeekDaysRule = this.addNewWeekDaysRule.bind(this);
        this.removeWeekDaysRule = this.removeWeekDaysRule.bind(this);
        this.activateMonthDayCustomization = this.activateMonthDayCustomization.bind(this);
        this.changeMonthDayHandler = this.changeMonthDayHandler.bind(this);
    }

    changeWeekDays(event) {
        if (this.props.activateWeekDay) {
            this.props.changeWeekDays(event);
        }
    }

    activateWeekDayCustomization(value) {
        this.props.activateWeekDayCustomization(value);
    }

    changeMonthWeekDaysRanks(weekDaysRankObj) {
        this.props.changeMonthWeekDaysRanks(weekDaysRankObj);
    }

    addNewWeekDaysRule(customIndex) {
        this.props.addNewWeekDaysRuleToFrequencyMonth(customIndex);
    }

    removeWeekDaysRule(customIndex) {
        this.props.removeWeekDaysRuleFromFrequencyMonth(customIndex);
    }

    activateMonthDayCustomization(value) {
        this.props.activateMonthDayCustomization(value);
    }

    changeMonthDayHandler(value) {
        this.props.changeMonthDayHandler(value);
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        const monthWeekDaysRanksName = this.props.monthWeekDaysRanksName;
        const monthWeekDaysRanksValue = this.props.monthWeekDaysRanksValue;
        const monthWeekDaysRanksDisable = this.props.monthWeekDaysRanksDisable;
        const showAddNewRuleButtonOfFirstFrequencyMonthWeekDaysRule = this.props.showAddNewRuleButtonOfFirstFrequencyMonthWeekDaysRule;
        const showRemoveCurentRuleButtonOfFirstFrequencyMonthWeekDaysRule = this.props.showRemoveCurentRuleButtonOfFirstFrequencyMonthWeekDaysRule;

        const weekDayCheckboxName = this.props.weekDayCheckboxName;
        const weekDaysToCheck = this.props.weekDaysToCheck;
        const htmlOptionsMonthDays = [{ value: 1, display: "Premier jour" }, { value: -1, display: "Dernier jour" }];
        for (let i = 2; i <= 31; i++) {
            let option = { value: i, display: i };
            htmlOptionsMonthDays.push(option);
        }

        const activateWeekDay = this.props.activateWeekDay;
        const additionalWeekDaysRules = this.props.additionalWeekDaysRules;

        const htmlAdditionalWeekDaysRules = additionalWeekDaysRules.map((weekDaysRule) =>
            <div className="form-row mb-3" key={weekDaysRule.customIndex}>
                <div className="col-md-auto pt-1 ml-4">
                </div>

                <FrequencyMonthWeekDaysRule monthWeekDaysRanksName={weekDaysRule.monthWeekDaysRanksName} monthWeekDaysRanksValue={weekDaysRule.monthWeekDaysRanksValue} monthWeekDaysRanksDisable={weekDaysRule.monthWeekDaysRanksDisable} changeMonthWeekDaysRanks={this.changeMonthWeekDaysRanks} weekDayCheckboxName={weekDaysRule.weekDayCheckboxName} weekDaysToCheck={weekDaysRule.weekDaysToCheck} changeWeekDays={this.changeWeekDays} activateWeekDay={activateWeekDay} addNewRule={this.addNewWeekDaysRule} removeRule={this.removeWeekDaysRule} customIndex={weekDaysRule.customIndex} showAddNewRuleButton={weekDaysRule.showAddNewRuleButton} showRemoveCurrentRuleButton={weekDaysRule.showRemoveCurrentRuleButton} />
            </div>
        );

        const activateMonthDay = this.props.activateMonthDay;
        const monthDayDisable = this.props.monthDayDisable;
        const freqMTH3rdLevMTHDayVal = this.props.freqMTH3rdLevMTHDayVal;

        return (
            <div>
                <div className="form-row mb-3">
                    {/* <div className="col-md-auto pt-1">
                        <CheckBox name="customMonth3" id="customMonth31" value="1" checked={activateWeekDay} parentHandlerChange={this.activateWeekDayCustomization} />
                    </div> */}

                    <FrequencyMonthWeekDaysRule monthWeekDaysRanksName={monthWeekDaysRanksName} monthWeekDaysRanksValue={monthWeekDaysRanksValue} monthWeekDaysRanksDisable={monthWeekDaysRanksDisable} changeMonthWeekDaysRanks={this.changeMonthWeekDaysRanks} weekDayCheckboxName={weekDayCheckboxName} weekDaysToCheck={weekDaysToCheck} changeWeekDays={this.changeWeekDays} activateWeekDay={activateWeekDay} addNewRule={this.addNewWeekDaysRule} removeRule={this.removeWeekDaysRule} customIndex={1} showAddNewRuleButton={showAddNewRuleButtonOfFirstFrequencyMonthWeekDaysRule} showRemoveCurrentRuleButton={showRemoveCurentRuleButtonOfFirstFrequencyMonthWeekDaysRule} />
                </div>
                {
                    htmlAdditionalWeekDaysRules
                }
                <div className="row mb-3">
                    <div className="col-md-auto pt-1">
                        <CheckBox name="customMonth3" id="customMonth32" value="2" checked={activateMonthDay} parentHandlerChange={this.activateMonthDayCustomization} />
                    </div>
                    <div className="col-md-5">
                        <SelectBox name="monthMonthDays" id="monthMonthDays" value={freqMTH3rdLevMTHDayVal} disabled={monthDayDisable} options={htmlOptionsMonthDays} multiple={true} size="3" parentHandlerChange={this.changeMonthDayHandler} />
                    </div>
                </div>
            </div>
        );
    }
}