import React from 'react';

export default class SelectBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let valueToSubmit = '';
        if (this.props.multiple) {
            valueToSubmit = Array.from(e.target.selectedOptions, option => option.value);;
        }
        else {
            valueToSubmit = e.target.value;
        }

        this.props.parentHandlerChange(valueToSubmit);
    }

    render() {
        const attrsColl = { name: this.props.name, id: this.props.id, size: this.props.size };
        if (this.props.disabled) {
            attrsColl['disabled'] = 'disabled';
        }

        if (this.props.multiple) {
            attrsColl['multiple'] = true;
        }

        const options = this.props.options;
        let keyloop = 0;
        const htmlOptions = options.map((option) => {
            keyloop += 1;
            return (<option value={option.value} key={keyloop}>{option.display}</option>);
        });

        const additionalClasses = this.props.additionalClasses;

        const value = this.props.value;

        return (
            <select className={"form-select " + additionalClasses} {...attrsColl} value={value} onChange={this.handleChange}>
                {htmlOptions}
            </select>
        );
    }
}