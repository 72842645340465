import { useContext, useState } from 'react';
import '../styles/Cell.css';
import { StateContext } from './AppContext';
import Event from './EventHandler';
import Modal from 'react-modal';
import EventDayCell from './EventDayCell';
import { rrulestr } from 'rrule';

function CellHour({ hour, startDate, events, refreshevent }) {
    const statecont = useContext(StateContext)
    const [modal, showModal] = useState(false)

    let blockEvent = [];

    let customStyles = {
        overlay: {
            "zIndex": "2"
        },
        content: {
            top: '10%',
            left: '20%',
            right: '20%',
            bottom: '10%'
            // marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        }
    };

    let startDateTime = statecont.formatDate(new Date(startDate)) + " 00:00";
    let endDateTime = statecont.formatDate(new Date(startDate)) + " 23:59";
    let startDayDateTime = new Date(startDateTime);
    let arrAllEventsToDisplay = [];
    events.forEach((event) => {
        let startDateTime = new Date(event.startDateTime);
        let eventHour = startDateTime.getHours().toString();
        let eventMinutes = startDateTime.getMinutes().toString();
        eventHour = ("0" + eventHour.toString()).slice(-2);
        eventMinutes = ("0" + eventMinutes.toString()).slice(-2);
        let startHourStartDateTime = eventHour + ":" + eventMinutes;
        if (event.recurringRule) {
            let dtStart = statecont.formatTzDate(startDateTime);
            let stringRule = event.recurringRule + "\nDTSTART:" + dtStart;
            const rule = rrulestr(stringRule);
            let endDayDateTime = new Date(endDateTime);
            const allRecurrency = rule.between(startDayDateTime, endDayDateTime);
            allRecurrency.forEach((recurrency) => {
                let setStartDateTime = statecont.formatDate(recurrency) + " " + eventHour + ":" + eventMinutes;
                let setEndDateTime = "";
                if (event.allDay) {
                    setStartDateTime = statecont.formatDate(recurrency) + " 00:00";
                    setEndDateTime = statecont.formatDate(recurrency) + " 23:59";
                } else {
                    let endDateTime = new Date(event.endDateTime);
                    let eventHourEndDateTime = endDateTime.getHours().toString();
                    let eventMinutesEndDateTime = endDateTime.getMinutes().toString();
                    eventHourEndDateTime = ("0" + eventHourEndDateTime.toString()).slice(-2);
                    eventMinutesEndDateTime = ("0" + eventMinutesEndDateTime.toString()).slice(-2);
                    let endHourEndDateTime = eventHourEndDateTime + ":" + eventMinutesEndDateTime;
                    if (statecont.diffMinute(startHourStartDateTime, endHourEndDateTime) <= 0) {
                        endDateTime = recurrency;
                        eventHourEndDateTime = "23";
                        eventMinutesEndDateTime = "59";
                    }
                    setEndDateTime = statecont.formatDate(endDateTime) + " " + eventHourEndDateTime + ":" + eventMinutesEndDateTime;
                }
                let newEvent = {
                    id: event.id,
                    title: event.title,
                    // eventDay: setEventDay,
                    allDay: (event.allDay) ? event.allDay : 0,
                    setStartDateTime: setStartDateTime,
                    setEndDateTime: setEndDateTime,
                    startDateTime: event.startDateTime,
                    endDateTime: (event.endDateTime) ? event.endDateTime : '',
                    location: (event.location) ? event.location : '',
                    description: (event.description) ? event.description : '',
                    createdOn: (event.createdOn) ? event.createdOn : '',
                    createdBy: (event.createdBy) ? event.createdBy : '',
                    recurringRule: (event.recurringRule) ? event.recurringRule : '',
                    timeZone: (event.timeZone) ? event.timeZone : '',
                    parentId: (event.parentId) ? event.parentId : '',
                    updatedOn: (event.updatedOn) ? event.updatedOn : '',
                    updatedBy: (event.updatedBy) ? event.updatedBy : ''
                };
                arrAllEventsToDisplay.push(newEvent);
            });
        } else {
            if (statecont.formatDate(new Date(event.startDateTime)) === statecont.formatDate(startDayDateTime)) {
                if (event.allDay) {
                    let newEvent = {
                        id: event.id,
                        title: event.title,
                        allDay: (event.allDay) ? event.allDay : 0,
                        setStartDateTime: statecont.formatDate(startDayDateTime) + " 00:00",
                        setEndDateTime: statecont.formatDate(startDayDateTime) + " 23:59",
                        startDateTime: (event.startDateTime) ? event.startDateTime : '',
                        endDateTime: (event.endDateTime) ? event.endDateTime : '',
                        location: (event.location) ? event.location : '',
                        description: (event.description) ? event.description : '',
                        createdOn: (event.createdOn) ? event.createdOn : '',
                        createdBy: (event.createdBy) ? event.createdBy : '',
                        recurringRule: (event.recurringRule) ? event.recurringRule : '',
                        timeZone: (event.timeZone) ? event.timeZone : '',
                        parentId: (event.parentId) ? event.parentId : '',
                        updatedOn: (event.updatedOn) ? event.updatedOn : '',
                        updatedBy: (event.updatedBy) ? event.updatedBy : ''
                    }
                    arrAllEventsToDisplay.push(newEvent);
                } else {
                    let setStartDateTime = statecont.formatDate(startDayDateTime) + " " + eventHour + ":" + eventMinutes;
                    let endDayDtTime = new Date(event.endDateTime);
                    let eventEndHour = endDayDtTime.getHours().toString();
                    let eventEndMinutes = endDayDtTime.getMinutes().toString();
                    eventEndHour = ("0" + eventEndHour.toString()).slice(-2);
                    eventEndMinutes = ("0" + eventEndMinutes.toString()).slice(-2);
                    let setEndDateTime = statecont.formatDate(startDayDateTime) + " " + eventEndHour + ":" + eventEndMinutes;
                    let newEvent = {
                        id: event.id,
                        title: event.title,
                        allDay: (event.allDay) ? event.allDay : 0,
                        setStartDateTime: setStartDateTime,
                        setEndDateTime: setEndDateTime,
                        startDateTime: event.startDateTime,
                        endDateTime: (event.endDateTime) ? event.endDateTime : '',
                        location: (event.location) ? event.location : '',
                        description: (event.description) ? event.description : '',
                        createdOn: (event.createdOn) ? event.createdOn : '',
                        createdBy: (event.createdBy) ? event.createdBy : '',
                        recurringRule: (event.recurringRule) ? event.recurringRule : '',
                        timeZone: (event.timeZone) ? event.timeZone : '',
                        parentId: (event.parentId) ? event.parentId : '',
                        updatedOn: (event.updatedOn) ? event.updatedOn : '',
                        updatedBy: (event.updatedBy) ? event.updatedBy : ''
                    }
                    arrAllEventsToDisplay.push(newEvent);
                }
            }
        }
    });

    // sort arrAllEventsToDisplay
    arrAllEventsToDisplay.sort(function (a, b) {
        return new Date(a.setStartDateTime) - new Date(b.setStartDateTime);
    });
    // console.log('arrAllEventsToDisplay', arrAllEventsToDisplay);
    let levelStartDate = startDayDateTime;
    let level = { "distanceOrigin": 0, "startDate": statecont.formatDate(levelStartDate) + " 00:00", "endDate": statecont.formatDate(levelStartDate) + " 23:59" };
    let arrEventsToDisplay = [];
    let countEvents = arrAllEventsToDisplay.length;

    do {
        let initialLength = arrAllEventsToDisplay.length;
        for (let k = 0; k < arrAllEventsToDisplay.length; k++) {
            let start = new Date(arrAllEventsToDisplay[k].setStartDateTime);
            let end = new Date(arrAllEventsToDisplay[k].setEndDateTime);
            let levelStart = new Date(level.startDate);

            // 1hour -> 1 cell , 10 min ->1/6 cell
            if (arrAllEventsToDisplay[k].setStartDateTime === level.startDate) {
                let getHours = end.getHours() - start.getHours();
                let getMinutes = end.getMinutes() - start.getMinutes();
                let heightCell = getHours + getMinutes / 60;
                let cellHour = (Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) !== 12) ? Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) % 12 : 12;
                let amOrPm = (Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) > 11) ? "PM" : "AM";
                let appendEvent = {
                    setStartDateTime: arrAllEventsToDisplay[k].setStartDateTime,
                    setEndDateTime: arrAllEventsToDisplay[k].setEndDateTime,
                    startDateTime: arrAllEventsToDisplay[k].startDateTime,
                    endDateTime: arrAllEventsToDisplay[k].endDateTime,
                    id: arrAllEventsToDisplay[k].id,
                    title: arrAllEventsToDisplay[k].title,
                    allDay: arrAllEventsToDisplay[k].allDay,
                    location: arrAllEventsToDisplay[k].location,
                    description: arrAllEventsToDisplay[k].description,
                    createdOn: arrAllEventsToDisplay[k].createdOn,
                    createdBy: arrAllEventsToDisplay[k].createdBy,
                    recurringRule: arrAllEventsToDisplay[k].recurringRule,
                    timeZone: arrAllEventsToDisplay[k].timeZone,
                    parentId: arrAllEventsToDisplay[k].parentId,
                    updatedOn: arrAllEventsToDisplay[k].updatedOn,
                    updatedBy: arrAllEventsToDisplay[k].updatedBy,
                    startHour: arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1],
                    endHour: arrAllEventsToDisplay[k].setEndDateTime.split(' ')[1],
                    distanceX: level.distanceOrigin,
                    distanceY: (start.getMinutes() / 60).toFixed(2),
                    height: heightCell.toFixed(2),
                    cellDate: arrAllEventsToDisplay[k].setStartDateTime.split(' ')[0].toString(),
                    cellHour: cellHour + ":00 " + amOrPm
                }
                arrEventsToDisplay.push(appendEvent);
                level.startDate = arrAllEventsToDisplay[k].setEndDateTime;
                arrAllEventsToDisplay.splice(k, 1);
                break;
            } else if (start > levelStart) {
                let getHours = end.getHours() - start.getHours();
                let getMinutes = end.getMinutes() - start.getMinutes();
                let heightCell = getHours + getMinutes / 60;
                let cellHour = (Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) !== 12) ? Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) % 12 : 12;
                let amOrPm = (Number(arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1].split(':')[0]) > 11) ? "PM" : "AM";
                let appendEvent = {
                    setStartDateTime: arrAllEventsToDisplay[k].setStartDateTime,
                    setEndDateTime: arrAllEventsToDisplay[k].setEndDateTime,
                    startDateTime: arrAllEventsToDisplay[k].startDateTime,
                    endDateTime: arrAllEventsToDisplay[k].endDateTime,
                    id: arrAllEventsToDisplay[k].id,
                    title: arrAllEventsToDisplay[k].title,
                    allDay: arrAllEventsToDisplay[k].allDay,
                    location: arrAllEventsToDisplay[k].location,
                    description: arrAllEventsToDisplay[k].description,
                    createdOn: arrAllEventsToDisplay[k].createdOn,
                    createdBy: arrAllEventsToDisplay[k].createdBy,
                    recurringRule: arrAllEventsToDisplay[k].recurringRule,
                    timeZone: arrAllEventsToDisplay[k].timeZone,
                    parentId: arrAllEventsToDisplay[k].parentId,
                    updatedOn: arrAllEventsToDisplay[k].updatedOn,
                    updatedBy: arrAllEventsToDisplay[k].updatedBy,
                    startHour: arrAllEventsToDisplay[k].setStartDateTime.split(' ')[1],
                    endHour: arrAllEventsToDisplay[k].setEndDateTime.split(' ')[1],
                    distanceX: level.distanceOrigin,
                    distanceY: (start.getMinutes() / 60).toFixed(2),
                    height: heightCell.toFixed(2),
                    cellDate: arrAllEventsToDisplay[k].setStartDateTime.split(' ')[0].toString(),
                    cellHour: cellHour + ":00 " + amOrPm
                }
                arrEventsToDisplay.push(appendEvent);
                level.startDate = arrAllEventsToDisplay[k].setEndDateTime;
                arrAllEventsToDisplay.splice(k, 1);
                break;
            }
        }

        if (initialLength === arrAllEventsToDisplay.length) {
            level.distanceOrigin = level.distanceOrigin + 1;
            level.startDate = statecont.formatDate(levelStartDate) + " 00:00";
            level.endDate = statecont.formatDate(levelStartDate) + " 23:59";
        }
    } while (arrEventsToDisplay.length !== countEvents);
    // console.log('arrEventsToDisplay', arrEventsToDisplay);

    if (typeof arrEventsToDisplay !== 'undefined') {
        arrEventsToDisplay.forEach((event, index) => {
            if (event.cellHour === hour) {
                blockEvent.push(<EventDayCell key={statecont.day + index + event.id} event={event} />);
            }
        });
    }

    const handleOpenModal = () => {
        showModal(true)
    }

    const handleCloseModal = async () => {
        showModal(false);
        refreshevent();
    }

    const handleCancelModal = () => {
        showModal(false);
    }
    const addEvent = (e) => {
        let id = statecont.day + hour;
        if (e.target.id.toString() === id.toString()) {
            handleOpenModal();
        }
    }

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={modal}
                contentLabel="Event"
                style={customStyles}
            >
                <Event

                    startDateTime={new Date(startDate)}

                    frequencyMonthThirdLevelMonthWeekDaysRanksName="monthWeekDaysRanks"

                    frequencyMonthThirdLevelWeekDayCheckboxName="weekDayFrequencyMonthThirdLevel"

                    freqYr2ndLevSlctBxMthName="slctBxMthYr"

                    freqYr2ndLevChkBxActivateMthDayName="chkBxActivateMthDayName"

                    freqYr2ndLevSlctBxMthDayName="slctBxMthDayName"

                    freqYr2ndLevChkBxActivateWkDayInMthYrRuleName="chkBxActivateWkDayInMthYrRuleName"

                    freqYr2ndLevSlctBxWkDayRkInMthYrRuleName="slctBxWkDayRkInMthYrRuleName"

                    freqYr2ndLevWkDaysInMthYrRuleName="weekDayInMthYrRule"

                    freqYr2ndLevSlctBxWkYrName="slctBxWkYr"

                    freqYr2ndLevWkDaysInWkYrRuleName="weekDayInWkYrRule"

                    freqYr2ndLevSlctBxDayYrName="slctBxDayYr"

                    handleCloseModal2={handleCloseModal}

                    handleCancelModal2={handleCancelModal}
                />

            </Modal>
            <div className="col-md-1  border text-center text-nowrap cellHour">{hour}</div>
            <div id={statecont.day + hour} className="col-md-11  border cellHeight" onDoubleClick={(e) => addEvent(e)}>{blockEvent}</div>
        </>
    );
}

export default CellHour