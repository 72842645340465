import { useContext, useState } from 'react';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { StateContext } from './AppContext';


function ModalEmail({ handleCloseModal }) {
    const stateCont = useContext(StateContext)
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());

    const handleChange = (value) => {
        setValue(value);
    };

    const sendMail = async () => {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/mail`,
            withCredentials: true,
            data: {
                idUser: stateCont.userLogged.id,
                subjectEmail: "Attribution de Tâche",
                htmlOutput: value.toString('html')
            }
        }).then((res) => {
            handleCloseModal()
        })
    };

    return (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document" style={{ maxWidth: '734px', color: 'black' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" style={{ color: 'black' }}>Veuillez insérer les détails du message</h5>
                        <button type="button" className="btn-close" onClick={() => { handleCloseModal() }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            {/* <label htmlFor="avancement">Avancement</label> */}
                            <RichTextEditor
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => handleCloseModal()}>Fermer</button>
                        <button type="button" className="btn btn-primary" onClick={() => sendMail()}>Envoyer Email</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ModalEmail