import React from 'react';

export default class InputText extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.handleChange(event.target.value);
    }

    render() {
        const attrsColl = { name: this.props.name, id: this.props.id, maxLength: this.props.maxLength, placeholder: this.props.placeHolder };
        if (this.props.disabled) {
            attrsColl['disabled'] = 'disabled';
        }

        const addClasses = this.props.addClasses ? ' ' + this.props.addClasses : '';

        return (
            <input type="text" className={'form-control' + addClasses} {...attrsColl} value={this.props.value} onChange={this.handleChange} />
        );
    }
}