import './styles/App.css';
import Router from './components/Routes'

function App() {
    return (
        <Router />
    )
}

export default App;
