import React from 'react';

export default class WeekDayCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.changeStateHandler = this.changeStateHandler.bind(this);
    }

    changeStateHandler(event) {
        //We need to make sure that we only listen on the input checkbox element
        if (event.target.nodeName === 'INPUT') {
            this.props.changeStateHandler(event);
        }
    }

    render() {
        const inputLabel = this.props.label;
        let activeClass = ' btn-secondary';

        if (this.props.checked) {
            activeClass = ' btn-primary active';
        }
        return (
            <label className={"btn mr-2 rounded" + activeClass} onClick={this.changeStateHandler}>
                <input type="checkbox" name={this.props.name} id={this.props.id} value={this.props.value} checked={this.props.checked} onChange={this.changeStateHandler} /> {inputLabel}
            </label>
        );
    }
}