import React from 'react';
import BlockWeekDays from './BlockWeekDays';
import SelectBox from './SelectBox';
import AddNewRule from './AddNewRule';
import RemoveCurrentRule from './RemoveCurrentRule';

export default class FrequencyMonthWeekDaysRule extends React.Component {
    constructor(props) {
        super(props);
        this.changeMonthWeekDaysRanks = this.changeMonthWeekDaysRanks.bind(this);
        this.changeWeekDays = this.changeWeekDays.bind(this);
        this.addThisRule = this.addThisRule.bind(this);
        this.removeThisRule = this.removeThisRule.bind(this);
    }

    changeMonthWeekDaysRanks(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.changeMonthWeekDaysRanks(newObject);
    }

    changeWeekDays(event) {
        if (this.props.activateWeekDay) {
            let newObject = event;
            newObject.customIndex = this.props.customIndex;
            this.props.changeWeekDays(newObject);
        }
    }

    addThisRule(customIndex) {
        this.props.addNewRule(customIndex);
    }

    removeThisRule(customIndex) {
        this.props.removeRule(customIndex);
    }

    render() {

        const weekDayCheckboxName = this.props.weekDayCheckboxName;
        const weekDaysToCheck = this.props.weekDaysToCheck;
        const htmlOptionsWeekDaysRanks = [{ value: "", display: "Chaque" }, { value: 1, display: "1st" }, { value: 2, display: "2nd" }, { value: 3, display: "3rd" }, { value: 4, display: "4th" }, { value: 5, display: "5th" }, { value: -1, display: "Last" }];
        const htmlOptionsMonthDays = [{ value: 1, display: "1st Day" }, { value: -1, display: "Last Day" }, { value: -2, display: "Second-to-the-last Day" }, { value: -3, display: "Third-to-the-last Day" }];
        for (let i = 2; i <= 31; i++) {
            let option = { value: i, display: i };
            htmlOptionsMonthDays.push(option);
        }

        const customIndex = this.props.customIndex;

        const showAddNewRuleButton = this.props.showAddNewRuleButton;
        const showRemoveCurrentRuleButton = this.props.showRemoveCurrentRuleButton;

        let selectBoxAttrs = {
            name: this.props.monthWeekDaysRanksName,
            //id: this.props.monthWeekDaysRanksId,
            value: this.props.monthWeekDaysRanksValue,
            options: htmlOptionsWeekDaysRanks,
            additionalClasses: "my-2",
            parentHandlerChange: this.changeMonthWeekDaysRanks
        }

        if (this.props.monthWeekDaysRanksDisable) {
            selectBoxAttrs['disabled'] = true;
        }

        return (
            <React.Fragment>
                <div className="col-md-auto">
                    <SelectBox {...selectBoxAttrs} />
                </div>
                <div className="col-auto my-2">
                    <BlockWeekDays show={true} weekDayCheckboxName={weekDayCheckboxName} weekDaysToCheck={weekDaysToCheck} changeWeekDays={this.changeWeekDays} />
                </div>

                <div className="col-auto pt-2">
                    {showAddNewRuleButton && <AddNewRule customIndex={customIndex} addNewRule={this.addThisRule} />}

                    {showRemoveCurrentRuleButton && <RemoveCurrentRule customIndex={customIndex} removeRule={this.removeThisRule} />}
                </div>
            </React.Fragment>
        );
    }
}