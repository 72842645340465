import React from 'react';

export default class Switch extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.parentHandlerChange(event.target.checked);
    }

    render() {
        const name = this.props.name;
        const id = this.props.id;
        const value = this.props.value;
        const label = this.props.label;
        return (
            <div className="custom-control custom-switch mb-3">
                <input type="checkbox" className="custom-control-input me-2" id={id} name={name} value={value} onChange={this.handleChange} checked={this.props.checked} />
                <label className="custom-control-label" htmlFor={id}>{label}</label>
            </div>
        );
    }
}