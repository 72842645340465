import { useRef, useState, useEffect } from 'react'
import '../styles/Contact.css'
import 'animate.css'
import intlTelInput from 'intl-tel-input'
import utils from 'intl-tel-input/build/js/utils'
import 'intl-tel-input/build/css/intlTelInput.css'
import axios from 'axios'
import $ from "jquery"

function Contact() {
    const form = useRef()
    const tel = useRef()
    const [formContactSuccess, setSuccess] = useState(false)
    const [formObj, setForm] = useState({ nameCompany: '', mail: '', tel: '', description: '' })
    const [errors, setErrors] = useState({ nameCompany: '', mail: '', tel: '', description: '' })

    useEffect(() => {
        // initialise plugin
        if (tel.current) {
            intlTelInput(tel.current, {
                initialCountry: "MG",
                utilsScript: utils,
                separateDialCode: true,
                geoIpLookup: function (success, failure) {
                    $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
                        var countryCode = (resp && resp.country) ? resp.country : "MG"
                        success(countryCode)
                    })
                }
            })
        }
    }, [formContactSuccess])

    const submitProject = async (e) => {
        e.preventDefault()
        let errorValidation = {}

        let iti = window.intlTelInputGlobals.getInstance(tel.current)

        if (formObj.tel)
            errorValidation.tel = iti.isValidNumber() ? '' : 'Invalide numero tel'

        errorValidation.mail = formObj.mail.length ? '' : 'Mail requis'
        if (formObj.mail.length > 0)
            errorValidation.mail = formObj.mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? errorValidation.mail : 'Invalide email'
        errorValidation.nameCompany = formObj.nameCompany.length ? '' : 'Nom ou compagnie requis'
        errorValidation.description = formObj.description.length ? '' : 'Description requis'

        if (errorValidation.mail || errorValidation.nameCompany || errorValidation.description) {
            setErrors(errorValidation)
            setTimeout(() => {
                setErrors({ nameCompany: '', email: '', tel: '', description: '' })
            }, 7000)
        } else {
            setErrors({ nameCompany: '', email: '', tel: '', description: '' })
            let dataContact = {
                ...formObj, tel: "+" + iti.selectedCountryData.dialCode + formObj.tel
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}/api/project`,
                data: dataContact,
                withCredentials: true
            }).then((res) => res.data).then((data) => setSuccess(true))
                .catch((err) => { })
        }
    }

    return (
        <div id="Contact" className="animate__animated animate__zoomInDown" >
            <div className="container">
                <h1 className="contact-title">Contact</h1>
                <div className="contact-form">
                    {
                        formContactSuccess ?
                            (
                                <div className="m-auto d-flex text-center justify-content-center align-items-center" style={{ "height": "652.375px" }}>
                                    <div >
                                        <i className="fas fa-check-circle" style={{ fontSize: "70px" }} /><br /><br />
                                        <div className="h3">Merci de votre confiance!</div>
                                        <div className="h6"> Votre demande a été envoyé. Notre équipe d'assistance vous répondra dans les plus brefs délais.</div><br />
                                        <span className="btn-click" onClick={() => {
                                            setSuccess(false)
                                            setForm({ nameCompany: '', mail: '', tel: '', description: '' })
                                        }}>
                                            Terminé <img src="./img/Smileynoir.png" alt="smileynoir" className="btn-smiley" />
                                        </span>
                                    </div>
                                </div>
                            ) :
                            (
                                <>
                                    <p className="h5 text-center">Un projet à mettre en place?</p><br />
                                    <p>Nous sommes là pour vous aider à le réaliser. Discutons-en!</p>
                                    <form ref={form} onSubmit={(e) => submitProject(e)}>
                                        <div className="flex-row g-3">
                                            <div className="col-9">
                                                <label htmlFor="nameCompany" className="form-label fw-500">Nom ou Société <span className="red-text">*</span></label>
                                                <input type="text" className="form-control" id="nameCompany" name="nameCompany" value={formObj.nameCompany} required={true} onChange={(e) => setForm({ ...formObj, nameCompany: e.target.value })} placeholder="Nom" />
                                                {errors.nameCompany && <div className="error-contact alert alert-warning">{errors.nameCompany}</div>}
                                            </div>
                                            <div className="col-9">
                                                <label htmlFor="mail" className="form-label fw-500">Adresse email <span className="red-text">*</span></label>
                                                <input type="text" className="form-control" id="mail" name="mail" value={formObj.mail} required={true} onChange={(e) => setForm({ ...formObj, mail: e.target.value })} placeholder="Email" />
                                                {errors.mail && <div className="error-contact alert alert-warning">{errors.mail}</div>}
                                            </div>
                                            <div >
                                                <label htmlFor="tel" className="form-label fw-500">Télephone</label><br />
                                                <input type="tel" className="form-control" id="tel" name="tel" ref={tel} value={formObj.tel} onChange={(e) => setForm({ ...formObj, tel: e.target.value })} placeholder="Numéro telephone" />
                                                {errors.tel && <div className="error-contact alert alert-warning">{errors.tel}</div>}
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="form-label fw-500">Description du projet <span className="red-text">*</span></label>
                                                <textarea className="form-control" id="description" name="description" cols="30" rows="10" value={formObj.description} onChange={(e) => setForm({ ...formObj, description: e.target.value })} placeholder="Dites-nous un peu plus" />
                                                {errors.description && <div className="error-contact alert alert-warning">{errors.description}</div>}
                                            </div>
                                            <div className="d-flex flex-row flex-wrap justify-content-around">
                                                <div className="pt-3">
                                                    <span onClick={(e) => submitProject(e)} className="btn-click"> Envoyer <img src="./img/Smileynoir.png" alt="smileynoir" className="btn-smiley" /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default Contact
