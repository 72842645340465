import React from 'react';

export default class CheckBox extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const elemTarget = event.target;
        const elemTagName = elemTarget.tagName;

        let checked = false;

        //If the "label" element gets clicked, then try to get the "checkbox" element
        if (elemTagName === 'LABEL') {
            const checkboxElem = elemTarget.previousSibling;
            const isCheckboxElemChecked = checkboxElem.checked;
            if (isCheckboxElemChecked) {
                checked = false;
            }
            else {
                checked = true;
            }
        }
        else if (elemTagName === 'DIV') {
            const checkboxElem = elemTarget.childNodes[0];
            const isCheckboxElemChecked = checkboxElem.checked;

            if (isCheckboxElemChecked) {
                checked = false;
            }
            else {
                checked = true;
            }
        }
        else if (elemTagName === 'INPUT') {
            checked = elemTarget.checked;
        }

        this.props.parentHandlerChange(checked);
    }

    render() {
        const inputLabel = this.props.label;

        return (
            <div className="custom-control custom-checkbox">
                <input type="checkbox" name={this.props.name} id={this.props.id} value={this.props.value} checked={this.props.checked} onChange={this.handleChange} className="custom-control-input me-2" disabled={this.props.disabled} />
                <label className="custom-control-label" htmlFor={this.props.id}>{inputLabel}</label>
            </div>
        );
    }
}