/*global FB*/
import { useEffect } from 'react'

const FbChatBot = () => {
    useEffect(() => {
        let chatbox = document.getElementById('fb-customer-chat')
        chatbox.setAttribute("page_id", "105227578678062")
        chatbox.setAttribute("attribution", "biz_inbox")

        window.fbAsyncInit = function () {
            FB.init({
                xfbml: true,
                version: 'v13.0'
            })
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s); js.id = id
            js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js'
            fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
    }, [])

    return (
        <>
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat">
            </div>
        </>
    )
}

export default FbChatBot