import React from 'react';

export default class AddNewRule extends React.Component {

    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(event) {
        event.preventDefault();
        this.props.addNewRule(this.props.customIndex);

    }

    render() {
        // const customIndex = this.props.customIndex;
        return (
            <button className="btn btn-sm" onClick={this.clickHandler}>
                <img src="assets/bootstrap-icons/icons/plus.svg" alt='icon-plus'/> Add New Rule
            </button>
        )
    }
}