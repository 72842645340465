import React from 'react';
import DateTimePicker from 'react-datetime-picker';


export default class InputDate extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.input = React.createRef();
    }


    handleChange(newValue) {
        if (newValue instanceof Date) {
            this.props.handleChange(newValue);
        }
    }

    render() {

        return (

            <DateTimePicker
                onChange={this.handleChange}
                value={this.props.value}
                disabled={this.props.disabled}
            />

        );
    }

}