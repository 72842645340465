import { useEffect } from 'react'
import '../styles/Offres.css'
import Offre from '../components/Offre'
import CardFlex from '../helper/multiCardFlex'

function Offres() {
    const cardOffres = [
        {
            linkImage: "img/build.jpg", name: "Applications Web et Mobile", description: "Des outils pour vous servir...", body: <>
                <h2>Des outils pour vous servir...</h2>
                <p>
                    Saviez-vous que plus de personnes sur la planète possèdent un appareil mobile qu'une brosse à dents ?<br />
                    À l'ère numérique, un site Web réactif de haute qualité et adapté aux mobiles est un must pour toute
                    entreprise. Plus encore, il faut un site web adaptatif ou "Responsive".
                </p>
                <p>
                    Cela est particulièrement vrai pour les petites entreprises et les start-ups où les gens évalueront
                    initialement vos compétences en fonction de ce que vous affichez en ligne. Si votre entreprise a besoin
                    d'aide pour bien se présenter en ligne, nous pouvons vous aider !
                </p>
                <p>
                    Nous offrons des tarifs très compétitifs. Aussi, si vous avez besoin d'un nouveau site Web à faible coût ou
                    d'aide pour améliorer votre offre Web, n'hésitez pas à nous contacter dès aujourd'hui.
                </p>
                <p>
                    <a href="#Contact">En savoir plus</a>
                </p>
            </>
        },
        {
            linkImage: "img/graf.jpg", name: "Design et Rédaction de Contenu", description: "Des outils à votre image...", body: <>
                <h2>Design et Rédaction de Contenu</h2>
                <p>
                    Une image vaut mille mots, ne les laissez pas être destructeurs.
                    Une partie importante de tout site Web ou élément de marketing est la qualité de sa conception et de ses
                    images. Un design de haute qualité dépeint un produit ou un service standing, tandis qu'un design de
                    mauvaise qualité montre la paresse et la négligence.
                </p>
                <p>
                    Nous pouvons vous assurer que lorsque vos visiteurs accèdent à votre site, ils obtiennent ce facteur
                    impressionnant dont vous avez besoin pour vous démarquer. Que votre site Web ou vos applications aient
                    besoin d'un petit rafraîchissement ou de faire un grand pas dans l'ère moderne, nous sommes là pour vous
                    aider.<br />
                    Contactez-nous dès aujourd'hui pour discuter de vos besoins avec nous et nous vous garantissons que vous en
                    serez ravi.
                </p>
                <p>
                    <a href="#Contact">En savoir plus</a>
                </p>
            </>
        },
        {
            linkImage: "img/fix.jpg", name: "Consulting Informatique", description: "Un support disponible...", body:
                <>
                    <h2>Un support disponible...</h2>
                    <p>
                        L'informatique est destinée à vous faciliter la vie. Si ce n'est pas le cas, il est temps de le réparer.
                    </p>
                    <p>
                        Si vous perdez un temps fou à cause de problèmes informatiques ou de systèmes informatiques inefficaces,
                        vous n'êtes pas seul et nous pouvons vous aider. Votre temps est trop précieux pour passer chaque
                        jour à combattre les machines juste pour les amener à faire les choses pour lesquelles elles sont conçues.
                        <br />Appelez-nous et nous remettrons votre informatique en marche pour vous et non l'inverse.
                    </p>
                    <p>
                        <a href="#Contact">En savoir plus</a>
                    </p>
                </>
        },
        {
            linkImage: "img/serveur.jpg", name: "Hébergement site web, emails et base de données", description: "En toute liberté...", body:
                <>
                    <h2>Votre activité en toute liberté</h2>
                    <p>
                        Lorsque vous souhaitez démarrer une entreprise dans le monde réel, vous avez besoin d'un emplacement
                        physique pour installer, stocker et vendre vos produits. Les mêmes règles s'appliquent dans le monde
                        numérique lors de la création d'un site Web.
                    </p>
                    <p>
                        Lorsque vous avez votre site web, vous disposez d'une série de fichiers, d'images et de code HTML. Ces
                        fichiers prennent de la place et ont besoin d'un endroit pour vivre. Sans une maison en ligne, vos fichiers
                        resteraient simplement sur votre ordinateur et personne ne les verrait jamais. Un fournisseur d'hébergement
                        fournira un emplacement sur un serveur Web pour stocker tous vos fichiers et se charge de fournir les
                        fichiers de votre site Web dès qu'un navigateur fait une demande en saisissant votre nom de domaine.
                    </p>
                    <p>
                        Lorsque vous payez pour des services d'hébergement, vous louez simplement un espace de stockage sur
                        Internet, tout comme vous loueriez un magasin physique pour votre entreprise. Débarrassez-vous des
                        contraintes d'infrastructures tout en gardant la main sur vos données.
                    </p>
                    <p>
                        <a href="#Contact">En savoir plus</a>
                    </p>
                </>
        }
    ]
    useEffect(() => {
        new CardFlex('#js-card-flex')
    }, [])

    return (
        <div id="Offres">
            <div className="container">
                <h1 className="offres-title h1 mb-4">Offres</h1>
                <div className="card-flex" id="js-card-flex">
                    {cardOffres.map((offre, idxO) => <Offre key={idxO} linkImage={offre.linkImage} name={offre.name} description={offre.description} body={offre.body} />)}
                </div>
            </div>
        </div>
    )
}

export default Offres
