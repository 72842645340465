import { useEffect, useState } from "react"
import axios from 'axios'

function ModalUser({ handleCloseModal, idGroupe, addGroup }) {
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState("")

    useEffect(() => {
        const getUsers = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/user/except/${idGroupe}`,
                withCredentials: true
            }).then((res) => {
                setUsers(res.data.users)
            })
        }
        getUsers();
    }, [])

    return (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter membre</h5>
                        <button type="button" className="btn-close" onClick={() => handleCloseModal()} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <label htmlFor="user">User</label>
                            <select name="user" id="user" className="form-select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">------</option>
                                {users.map((user, idUser) => <option key={idUser} value={user._id}>{user.pseudo}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => handleCloseModal()}>Fermer</button>
                        <button type="button" className="btn btn-primary" onClick={() => addGroup(selectedUser)}>Ajouter au groupe</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalUser