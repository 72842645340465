import '../styles/Design2.css'

function Design2() {
    return (
        <div className="design2">
            <ul>
                <li>
                    <span className='parent'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>L</span>
                    </span>
                </li>
                <li>
                    <span className='parent'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>A</span>
                    </span>
                </li>
                <li>
                    <span className='parent'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>N</span>
                    </span>
                </li>
                <li>
                    <span className='parent'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>Z</span>
                    </span>
                </li>
                <li>
                    <span className='parent'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>O</span>
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default Design2