import React from 'react';
import SelectBox from './SelectBox';
import CheckBox from './CheckBox';
import BlockWeekDays from './BlockWeekDays';

export default class FreqYrMthYrRule extends React.Component {
    constructor(props) {
        super(props);
        this.changeSlctBxMth = this.changeSlctBxMth.bind(this);
        this.activateMthDay = this.activateMthDay.bind(this);
        this.changeSlctBxMthDay = this.changeSlctBxMthDay.bind(this);
        this.activateWkDay = this.activateWkDay.bind(this);
        this.changeSlctBxWkDayRk = this.changeSlctBxWkDayRk.bind(this);
        this.changeWeekDays = this.changeWeekDays.bind(this);
        this.addThisRule = this.addThisRule.bind(this);
        this.removeThisRule = this.removeThisRule.bind(this);
    }

    changeSlctBxMth(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.changeSlctBxMth(newObject);
    }

    activateMthDay(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.activateMthDay(newObject);
    }

    changeSlctBxMthDay(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.changeSlctBxMthDay(newObject);
    }

    activateWkDay(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.activateWkDay(newObject);
    }

    changeSlctBxWkDayRk(value) {
        let newObject = {};
        newObject.customIndex = this.props.customIndex;
        newObject.value = value;
        this.props.changeSlctBxWkDayRk(newObject);
    }

    changeWeekDays(event) {
        if (this.props.chkBxActivateWkDayChecked) {
            let newObject = event;
            newObject.customIndex = this.props.customIndex;
            this.props.changeWeekDays(newObject);
        }
    }

    addThisRule(customIndex) {
        this.props.addNewRule(customIndex);
    }

    removeThisRule(customIndex) {
        this.props.removeRule(customIndex);
    }

    render() {

        const customIndex = this.props.customIndex;

        const slctBxMthName = this.props.slctBxMthName;
        const slctBxMthId = (this.props.slctBxMthId !== "undefined" ? this.props.slctBxMthId : slctBxMthName);
        const slctBxMthOptions = this.props.slctBxMthOptions;
        const slctBxMthVal = this.props.slctBxMthVal;
        const slctBxMthDisabled = this.props.slctBxMthDisabled;
        const slctBxMthAddClss = this.props.slctBxMthAddClss;

        const chkBxActivateMthDayName = this.props.chkBxActivateMthDayName;
        const chkBxActivateMthDayId = (typeof this.props.chkBxActivateMthDayId !== "undefined" ? this.props.chkBxActivateMthDayId : chkBxActivateMthDayName);
        const chkBxActivateMthDayDisabled = this.props.chkBxActivateMthDayDisabled;
        const chkBxActivateMthDayChecked = this.props.chkBxActivateMthDayChecked;

        const slctBxMthDayName = this.props.slctBxMthDayName;
        const slctBxMthDayId = (this.props.slctBxMthDayId !== "undefined" ? this.props.slctBxMthDayId : slctBxMthDayName);
        const slctBxMthDayOptions = this.props.slctBxMthDayOptions;
        const slctBxMthDayVal = this.props.slctBxMthDayVal;
        const slctBxMthDayDisabled = this.props.slctBxMthDayDisabled;
        const slctBxMthDayAddClss = this.props.slctBxMthDayAddClss;

        const chkBxActivateWkDayName = this.props.chkBxActivateWkDayName;
        const chkBxActivateWkDayId = (typeof this.props.chkBxActivateWkDayId !== "undefined" ? this.props.chkBxActivateWkDayId : chkBxActivateWkDayName);
        const chkBxActivateWkDayDisabled = this.props.chkBxActivateWkDayDisabled;
        const chkBxActivateWkDayChecked = this.props.chkBxActivateWkDayChecked;

        const slctBxWkDayRkName = this.props.slctBxWkDayRkName;
        const slctBxWkDayRkId = (typeof this.props.slctBxWkDayRkId !== "undefined" ? this.props.slctBxWkDayRkId : slctBxWkDayRkName);
        const slctBxWkDayRkOptions = this.props.slctBxWkDayRkOptions;
        const slctBxWkDayRkVal = this.props.slctBxWkDayRkVal;
        const slctBxWkDayRkDisabled = this.props.slctBxWkDayRkDisabled;
        const slctBxWkDayRkAddClss = this.props.slctBxWkDayRkAddClss;

        const weekDayCheckboxName = this.props.weekDayCheckboxName + customIndex;
        const weekDaysToCheck = this.props.weekDaysToCheck;

        return (
            <React.Fragment>
                <div className="col-md-auto">
                    <SelectBox name={slctBxMthName} id={slctBxMthId} options={slctBxMthOptions} value={slctBxMthVal} parentHandlerChange={this.changeSlctBxMth} disabled={slctBxMthDisabled} multiple={true} size="3" additionalClasses={slctBxMthAddClss} />
                </div>
                <div className="col-md-auto pt-1">
                    <CheckBox name={chkBxActivateMthDayName} id={chkBxActivateMthDayId} value="1" disabled={chkBxActivateMthDayDisabled} checked={chkBxActivateMthDayChecked} parentHandlerChange={this.activateMthDay} />
                </div>
                <div className="col-md-auto">
                    <SelectBox name={slctBxMthDayName} id={slctBxMthDayId} value={slctBxMthDayVal} parentHandlerChange={this.changeSlctBxMthDay} disabled={slctBxMthDayDisabled} options={slctBxMthDayOptions} multiple={true} size="3" additionalClasses={slctBxMthDayAddClss} />
                </div>
                <div className="col-md-auto pt-1">
                    <CheckBox name={chkBxActivateWkDayName} id={chkBxActivateWkDayId} value="1" disabled={chkBxActivateWkDayDisabled} checked={chkBxActivateWkDayChecked} parentHandlerChange={this.activateWkDay} />
                </div>
                <div className="col-md-auto">
                    <SelectBox name={slctBxWkDayRkName} id={slctBxWkDayRkId} value={slctBxWkDayRkVal} parentHandlerChange={this.changeSlctBxWkDayRk} disabled={slctBxWkDayRkDisabled} options={slctBxWkDayRkOptions} additionalClasses={slctBxWkDayRkAddClss} />
                </div>

                <div className="col-auto my-2">
                    <BlockWeekDays show={true} weekDayCheckboxName={weekDayCheckboxName} weekDaysToCheck={weekDaysToCheck} changeWeekDays={this.changeWeekDays} />
                </div>

                <div className="col-auto pt-2">
                    {/* { showAddNewRuleButton && <AddNewRule customIndex={customIndex} addNewRule={this.addThisRule} />}

					{ showRemoveCurrentRuleButton && <RemoveCurrentRule customIndex={customIndex} removeRule={this.removeThisRule} />} */}
                </div>
            </React.Fragment>
        )
    }
}