import React from 'react';

export default class RadioButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.parentHandlerChange(event.target.value);
        if (this.props.hideAllBlockFrequenciesHandler) {
            this.props.hideAllBlockFrequenciesHandler(event.target.checked);
        }
        if (this.props.showBlockFrequencyWeekFirstLevelHandler) {
            this.props.showBlockFrequencyWeekFirstLevelHandler(event.target.checked);
        }

        if (this.props.showBlockFrequencyWeekSecondLevelHandler) {
            this.props.showBlockFrequencyWeekSecondLevelHandler(event.target.checked);
        }

        if (this.props.showBlockFrequencyMonthFirstLevelHandler) {
            this.props.showBlockFrequencyMonthFirstLevelHandler(event.target.checked);
        }

        if (this.props.showBlockFrequencyMonthSecondLevelHandler) {
            this.props.showBlockFrequencyMonthSecondLevelHandler(event.target.checked);
        }

        if (this.props.showBlockFrequencyMonthThirdLevelHandler) {
            this.props.showBlockFrequencyMonthThirdLevelHandler(event.target.checked);
        }

        if (this.props.showBlockFreqYr1stLevHandler) {
            this.props.showBlockFreqYr1stLevHandler(event.target.checked);
        }

        if (this.props.showBlockFreqYr2ndLevHandler) {
            this.props.showBlockFreqYr2ndLevHandler(event.target.checked);
        }

    }

    render() {
        const inputLabel = this.props.label;
        const inputToDisplayInline = this.props.isInline;
        const inputAttrsColl = { name: this.props.name, id: this.props.id, value: this.props.value };
        if (this.props.checked) {
            inputAttrsColl['checked'] = 'checked';
        }

        const wrapperElemAddClasses = this.props.wrapperElemAddClasses ? ' ' + this.props.wrapperElemAddClasses : '';

        return (
            <div className={"custom-control custom-radio" + wrapperElemAddClasses + (inputToDisplayInline ? ' custom-control-inline' : '')}>
                <input type="radio" name={this.props.name} id={this.props.id} value={this.props.value} checked={this.props.checked} onChange={this.handleChange} className="custom-control-input me-2" />
                <label className="custom-control-label" htmlFor={inputAttrsColl.id}>{inputLabel}</label>
            </div>
        );

    }

}