import TacheSelect from './TacheSelect';
import TacheLog from './TacheLog';
import '../styles/Tache.css'
import { StateContext } from './AppContext';
import { useContext } from 'react';
import TaskManager from './TaskManager';

function Tache() {
    const statecont = useContext(StateContext)
    if (statecont.task === '')
        return <TaskManager />
    else
        return (
            <div id="Tache" className="tabcontent">
                <TacheSelect />
                <TacheLog />
            </div>
        );
}

export default Tache

