import { useContext, useState, useEffect, useCallback } from "react"
import { StateContext } from "./AppContext"
import ModalEmail from "./ModalEmail"
import axios from 'axios'

function TacheSelect() {
    const stateCont = useContext(StateContext)
    const statuts = ['Non assigné', 'En cours', 'Terminé', 'En retard']
    const priorites = ['A', 'B', 'C', 'D']
    const types = ['Administratif', 'Analyse', 'Conception', 'Interface', 'Migration', 'Nouvelles Fonctionnalités', 'Sécurité', 'Test', 'Autres']
    const [users, setUsers] = useState([])
    const [task, setTask] = useState({ name: '', responsable: '', assistant: '', status: '', priorite: '', type: '' })
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const getUsers = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/user`,
                withCredentials: true
            }).then((res) => {
                setUsers(res.data)
            })
        }
        getUsers();
    }, [])

    useEffect(() => {
        const getTache = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/tache/${stateCont.task}`,
                withCredentials: true
            }).then((res) => {
                if (res.data.tache.length)
                    setTask(res.data.tache[0])
            }).catch((err) => err);
        }
        getTache()
    }, [stateCont.task])

    useEffect(() => {
        if (stateCont.task != null) {
            const updateTache = async () => {
                await axios({
                    method: "put",
                    url: `${process.env.REACT_APP_API_URL}/api/tache/${stateCont.task}`,
                    withCredentials: true,
                    data: task
                })
            }
            updateTache()
        }
    }, [task])

    const handleSelectClick = useCallback((el) => {
        if (el.classList.contains('active')) {
            el.classList.remove('active')
        } else {
            el.classList.add('active')
        }
    }, [])

    const createChat = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/groupe`,
            data: {
                idTache: stateCont.task,
                userResponsable: task.responsable
            },
            withCredentials: true
        }).then((res) => res.data).then((data) => data, alert("Groupe de discussion créée"))
            .catch((err) => { })
    }

    const handleCloseModal = () => { setShowModal(false) };
    const handleShowModal = () => {
        // Attendre 3 secondes avant d'afficher le modal
        setTimeout(() => {
            setShowModal(true);
        }, 200);
    };

    return (
        <div>
            <table className="tableSelect">
                <thead>
                    <tr>
                        <th style={{ color: '#E61010', fontWeight: 'bold' }}>Tâche</th>
                        <th style={{ color: '#E61010', fontWeight: 'bold' }}>Responsable</th>
                        <th style={{ color: '#E61010', fontWeight: 'bold' }}>Assistant</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ fontSize: '16px' }}>{task?.tache}</td>
                        <td>
                            <div className="zone-selection" onClick={(e) => handleSelectClick(e.currentTarget)} style={{ display: 'inline-block' }}>
                                <select name="responsable" value={task?.responsable} onChange={(e) => { setTask({ ...task, responsable: e.target.value }); }}>
                                    <option value="">Non assigné</option>
                                    {users.map((currentUser, idUser) => <option key={idUser} value={currentUser._id}>{currentUser.pseudo}</option>)}
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                            <div className="tableIcon1">
                                <i className="far fa-envelope-open-text" title="Notifier le responsable" onClick={() => handleShowModal()}>
                                </i>
                                {showModal && (
                                    <ModalEmail handleCloseModal={handleCloseModal} />
                                )}
                                <i className="fab fa-facebook-messenger" title="Groupe de discussion" style={{ marginLeft: '6px' }} onClick={() => createChat()}></i>
                            </div>
                        </td>
                        <td>
                            <div className="zone-selection" onClick={(e) => handleSelectClick(e.currentTarget)} style={{ display: 'inline-block' }}>
                                <select name="assistant" value={task?.assistant} onChange={(e) => { setTask({ ...task, assistant: e.target.value }); }}>
                                    <option value="">Non assigné</option>
                                    {users.map((currentUser, idUser) => <option key={idUser} value={currentUser._id}>{currentUser.pseudo}</option>)}
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                            <div className="tableIcon2">
                                <i className="far fa-envelope-open-text"></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ color: '#E61010', fontWeight: 'bold' }}>Statut</td>
                        <td style={{ color: '#E61010', fontWeight: 'bold' }}>Priorité</td>
                        <td style={{ color: '#E61010', fontWeight: 'bold' }}>Type</td>
                    </tr>
                    <tr>
                        <td>
                            <div className="zone-selection" onClick={(e) => handleSelectClick(e.currentTarget)} style={{ display: 'inline-block' }}>
                                <select name="statut" value={task?.status} onChange={(e) => { setTask({ ...task, status: e.target.value }); }}>
                                    {
                                        statuts.map((status, idStatus) => <option key={idStatus} value={status}>{status}</option>)
                                    }
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                        </td>
                        <td>
                            <div className="zone-selection" onClick={(e) => handleSelectClick(e.currentTarget)} style={{ display: 'inline-block' }}>
                                <select name="priorite" value={task?.priorite} onChange={(e) => { setTask({ ...task, priorite: e.target.value }); }}>
                                    {
                                        priorites.map((priorite, idPriorite) => <option key={idPriorite} value={priorite}>{priorite}</option>)
                                    }
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                        </td>
                        <td>
                            <div className="zone-selection" onClick={(e) => handleSelectClick(e.currentTarget)} style={{ display: 'inline-block' }}>
                                <select name="type" value={task?.type} onChange={(e) => { setTask({ ...task, type: e.target.value }); }}>
                                    <option value="">-----</option>
                                    {
                                        types.map((type, idType) => <option key={idType} value={type}>{type}</option>)
                                    }
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TacheSelect