import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ChartBarDashboard({ tachesProject }) {

    useEffect(() => {
        if (tachesProject.length > 0) {
            const myChart = am5.Root.new('myChart');

            myChart.setThemes([am5themes_Animated.new(myChart)]);

            let chart = myChart.container.children.push(
                am5xy.XYChart.new(myChart, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    layout: myChart.verticalLayout
                })
            );

            var legend = chart.children.push(
                am5.Legend.new(myChart, {
                    centerX: am5.percent(50),
                    x: am5.percent(15),
                    y: am5.percent(104),
                    centerY: am5.percent(104)
                })
            );

            var data = tachesProject.map((tache) => {
                return (
                    {
                        membre: tache.tache,
                        evolution: Number(tache.avancement)
                    }
                )
            });

            let yRenderer = am5xy.AxisRendererY.new(myChart, { minGridDistance: 15 });
            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(myChart, {
                    categoryField: "membre",
                    renderer: yRenderer
                })
            );
            yAxis.get("renderer").labels.template.setAll({
                inside: true,
                fontSize: 22
            });
            yAxis.get("renderer").labels.template.adapters.add("dy", function (y) {
                return -chart.plotContainer.height();
            });
            yAxis.data.setAll(data);

            let xRenderer = am5xy.AxisRendererX.new(myChart, {
                labels: {
                    fontSize: 12, // Correction ici
                    fill: am5.color(0x000000),
                }
            });
            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(myChart, {
                    renderer: xRenderer
                })
            );
            xAxis.data.setAll(data);

            var series = chart.series.push(am5xy.ColumnSeries.new(myChart, {
                name: "Tâches",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "evolution",
                categoryYField: "membre"
            }));

            // Définir une palette de couleurs
            // const palette = am5.ColorSet.new(myChart, {
            //     colors: [
            //         am5.color(0xff0000),
            //         am5.color(0x00ff00),
            //         am5.color(0x0000ff),
            //         am5.color(0xffff00),
            //         am5.color(0xff00ff),
            //         am5.color(0x00ffff)
            //     ]
            // });

            // series.columns.template.adapters.add("fill", (fill, target) => {
            //     return palette.getIndex(target.dataItem.index);
            // });

            // series.columns.template.adapters.add("stroke", (stroke, target) => {
            //     return palette.getIndex(target.dataItem.index);
            // });

            // Récupération automatique de la hauteur du graphique
            var cellSize = 70;
            series.events.on("datavalidated", function (ev) {
                var series = ev.target;
                var chart = series.chart;
                var xAxis = chart.xAxes.getIndex(0);
                var chartHeight = series.data.length * cellSize + xAxis.height() + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);
                chart.root.dom.style.height = chartHeight + "px";
            });

            //affichage detail sur la bar lors du passge
            series.columns.template.setAll({
                tooltipText: "{categoryY}: {valueX}%",
                width: am5.percent(90),
                tooltipY: 0
            });

            // series.ticks.template.set("visible", false);
            // xAxis.labels.template.setAll('visible', false);
            // xAxis.labels.template.set("text", "");

            series.data.setAll(data);
            series.appear();
            legend.data.push(series);
            return () => {
                myChart.dispose()
            }
        }
    }, [tachesProject]);

    if (tachesProject.length > 0) {
        return (
            <div style={{ width: '98%', marginLeft: '10px' }}>
                <div id="myChart"></div>
            </div>
        );
    } else {
        return <></>
    }

}

export default ChartBarDashboard;
