import { useState, useCallback } from 'react'
import '../styles/ToDoList.css'

function ToDoList() {
    const [toDos, setTodos] = useState(["Apprendre une nouvelle langue", "Créer votre propre site", "Manger 5 fois par jour", "Marquer un but contre son camp", "Lire un livre"])
    const [checkedList, setCheckList] = useState([1, 4])

    const addNewToDo = useCallback(() => {
        var inputValue = document.getElementById("newToDo").value;
        if (inputValue === '') {
            alert("Ajouter une nouvelle tâche!");
        } else {
            setTodos((toDos) => ([...toDos, inputValue]))
        }
        document.getElementById("newToDo").value = "";
    }, [])

    return (
        <div className='todo-container'>
            <div className="demo-name">Votre propre gestionnaire de tâches</div>
            <div id="todolist-head" className="header">
                <div className="header-caption">Tâches à faire</div>
                <input type="text" id="newToDo" placeholder="Nouvelle Tâche" />
                <span onClick={() => addNewToDo()} className="addBtn">Ajouter</span>
            </div>
            <div id="myToDoList">
                {toDos.map((toDo, idxToDo) => {
                    if (checkedList.includes(idxToDo))
                        return (
                            <div key={idxToDo} className="item checked" onClick={() => {
                                let newCheckList = checkedList.filter(function (value) {
                                    return value !== idxToDo;
                                })
                                setCheckList(newCheckList)
                            }}>
                                <div className="task">{toDo}</div>
                                <div className="close" onClick={(e) => e.target.parentElement.remove()}>×</div>
                            </div>
                        )
                    else return (
                        <div key={idxToDo} className="item" onClick={() =>
                            setCheckList((lastCheckList) => ([...lastCheckList, idxToDo]))
                        }>
                            <div className="task">{toDo}</div>
                            <div className="close" onClick={(e) => e.target.parentElement.remove()}>×</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ToDoList