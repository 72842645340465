import { UserContext } from '../components/AppContext'
import Conversation from '../components/Conversation'
import Message from '../components/Message'
import ChatOnline from '../components/ChatOnline'
import { useContext, useEffect, useState, useRef } from 'react'
import '../styles/Chat.css'
import axios from "axios"
import { io } from "socket.io-client"

function Chat() {
    const userLogged = useContext(UserContext)
    const [conversations, setConversations] = useState([]);
    const [searchFriendFitler, setSearchFriendFilter] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const socket = useRef();
    const scrollRef = useRef();

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`);
        socket.current.on("getMessage", (data) => {
            setArrivalMessage({
                sender: data.senderId,
                text: data.text,
                createdAt: Date.now(),
            });
        });
        return () =>{
            socket.current.off("getMessage",()=>{
                
            })
        }
    }, []);

    useEffect(() => {
        arrivalMessage &&
            currentChat?.members.includes(arrivalMessage.sender) &&
            setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage, currentChat]);

    useEffect(() => {
        if (userLogged && userLogged.id && userLogged.followings) {
            socket.current.emit("addUser", userLogged.id);
            socket.current.on("getUsers", (users) => {
                setOnlineUsers(
                    userLogged.followings.filter((f) => users.some((u) => u.userId === f))
                );
            });
        }
    }, [userLogged]);

    useEffect(() => {
        const getConversations = async () => {
            try {
                if (userLogged && userLogged.id) {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/conversation/${userLogged.id}`);
                    setConversations(res.data);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getConversations();
    }, [userLogged]);

    useEffect(() => {
        const getMessages = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/message/${currentChat?._id}`);
                setMessages(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getMessages();
    }, [currentChat]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const message = {
            sender: userLogged.id,
            text: newMessage,
            conversationId: currentChat._id,
        };

        const receiverId = currentChat.members.find(
            (member) => member !== userLogged.id
        );

        socket.current.emit("sendMessage", {
            senderId: userLogged.id,
            receiverId,
            text: newMessage,
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/message`, message);
            setMessages([...messages, res.data]);
            setNewMessage("");
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <div id="Chat" className="tabcontent">
            <div className="chatMenu">
                <div className="chatMenuWrapper">
                    <input type="text" placeholder='Rechercher Personne' className='chatMenuInput' value={searchFriendFitler} onChange={(e) => setSearchFriendFilter(e.target.value)} />
                    {conversations.map((c, idxC) => (
                        <div className={"chat" + idxC} key={idxC} onClick={() => setCurrentChat(c)}>
                            <Conversation conversation={c} currentUser={userLogged} filterFriend={searchFriendFitler} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="chatBox">
                <div className="chatBoxWrapper">
                    {currentChat ? (
                        <>
                            <div className="chatBoxTop">
                                {messages.map((m, idxm) => (
                                    <div key={idxm} ref={scrollRef}>
                                        <Message message={m} own={m.sender === userLogged.id} senderId={m.sender} />
                                    </div>
                                ))}
                            </div>
                            <div className="chatBoxBottom">
                                <textarea className="chatMessageInput" placeholder='Ecrire quelque chose...' onChange={(e) => setNewMessage(e.target.value)}
                                    value={newMessage}></textarea>
                                <button className="chatSubmitButton" onClick={(e) => handleSubmit(e)}>Send</button>
                            </div>
                        </>
                    ) : (
                        <span className="noConversationText">
                            Ouvrir une conversation pour commencer le chat
                        </span>
                    )}
                </div>
            </div>
            <div className="chatOnline">
                <div className="chatOnlineWrapper">
                    <ChatOnline onlineUsers={onlineUsers}
                        currentId={userLogged?.id}
                        setCurrentChat={setCurrentChat} />
                </div>
            </div>
        </div>

    )
}

export default Chat
