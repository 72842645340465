import { useState, useEffect } from 'react'
import axios from 'axios'

function User() {
    const [page, setPage] = useState("ListUser")
    const [snackBarMessage, setSnackBarMessage] = useState('')
    const [user, setUser] = useState({ email: '', pseudo: '', password: '' })
    const [editName, setEditName] = useState('')
    const [newUser, setNewUser] = useState({ email: '', pseudo: '', password: '', passwordConfirm: '' })
    const [syncUser, setSync] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        const getUsers = async () => {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/api/user`,
                withCredentials: true
            }).then((res) => {
                setUsers(res.data)
            })
        }
        getUsers();
    }, [syncUser])

    const removeUser = async (userId) => {
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
            withCredentials: true
        }).then((res) => {
            setPage(null)
            setSync(!syncUser)
        })
    }

    const addNewUser = async (newUser) => {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/user/register`,
            withCredentials: true,
            data: newUser
        }).then((res) => {
            setPage(null)
            setSync(!syncUser)
        })
    }

    const updateUser = async (user) => {
        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/user/${user._id}`,
            withCredentials: true,
            data: user
        }).then((res) => {
            setPage(null)
            setSync(!syncUser)
        })
    }

    const updateConversation = async (userId) => {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/api/conversation/${userId}`,
            withCredentials: true
        }).then((res) => {
            if (res.data.message)
                setSnackBarMessage(res.data.message);
            let snackbar = document.getElementById("snackbar")
            snackbar.className = "show";
            setTimeout(function () { snackbar.className = snackbar.className.replace("show", ""); }, 3000);
        })
    }

    switch (page) {
        case "Register":
            return (
                <div id="User" className="tabcontent">
                    <div className="container">
                        <div className="card" style={{ overflowX: "auto" }}>
                            <div className="card-header">Nouvel Utilisateur</div>
                            <div className="card-body px-5" >
                                <div className="row">
                                    <label htmlFor="pseudo" className="col-form-label text-md-right">Pseudo</label>
                                    <input id="pseudo" type="text" className="form-control" name="pseudo" value={newUser.pseudo ? newUser.pseudo : ""} onChange={(e) => setNewUser({ ...newUser, pseudo: e.target.value })} required />
                                </div>
                                <div className="row">
                                    <label htmlFor="email" className="col-form-label text-md-right">Email</label>
                                    <input id="email" type="email" className="form-control" name="email" value={newUser.email ? newUser.email : ""} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} required autoComplete="email" />
                                </div>
                                <div className="row">
                                    <label htmlFor="password" className="col-form-label text-md-right">Mot de passe</label>
                                    <input id="password" type="password" className="form-control" name="password" value={newUser.password ? newUser.password : ""} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} required autoComplete="new-password" minLength="8" />
                                </div>
                                <div className="row">
                                    <label htmlFor="password-confirm" className="col-form-label text-md-right">Confirmer mot de passe</label>
                                    <input id="password-confirm" type="password" className="form-control" name="password_confirmation" value={newUser.passwordConfirm ? newUser.passwordConfirm : ""} onChange={(e) => setNewUser({ ...newUser, passwordConfirm: e.target.value })} required autoComplete="confirm-password" minLength="8" />
                                </div>
                                <div className="d-flex flex-row justify-content-center mt-3">
                                    <button type="submit" className="btn btn-success me-2" onClick={() => addNewUser(newUser)}>
                                        S'enregistrer
                                    </button>
                                    <button type="button" className="btn btn-secondary ms-2" onClick={() => setPage(null)}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case "Edit":
            return (
                <div id="User" className="tabcontent">
                    <div className="container">
                        <div className="card" style={{ overflowX: "auto" }}>
                            <div className="card-header">Utilisateur: <strong>{editName}</strong></div>
                            <div className="card-body px-5">
                                <div className="row">
                                    <label htmlFor="pseudo" className="col-form-label text-md-right">Pseudo</label>
                                    <input id="pseudo" name="pseudo" type="text" className="form-control" value={user.pseudo ? user.pseudo : ""} onChange={(e) => setUser({ ...user, pseudo: e.target.value })} required />
                                </div>
                                <div className="row">
                                    <label htmlFor="email" className="col-form-label text-md-right">Email</label>
                                    <input id="email" type="email" name="email" className="form-control" value={user.email ? user.email : ""} onChange={(e) => setUser({ ...user, email: e.target.value })} required />
                                </div>
                                <div className="row">
                                    <label htmlFor="role" className="col-form-label text-md-right">Role</label>
                                    <select id="role" name="role" className="form-control" value={user.role ? user.role : 0} onChange={(e) => setUser({ ...user, role: e.target.value })} >
                                        <option value="0"></option>
                                        <option value="1">Admin</option>
                                        <option value="2">User</option>
                                    </select>
                                </div>
                                <div className="row">
                                    <label htmlFor="follower" className="col-form-label text-md-right">S'abonner à</label>
                                    <select id="follower" name="follower" className="form-control" value={user.followers ? user.followers : []} onChange={(e) => setUser({ ...user, followers: Array.from(e.target.selectedOptions, option => option.value) })} multiple>
                                        <option value=""></option>
                                        {users.map((currentUser, idxUser) => {
                                            if (currentUser._id === user._id)
                                                return ""
                                            else
                                                return <option key={idxUser} value={currentUser._id}>{currentUser.pseudo}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="row">
                                    <label htmlFor="following" className="col-form-label text-md-right">Vos abonnés</label>
                                    <select id="following" name="following" className="form-control" value={user.followings ? user.followings : []} disabled multiple>
                                        <option value=""></option>
                                        {users.map((currentUser, idxUser) => {
                                            if (currentUser._id === user._id)
                                                return ""
                                            else
                                                return <option key={idxUser} value={currentUser._id}>{currentUser.pseudo}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="row">
                                    <label htmlFor="password" className="col-form-label text-md-right">Mot de passe</label>
                                    <input id="password" name="password" type="password" className="form-control" value={user.password ? user.password : ""} onChange={(e) => setUser({ ...user, password: e.target.value })} required />
                                </div>
                                <div className="d-flex flex-row justify-content-center mt-3">
                                    <button type="submit" className="btn btn-primary me-2" onClick={() => updateUser(user)}>
                                        Modifier
                                    </button>
                                    <button type="button" className="btn btn-secondary ms-2" onClick={() => setPage(null)}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
            return (
                <div id="User" className="tabcontent">
                    <div className="container">
                        <div className="row" style={{ overflowX: "auto" }}>
                            <div className="card-body px-5">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Pseudo</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((currentUser, idUser) => {
                                            return (
                                                <tr key={idUser}>
                                                    <td>{currentUser.pseudo}</td>
                                                    <td>{currentUser.email}</td>
                                                    <td>{currentUser.role === 1 ? (<>Admin</>) : (<>User</>)}</td>
                                                    <td className='d-flex justify-content-center'>
                                                        <button type="button" className="btn btn-primary me-2" onClick={() => {
                                                            setUser(currentUser)
                                                            setEditName(currentUser.pseudo)
                                                            setPage('Edit')
                                                        }
                                                        }>
                                                            Editer
                                                        </button>
                                                        <button type="submit" className="btn btn-danger ms-2" onClick={() => removeUser(currentUser._id)}>
                                                            Supprimer
                                                        </button>
                                                        <button type="submit" className="btn btn-secondary ms-2" onClick={() => updateConversation(currentUser._id)}>
                                                            Mettre à jour conversation
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={4}>
                                                <button className="btn btn-success ml-4" onClick={() => {
                                                    setPage('Register')
                                                    setNewUser({ email: '', pseudo: '', password: '', passwordConfirm: '' })
                                                }} style={{ marginLeft: "40%" }}>
                                                    Créer Utilisateur
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="snackbar">{snackBarMessage}</div>
                    </div>
                </div>
            )
    }
}

export default User
