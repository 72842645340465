import { useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5percent from "@amcharts/amcharts5/percent"
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import '../styles/AmChart.css'

function AmChart() {

    useEffect(() => {
        // Create root element
        let root = am5.Root.new("chart1");

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ])

        let data = [{
            player: "Joueur 1",
            number: 11,
            pictureSettings: {
                src: "https://www.amcharts.com/wp-content/uploads/2019/04/joey.jpg"
            }
        }, {
            player: "Joueur 2",
            number: 8,
            pictureSettings: {
                src: "https://www.amcharts.com/wp-content/uploads/2019/04/chandler.jpg"
            }
        }, {
            player: "Joueur 3",
            number: 7,
            pictureSettings: {
                src: "https://www.amcharts.com/wp-content/uploads/2019/04/ross.jpg"
            }
        }, {
            player: "Joueur 4",
            number: 5,
            pictureSettings: {
                src: "https://www.amcharts.com/wp-content/uploads/2019/04/rachel.jpg"
            }
        }, {
            player: "Joueur 5",
            number: 4,
            pictureSettings: {
                src: "https://www.amcharts.com/wp-content/uploads/2019/04/phoebe.jpg"
            }
        }]

        // Create chart
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                paddingBottom: 50,
                paddingTop: 40
            })
        );

        // Create axes
        let xRenderer = am5xy.AxisRendererX.new(root, {});
        xRenderer.grid.template.set("visible", false);
        xRenderer.labels.template.set("fill", am5.color(0xadbdb3))
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                paddingTop: 40,
                categoryField: "player",
                renderer: xRenderer
            })
        );
        let yRenderer = am5xy.AxisRendererY.new(root, {});
        yRenderer.grid.template.set("visible", false);
        yRenderer.labels.template.set("fill", am5.color(0xadbdb3))
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer
            })
        );

        // Add series
        let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "prize",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "number",
                categoryXField: "player",
                sequencedInterpolation: true,
                calculateAggregates: true,
                maskBullets: false,
                tooltip: am5.Tooltip.new(root, {
                    dy: -30,
                    pointerOrientation: "vertical",
                    labelText: "{valueY}"
                })
            })
        );
        series.columns.template.setAll({
            strokeOpacity: 0,
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusTL: 10,
            maxWidth: 50,
            fillOpacity: 0.8
        });
        let currentlyHovered;
        // Series template event 
        series.columns.template.events.on("pointerover", function (e) {
            handleHover(e.target.dataItem);
        });
        series.columns.template.events.on("pointerout", function (e) {
            handleOut();
        });
        function handleHover(dataItem) {
            if (dataItem && currentlyHovered !== dataItem) {
                handleOut();
                currentlyHovered = dataItem;
                let bullet = dataItem.bullets[0];
                bullet.animate({
                    key: "locationY",
                    to: 1,
                    duration: 600,
                    easing: am5.ease.out(am5.ease.cubic)
                });
            }
        }
        function handleOut() {
            if (currentlyHovered) {
                let bullet = currentlyHovered.bullets[0];
                bullet.animate({
                    key: "locationY",
                    to: 0,
                    duration: 600,
                    easing: am5.ease.out(am5.ease.cubic)
                });
            }
        }
        let circleTemplate = am5.Template.new({});
        series.bullets.push(function (root, series, dataItem) {
            let bulletContainer = am5.Container.new(root, {});
            bulletContainer.children.push(
                am5.Circle.new(
                    root,
                    {
                        radius: 34
                    },
                    circleTemplate
                )
            );

            let maskCircle = bulletContainer.children.push(
                am5.Circle.new(root, { radius: 27 })
            );

            // only containers can be masked, so we add image to another container
            let imageContainer = bulletContainer.children.push(
                am5.Container.new(root, {
                    mask: maskCircle
                })
            );

            imageContainer.children.push(
                am5.Picture.new(root, {
                    templateField: "pictureSettings",
                    centerX: am5.p50,
                    centerY: am5.p50,
                    width: 60,
                    height: 60
                })
            );

            return am5.Bullet.new(root, {
                locationY: 0,
                sprite: bulletContainer
            });
        });
        // heatrule
        series.set("heatRules", [
            {
                dataField: "valueY",
                min: am5.color(0xe5dc36),
                max: am5.color(0x5faa46),
                target: series.columns.template,
                key: "fill"
            },
            {
                dataField: "valueY",
                min: am5.color(0xe5dc36),
                max: am5.color(0x5faa46),
                target: circleTemplate,
                key: "fill"
            }
        ]);

        series.data.setAll(data);
        xAxis.data.setAll(data);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineX.set("visible", false);
        cursor.lineY.set("visible", false);

        cursor.events.on("cursormoved", function () {
            let dataItem = series.get("tooltip").dataItem;
            if (dataItem) {
                handleHover(dataItem);
            } else {
                handleOut();
            }
        });

        // Make stuff animate on load
        series.appear();
        chart.appear(1000, 100);

        return () => {
            root.dispose()
        }
    }, [])

    useEffect(() => {
        let root2 = am5.Root.new("chart2");

        // Set themes
        root2.setThemes([
            am5themes_Animated.new(root2)
        ]);

        // Create chart
        let chart = root2.container.children.push(
            am5percent.PieChart.new(root2, {
                startAngle: 160, endAngle: 380
            })
        );

        // Create series
        let series0 = chart.series.push(
            am5percent.PieSeries.new(root2, {
                valueField: "litres",
                categoryField: "country",
                startAngle: 160,
                endAngle: 380,
                radius: am5.percent(70),
                innerRadius: am5.percent(65)
            })
        );
        let colorSet = am5.ColorSet.new(root2, {
            colors: [series0.get("colors").getIndex(0)],
            passOptions: {
                lightness: -0.05,
                hue: 0
            }
        });
        series0.set("colors", colorSet);
        series0.ticks.template.set("forceHidden", true);
        series0.labels.template.set("forceHidden", true);
        let series1 = chart.series.push(
            am5percent.PieSeries.new(root2, {
                startAngle: 160,
                endAngle: 380,
                valueField: "bottles",
                innerRadius: am5.percent(80),
                categoryField: "country"
            })
        );
        series1.ticks.template.set("forceHidden", true);
        series1.labels.template.set("forceHidden", true);
        chart.seriesContainer.children.push(
            am5.Label.new(root2, {
                textAlign: "center",
                centerY: am5.p100,
                centerX: am5.p50,
                text: "[fontSize:18px]Total[/]:\n[bold fontSize:30px]1647.9[/]"
            })
        );

        let data = [
            {
                country: "Lithuania",
                litres: 501.9,
                bottles: 1500
            },
            {
                country: "Czech Republic",
                litres: 301.9,
                bottles: 990
            },
            {
                country: "Ireland",
                litres: 201.1,
                bottles: 785
            },
            {
                country: "Germany",
                litres: 165.8,
                bottles: 255
            },
            {
                country: "Australia",
                litres: 139.9,
                bottles: 452
            },
            {
                country: "Austria",
                litres: 128.3,
                bottles: 332
            },
            {
                country: "UK",
                litres: 99,
                bottles: 150
            },
            {
                country: "Belgium",
                litres: 60,
                bottles: 178
            },
            {
                country: "The Netherlands",
                litres: 50,
                bottles: 50
            }
        ];

        // Set data
        series0.data.setAll(data);
        series1.data.setAll(data);
        return () => {
            root2.dispose()
        }
    }, [])

    useEffect(() => {
        let root3 = am5.Root.new("chart3");

        // Set themes
        root3.setThemes([
            am5themes_Animated.new(root3)
        ]);

        // Create wrapper container
        let container = root3.container.children.push(am5.Container.new(root3, {
            width: am5.percent(100),
            height: am5.percent(80),
            layout: root3.verticalLayout
        }));

        // Create series
        let series = container.children.push(am5hierarchy.Tree.new(root3, {
            singleBranchOnly: false,
            downDepth: 1,
            initialDepth: 10,
            valueField: "value",
            categoryField: "name",
            childDataField: "children"
        }));

        // Generate and set data
        let maxLevels = 2;
        let maxNodes = 2;
        let maxValue = 100;

        let data = {
            name: "Start",
            children: []
        }
        generateLevel(data, "", 0);

        series.data.setAll([data]);
        series.set("selectedDataItem", series.dataItems[0]);

        function generateLevel(data, name, level) {
            for (let i = 0; i < Math.ceil(maxNodes * Math.random()) + 1; i++) {
                let nodeName = "Level ";
                let child;
                if (level < maxLevels) {
                    child = {
                        name: nodeName + (level + 1)
                    }

                    if (level > 0 && Math.random() < 0.5) {
                        child.value = Math.round(Math.random() * maxValue);
                    }
                    else {
                        child.children = [];
                        generateLevel(child, nodeName, level + 1)
                    }
                }
                else {
                    child = {
                        name: "End " + i,
                        value: Math.round(Math.random() * maxValue)
                    }
                }
                data.children.push(child);
            }

            level++;
            return data;
        }

        // Make stuff animate on load
        series.appear(1000, 100);
        return () => {
            root3.dispose()
        }
    }, [])

    useEffect(() => {
        let root4 = am5.Root.new("chart4");

        // Set themes
        root4.setThemes([
            am5themes_Animated.new(root4)
        ]);

        // Create chart
        let chart = root4.container.children.push(am5xy.XYChart.new(root4, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingBottom: 50
        }));

        chart.get("colors").set("step", 3);

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root4, {}));
        cursor.lineY.set("visible", false);

        // Create axes
        let xRenderer = am5xy.AxisRendererX.new(root4, {})
        xRenderer.labels.template.set("fill", am5.color(0xadbdb3))
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root4, {
            maxDeviation: 0.3,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root4, {})
        }));

        let yRenderer = am5xy.AxisRendererY.new(root4, {})
        yRenderer.labels.template.set("fill", am5.color(0xadbdb3))
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root4, {
            maxDeviation: 0.3,
            renderer: yRenderer
        }));

        // Add series
        let series = chart.series.push(am5xy.LineSeries.new(root4, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value1",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root4, {
                labelText: "{valueX}: {valueY}\n{previousDate}: {value2}"
            })
        }));
        series.strokes.template.setAll({
            strokeWidth: 2
        });
        series.get("tooltip").get("background").set("fillOpacity", 0.5);
        let series2 = chart.series.push(am5xy.LineSeries.new(root4, {
            name: "Series 2",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value2",
            valueXField: "date"
        }));
        series2.strokes.template.setAll({
            strokeDasharray: [2, 2],
            strokeWidth: 2
        });

        // Set date fields
        root4.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
        });

        // Set data
        let data = [{
            date: new Date(2019, 5, 12).getTime(),
            value1: 50,
            value2: 48,
            previousDate: new Date(2019, 5, 5)
        }, {
            date: new Date(2019, 5, 13).getTime(),
            value1: 53,
            value2: 51,
            previousDate: "2019-05-06"
        }, {
            date: new Date(2019, 5, 14).getTime(),
            value1: 56,
            value2: 58,
            previousDate: "2019-05-07"
        }, {
            date: new Date(2019, 5, 15).getTime(),
            value1: 52,
            value2: 53,
            previousDate: "2019-05-08"
        }, {
            date: new Date(2019, 5, 16).getTime(),
            value1: 48,
            value2: 44,
            previousDate: "2019-05-09"
        }, {
            date: new Date(2019, 5, 17).getTime(),
            value1: 47,
            value2: 42,
            previousDate: "2019-05-10"
        }, {
            date: new Date(2019, 5, 18).getTime(),
            value1: 59,
            value2: 55,
            previousDate: "2019-05-11"
        }]

        series.data.setAll(data);
        series2.data.setAll(data);

        // Make stuff animate on load
        series.appear(1000);
        series2.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root4.dispose()
        }
    }, [])


    return (
        <div className='amchart-container'>
            <div className="demo-name">Vos graphiques personalisés selon vos besoins</div>
            <Splide className="amchart" options={{ perPage: 1 }}>
                <SplideSlide>
                    <div className="chart-title">Graphique en colonne avec bulles</div>
                    <div id="chart1"></div>
                </SplideSlide>
                <SplideSlide>
                    <div className="chart-title">Graphique en anneau</div>
                    <div id="chart2"></div>
                </SplideSlide>
                <SplideSlide>
                    <div className="chart-title">Graphique en arbre</div>
                    <div id="chart3"></div>
                </SplideSlide>
                <SplideSlide>
                    <div className="chart-title">Graphique similaire à Google Analytics</div>
                    <div id="chart4"></div>
                </SplideSlide>
            </Splide>
        </div>
    )
}

export default AmChart