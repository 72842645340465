import React from 'react';
import $ from "jquery";
import { rrulestr } from 'rrule';
import Draggable from 'react-draggable';
// import Modal from 'react-modal';
import { StateContext } from './AppContext';
// import '../../assets/Cell.css';

export default class EventsSquare extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            cellDate: props.event.eventDay,
            cellDropDate: props.event.eventDay,
            showModal: false,
            showModal2: false,
            showModal3: false,
            boundTop: 0,
            boundBottom: 0,
            boundLeft: 0,
            boundRight: 0,
            diffDate: 0,
            activeDrags: 0,
            controlledPosition: {
                x: 0, y: 0
            },
            checkedRecurrency: "1",
            updateEvent: {}
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleOpenModal3 = this.handleOpenModal3.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
        this.handleCloseModal3 = this.handleCloseModal3.bind(this);
        this.handleCancelModal = this.handleCancelModal.bind(this);
        this.handleCancelModal3 = this.handleCancelModal3.bind(this);
        this.recurrencyEvent = this.recurrencyEvent.bind(this);
    }

    recurrencyEvent(e) {
        this.setState({
            checkedRecurrency: e.target.value
        });
    }

    handleOpenModal() {
        this.setState({
            showModal: true,
            showModal2: false,
            showModal3: false
        });
    }

    handleOpenModal2() {
        this.setState({
            showModal: false,
            showModal2: true,
            showModal3: false
        });
    }

    handleOpenModal3(eventDataObj) {
        console.log('event handler', eventDataObj);
        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: true,
            updateEvent: eventDataObj
        });
    }

    handleCloseModal = async () => {
        const { event } = this.props;
        const { formatTzDate, month, year, getStartEndTimeMonth, pullMonthEvents, formatDate } = this.context;
        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: false
        });
        let getHour = new Date(event.startDateTime).getHours();
        let getMinute = new Date(event.startDateTime).getMinutes();
        let startHour = ("0" + getHour.toString()).slice(-2) + ":" + ("0" + getMinute.toString()).slice(-2)
        let excludeDate = new Date(event.eventDay + " " + startHour);
        let excludeTzDate = formatTzDate(excludeDate);
        let strDate = excludeTzDate;
        const regexDate = RegExp(strDate);
        const regexExDate = RegExp('EXDATE');
        let newEventRecurringRule = "";
        if (regexExDate.test(event.recurringRule)) {
            if (!regexDate.test(event.recurringRule)) {
                newEventRecurringRule = event.recurringRule + "," + excludeTzDate;
            }
        } else {
            newEventRecurringRule = event.recurringRule + "\nEXDATE:" + excludeTzDate;
        }

        let eventDataObj = {
            id: event.id,
            title: event.title,
            allDay: event.allDay,
            startDateTime: event.startDateTime,
            endDateTime: event.endDateTime,
            recurrencyRule: newEventRecurringRule,
            location: event.location,
            description: event.description,
        };
        $.ajax({
            url: "../saveEvent.cfm",
            method: "POST",
            async: false,
            data: eventDataObj,
            success: function () {
                console.log('update exclude date recurring event' + event.id, eventDataObj);
            }
        });
        let rangeDateTime = getStartEndTimeMonth(month, year);
        let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
        let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
        await pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
    }

    handleCloseModal2 = async () => {
        const { month, year, getStartEndTimeMonth, pullMonthEvents, formatDate } = this.context;
        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: false
        });
        let rangeDateTime = getStartEndTimeMonth(month, year);
        let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
        let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
        await pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
    }

    handleCloseModal3 = async () => {
        const { event } = this.props;
        const { title, allDay, startDateTime, endDateTime, location, description, parentId, recurrencyRule } = this.state.updateEvent;
        const { month, year, getStartEndTimeMonth, pullMonthEvents, formatDate, formatTzDate } = this.context;
        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: false
        });
        if (this.state.checkedRecurrency === "1") {
            let excludeDate = new Date(event.eventDaysetHourMin);
            let excludeTzDate = formatTzDate(excludeDate);
            let strDate = excludeTzDate;
            const regexDate = RegExp(strDate);
            const regexExDate = RegExp('EXDATE');
            let newEventRecurringRule = "";
            if (regexExDate.test(event.recurringRule)) {
                if (!regexDate.test(event.recurringRule)) {
                    newEventRecurringRule = event.recurringRule + "," + excludeTzDate;
                }
            } else {
                newEventRecurringRule = event.recurringRule + "\nEXDATE:" + excludeTzDate;
            }
            console.log('new recurring rule:', newEventRecurringRule);
            let eventDataObj = {
                id: event.id,
                title: event.title,
                allDay: event.allDay,
                startDateTime: event.startDateTime,
                endDateTime: event.endDateTime,
                recurrencyRule: newEventRecurringRule,
                location: event.location,
                description: event.description,
                parentId: event.parentId
            };
            console.log('title new Event', title, startDateTime, endDateTime);
            let newEventDataObj = {
                title: title,
                allDay: allDay,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrencyRule: "",
                location: location,
                description: description,
                parentId: parentId
            };
            $.ajax({
                url: "../saveEvent.cfm",
                method: "POST",
                async: false,
                data: eventDataObj,
                success: function () {
                    console.log('update recurring event' + event.id, eventDataObj);
                }
            });
            $.ajax({
                url: "../saveEvent.cfm",
                method: "POST",
                async: false,
                data: newEventDataObj,
                success: function () {
                    console.log('This event', newEventDataObj);
                }
            });

        } else if (this.state.checkedRecurrency === "2") {
            let untilDate = new Date(event.eventDay);
            let eventYear = untilDate.getUTCFullYear().toString();
            let eventMonth = untilDate.getUTCMonth() + 1;
            let eventDay = untilDate.getUTCDate().toString();
            eventMonth = ("0" + eventMonth.toString()).slice(-2);
            eventDay = ("0" + eventDay.toString()).slice(-2);
            let strDate = eventYear + eventMonth + eventDay;
            const regexDate = RegExp(strDate);
            const regexExDate = RegExp('EXDATE');
            let regexUntilDateVal = RegExp(';UNTIL=(.+?)*');
            let newEventRecurringRule = "";
            let untilTzDate = formatTzDate(untilDate);
            if (regexExDate.test(event.recurringRule)) {
                if (!regexDate.test(event.recurringRule)) {
                    let regexExDateVal = RegExp('\nEXDATE:(.+?)*');
                    let strExDate = regexExDateVal.exec(event.recurringRule)[0];
                    newEventRecurringRule = event.recurringRule.replace(regexExDateVal, '');
                    if (regexUntilDateVal.test(event.recurringRule)) {
                        newEventRecurringRule = newEventRecurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilTzDate + strExDate;
                    } else {
                        newEventRecurringRule = newEventRecurringRule + ";UNTIL=" + untilTzDate + strExDate;
                    }
                }
            } else {
                if (regexUntilDateVal.test(event.recurringRule)) {
                    newEventRecurringRule = event.recurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilTzDate;
                } else {
                    newEventRecurringRule = event.recurringRule + ";UNTIL=" + untilTzDate;
                }
            }
            if (event.parentId) {
                let startDate = new Date(event.startDateTime);
                let dtStart = formatTzDate(startDate);
                let stringRule = event.recurringRule + "\nDTSTART:" + dtStart;
                const rule = rrulestr(stringRule);
                const allRecurrency = rule.between(new Date(formatDate(new Date(event.startDateTime)) + " 00:00"), new Date(formatDate(startDateTime) + " 23:59"));
                if (allRecurrency.length <= 1) {
                    let eventDataObj = {
                        id: event.id,
                        title: title,
                        allDay: allDay,
                        startDateTime: startDateTime,
                        endDateTime: endDateTime,
                        recurrencyRule: recurrencyRule,
                        location: location,
                        description: description,
                        parentId: parentId
                    };
                    $.ajax({
                        url: "../saveEvent.cfm",
                        method: "POST",
                        async: false,
                        data: eventDataObj,
                        success: function () {
                            console.log('success update event' + event.id, eventDataObj);
                        }
                    });
                } else {
                    const dateCellUntil = new Date(allRecurrency[allRecurrency.length - 1]);
                    let regexUntilDateVal = RegExp(';UNTIL=(.+?)*');
                    let untilCellTzDate = formatTzDate(new Date(formatDate(new Date(dateCellUntil)) + " 00:00"));
                    if (regexExDate.test(event.recurringRule)) {
                        if (!regexDate.test(event.recurringRule)) {
                            let regexExDateVal = RegExp('\nEXDATE:(.+?)*');
                            let strExDate = regexExDateVal.exec(event.recurringRule)[0];
                            newEventRecurringRule = event.recurringRule.replace(regexExDateVal, '');
                            if (regexUntilDateVal.test(event.recurringRule)) {
                                newEventRecurringRule = newEventRecurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilCellTzDate + strExDate;
                            } else {
                                newEventRecurringRule = newEventRecurringRule + ";UNTIL=" + untilCellTzDate + strExDate;
                            }
                        }
                    } else {
                        if (regexUntilDateVal.test(event.recurringRule)) {
                            newEventRecurringRule = event.recurringRule.replace(regexUntilDateVal, '') + ";UNTIL=" + untilCellTzDate;
                        } else {
                            newEventRecurringRule = event.recurringRule + ";UNTIL=" + untilCellTzDate;
                        }
                    }

                    let eventDataObj = {
                        id: event.id,
                        title: event.title,
                        allDay: event.allDay,
                        startDateTime: event.startDateTime,
                        endDateTime: event.endDateTime,
                        recurrencyRule: newEventRecurringRule,
                        location: event.location,
                        description: event.description,
                        parentId: event.parentId
                    };
                    let newEventDataObj = {
                        title: title,
                        allDay: allDay,
                        startDateTime: startDateTime,
                        endDateTime: endDateTime,
                        recurrencyRule: event.recurringRule,
                        location: event.location,
                        description: event.description,
                        parentId: event.id
                    };
                    $.ajax({
                        url: "../saveEvent.cfm",
                        method: "POST",
                        async: false,
                        data: eventDataObj,
                        success: function () {
                            console.log('succes update event' + event.id, eventDataObj);
                        }
                    });
                    $.ajax({
                        url: "../saveEvent.cfm",
                        method: "POST",
                        data: newEventDataObj,
                        async: false,
                        success: (data) => {
                            let eventData = JSON.parse(data);
                            console.log('succes new event' + eventData.id, eventData);
                            this.setState({
                                currId: eventData.id
                            });
                        }
                    });
                }
            } else {
                let eventDataObj = {
                    id: event.id,
                    title: event.title,
                    allDay: event.allDay,
                    startDateTime: event.startDateTime,
                    endDateTime: event.endDateTime,
                    recurrencyRule: newEventRecurringRule,
                    location: event.location,
                    description: event.description,
                    parentId: event.parentId
                };
                let newEventDataObj = {
                    title: title,
                    allDay: allDay,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    recurrencyRule: recurrencyRule,
                    location: location,
                    description: description,
                    parentId: event.id
                };
                $.ajax({
                    url: "../saveEvent.cfm",
                    method: "POST",
                    async: false,
                    data: eventDataObj,
                    success: function () {
                        console.log('No parent Id ,succes update event' + event.id, eventDataObj);
                    }
                });
                $.ajax({
                    url: "../saveEvent.cfm",
                    method: "POST",
                    async: false,
                    data: newEventDataObj,
                    success: (data) => {
                        let eventData = JSON.parse(data);
                        console.log('No parent Id ,succes new event' + eventData.id, eventData);
                    }
                });
            }

        } else if (this.state.checkedRecurrency === "3") {
            $.ajax({
                url: "../deleteParentEvent.cfm",
                method: "POST",
                async: false,
                data: {
                    parentId: event.id
                },
                success: function () {
                    console.log('succes remove event with parentId:' + event.id + "without recurring rule");
                }
            });
            let eventDataObj = {
                id: event.id,
                title: title,
                allDay: allDay,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                recurrencyRule: recurrencyRule,
                location: location,
                description: description,
                parentId: parentId
            };
            $.ajax({
                url: "../saveEvent.cfm",
                method: "POST",
                async: false,
                data: eventDataObj,
                success: function () {
                    console.log('succes update all events id' + event.id, eventDataObj);
                }
            });
        }
        let rangeDateTime = getStartEndTimeMonth(month, year);
        let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
        let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
        await pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
    }

    handleCancelModal() {
        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: false,
            controlledPosition: {
                x: 0, y: 0
            },
            cellDropDate: this.props.event.eventDay,
            cellDate: this.props.event.eventDay,
            diffDate: 0
        });
    }

    handleCancelModal3() {
        this.setState({
            showModal: false,
            showModal2: true,
            showModal3: false,
            updateEvent: {}
        });
    }

    onControlledDrag = (e, post) => {
        // x +- 1 = +-1day, y +- 1= +-7day
        const { x, y } = post;
        const { numero, nbEventBorder } = this.props;
        let transX = Math.round(x / 156) * 156;

        let origY = -29 - nbEventBorder * 21 - (numero - nbEventBorder) * 40;
        let upCell = 0;
        let downCell = 0;
        if (y >= 0) {
            upCell = parseInt((y + 29) / 156);
        } else if (y < 0) {
            downCell = (y < -29) ? parseInt((y - origY) / 156) - 1 : 0;
        }
        let transY = origY + (upCell * 156) + (downCell * 156) + 29;
        let diffDate = Math.round(x / 156) + (upCell + downCell) * 7;

        this.setState({
            controlledPosition: { x: transX, y: transY },
            diffDate
        });
    }

    onStart = () => {
        let activeDrags = this.state.activeDrags + 1;
        this.setState({ activeDrags });
    }

    onStop = () => {
        const { event, position } = this.props;
        const { formatTzDate, arrEventsToDisplay, month, year, getStartEndTimeMonth, pullMonthEvents, formatDate } = this.context;
        let dropCell = this.state.diffDate + position;
        if (this.state.diffDate !== 0) {
            if (event.recurringRule) {
                if (typeof arrEventsToDisplay[dropCell] !== 'undefined') {
                    if (arrEventsToDisplay[dropCell].events.length) {
                        arrEventsToDisplay[dropCell].events.forEach(ev => {
                            if (event.id === ev.id) {
                                this.setState({ showModal: true, cellDropDate: arrEventsToDisplay[dropCell].date });
                            }
                        });
                    } else {
                        let getHour = new Date(event.startDateTime).getHours();
                        let getMinute = new Date(event.startDateTime).getMinutes();
                        let startHour = ("0" + getHour.toString()).slice(-2) + ":" + ("0" + getMinute.toString()).slice(-2);
                        let excludeDate = new Date(event.eventDay + " " + startHour);
                        let excludeTzDate = formatTzDate(excludeDate);
                        let strDate = excludeTzDate;
                        const regexDate = RegExp(strDate);
                        const regexExDate = RegExp('EXDATE');
                        let newEventRecurringRule = "";
                        if (regexExDate.test(event.recurringRule)) {
                            if (!regexDate.test(event.recurringRule)) {
                                newEventRecurringRule = event.recurringRule + "," + excludeTzDate;
                            }
                        } else {
                            newEventRecurringRule = event.recurringRule + "\nEXDATE:" + excludeTzDate;
                        }

                        let eventDataObj = {
                            id: event.id,
                            title: event.title,
                            allDay: event.allDay,
                            startDateTime: event.startDateTime,
                            endDateTime: event.endDateTime,
                            recurrencyRule: newEventRecurringRule,
                            location: event.location,
                            description: event.description,
                            parentId: event.parentId
                        };
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            async: false,
                            data: eventDataObj,
                            success: function () {
                                console.log('update exclude date recurring event' + event.id, eventDataObj);
                            }
                        });
                        let tempStartDateTime = new Date(event.startDateTime);
                        let tempEndDateTime = (event.endDateTime) ? new Date(event.endDateTime) : '';
                        tempStartDateTime.setDate(tempStartDateTime.getDate() + this.state.diffDate);
                        if (event.endDateTime) {
                            tempEndDateTime.setDate(tempEndDateTime.getDate() + this.state.diffDate);
                        }

                        let newEventDataObj = {
                            title: event.title,
                            allDay: event.allDay,
                            startDateTime: tempStartDateTime,
                            endDateTime: tempEndDateTime,
                            recurrencyRule: '',
                            location: event.location,
                            description: event.description,
                            parentId: event.parentId
                        }
                        $.ajax({
                            url: "../saveEvent.cfm",
                            method: "POST",
                            async: false,
                            data: newEventDataObj,
                            success: (data) => {
                                let eventData = JSON.parse(data);
                                console.log('new event' + eventData.id, eventData);
                            }
                        });
                        let rangeDateTime = getStartEndTimeMonth(month, year);
                        let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
                        let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
                        pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
                    }
                }
            } else {
                let newStartDateTime = new Date(event.startDateTime);
                let newEndDateTime = new Date(event.endDateTime);
                newStartDateTime.setDate(newStartDateTime.getDate() + this.state.diffDate);
                newEndDateTime.setDate(newEndDateTime.getDate() + this.state.diffDate);
                let eventDataObj = {
                    id: event.id,
                    title: event.title,
                    allDay: event.allDay,
                    startDateTime: newStartDateTime,
                    endDateTime: newEndDateTime,
                    recurrencyRule: event.recurringRule,
                    location: event.location,
                    description: event.description,
                    parentId: event.parentId
                };
                $.ajax({
                    url: "../saveEvent.cfm",
                    method: "POST",
                    async: false,
                    data: eventDataObj,
                    success: function () {
                        console.log('update recurring event' + event.id, eventDataObj);
                    }
                });
                let rangeDateTime = getStartEndTimeMonth(month, year);
                let formatStartMonth = formatDate(rangeDateTime.startDateTime) + " 00:00";
                let formatEndMonth = formatDate(rangeDateTime.endDateTime) + " 23:59";
                pullMonthEvents(formatStartMonth, formatEndMonth, month, year);
            }
        }
    }

    componentDidMount() {
        const { position, nbEventBorder, numero, nbCell } = this.props;
        let nbRow = parseInt(nbCell / 7) - 1;
        let rankEventSquare = numero - nbEventBorder + 1;
        let bdLeft = -8 - (156 * (position % 7));
        let bdRight = 8 + (156 * (6 - (position % 7)));
        let bdTop = -29 - 156 * parseInt(position / 7) - nbEventBorder * 21 - (numero - nbEventBorder) * 40;
        let bdBottom = 156 * (nbRow - parseInt(position / 7)) + (127 - rankEventSquare * 40 - nbEventBorder * 21);
        this.setState({
            boundTop: bdTop,
            boundBottom: bdBottom,
            boundLeft: bdLeft,
            boundRight: bdRight
        });
    }

    render() {
        const { event } = this.props;
        const { controlledPosition } = this.state;
        const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
        let displayFinal = ''
        if (typeof event.eventDaysetHourMin !== 'undefined') {
            let time = event.eventDaysetHourMin.split(' ')[1];
            let hour = Number(time.split(':')[0]);
            let min = Number(time.split(':')[1]);
            let amOrPm = (hour > 11) ? 'pm' : 'am';
            hour = hour % 12;
            let displayTime = ("0" + hour.toString()).slice(-2) + ":" + ("0" + min.toString()).slice(-2) + amOrPm + " " + event.title;
            displayFinal = (event.location) ? displayTime + " - " + event.location : displayTime;
        }

        return (
            <React.Fragment>
                {/* <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Event"
                    style={customStyles}
                    onRequestClose={this.handleCancelModal}
                >
                    <button type="button" class="close" aria-label="Close" onClick={this.handleCancelModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="text-center">
                        There is already a same event at the  "{this.state.cellDropDate}" Date so you can't perform that action. Do you want instead to remove the event from the "{this.state.cellDate}" Date?<br />

                        <button onClick={this.handleCloseModal} className="btn btn-primary mr-2">Ok</button>
                        <button onClick={this.handleCancelModal} className="btn btn-primary ml-2">Cancel</button>
                    </div>
                </Modal>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal3}
                    contentLabel="Recurrency Event"
                    style={customStyles3}
                    onRequestClose={this.handleCancelModal3}
                >
                    <button type="button" class="close" aria-label="Close" onClick={this.handleCancelModal3}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="custom-control custom-radio">
                        <input type="radio" id="1" name="recurrencyEvent" value="1" checked={this.state.checkedRecurrency === "1"} onChange={this.recurrencyEvent} />
                        <label htmlFor="1"> &nbsp; This event </label><br />

                        <input type="radio" id="2" name="recurrencyEvent" value="2" checked={this.state.checkedRecurrency === "2"} onChange={this.recurrencyEvent} />
                        <label htmlFor="2"> &nbsp; This and following events </label><br />

                        <input type="radio" id="3" name="recurrencyEvent" value="3" checked={this.state.checkedRecurrency === "3"} onChange={this.recurrencyEvent} />
                        <label htmlFor="3"> &nbsp; All events </label><br />

                        <button onClick={this.handleCloseModal3} className="btn btn-primary mr-2">Ok</button>
                        <button onClick={this.handleCancelModal3} className="btn btn-primary ml-2">Cancel</button>
                    </div>
                </Modal>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.showModal2}
                    contentLabel="Event"
                    style={customStyles2}
                    onRequestClose={this.handleCancelModal}
                >
                    <button type="button" class="close" aria-label="Close" onClick={this.handleCancelModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Event
                        id={event.id}

                        startDateTime={new Date(startDate)}

                        endDateTime={endDate}

                        edit={(event.recurringRule) ? "recurringRule" : ""}

                        frequencyMonthThirdLevelMonthWeekDaysRanksName="monthWeekDaysRanks"

                        frequencyMonthThirdLevelWeekDayCheckboxName="weekDayFrequencyMonthThirdLevel"

                        freqYr2ndLevSlctBxMthName="slctBxMthYr"

                        freqYr2ndLevChkBxActivateMthDayName="chkBxActivateMthDayName"

                        freqYr2ndLevSlctBxMthDayName="slctBxMthDayName"

                        freqYr2ndLevChkBxActivateWkDayInMthYrRuleName="chkBxActivateWkDayInMthYrRuleName"

                        freqYr2ndLevSlctBxWkDayRkInMthYrRuleName="slctBxWkDayRkInMthYrRuleName"

                        freqYr2ndLevWkDaysInMthYrRuleName="weekDayInMthYrRule"

                        freqYr2ndLevSlctBxWkYrName="slctBxWkYr"

                        freqYr2ndLevWkDaysInWkYrRuleName="weekDayInWkYrRule"

                        freqYr2ndLevSlctBxDayYrName="slctBxDayYr"

                        handleCloseModal2={this.handleCloseModal2}

                        handleCancelModal2={this.handleCancelModal}

                        handleOpenModal3={this.handleOpenModal3}
                    />

                </Modal> */}
                <Draggable
                    position={controlledPosition}
                    bounds={{ top: this.state.boundTop, bottom: this.state.boundBottom, left: this.state.boundLeft, right: this.state.boundRight }}
                    {...dragHandlers}
                    onDrag={this.onControlledDrag}
                >
                    <div onDoubleClick={this.handleOpenModal2} >
                        <ul className="squareSpace mb-0">
                            <li className="cellList">
                                <span className="squareSpan" id={"event_" + event.id}>{displayFinal} </span>
                            </li>
                        </ul>
                    </div>
                </Draggable>
            </React.Fragment>
        )
    }
}