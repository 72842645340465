import React from 'react';

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleClick(e);
    }

    render() {
        return (
            <button className="btn btn-primary mx-2" onClick={this.handleClick}>{this.props.label}</button>
        );
    }
}