import { MONTHNAMES, MONTHNAMESFR } from '../Constants';

function DateWeekDays({ startDate }) {
    //get premier jour de la semaine du startdate
    let startDayWeek = startDate.getDate();
    let month = MONTHNAMES[startDate.getMonth()];
    let year = startDate.getFullYear();
    let startDateToDate = new Date(month + ' ' + startDayWeek + ', ' + year);
    let previousDay = new Date(startDateToDate);
    const allDays = ['', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];


    const datedays = allDays.map((day) => {
        let setDay = new Date(previousDay);
        let setMonth = MONTHNAMESFR[setDay.getMonth()];
        let currentDay = String(setDay.getDate()).padStart(2, '0');
        if (day === '') {
            return <td key="dayhour" className="border"></td>
        } else {
            previousDay.setDate(previousDay.getDate() + 1);
            return (
                <td key={day.toString()} className="border" >
                    {day}<br /> {currentDay} {setMonth}
                </td>
            );
        }
    });

    return (
        <tr className="border text-center text-nowrap" style={{ backgroundColor: '#504c4c', color: 'white' }}>
            {datedays}
        </tr>
    );
}

export default DateWeekDays