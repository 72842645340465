import "../styles/Footer.css"

function Footer() {
    return (
        <footer className="footer py-3 mt-4 text-muted text-center">
            <div className='text-first p-2'>
                <div>LANZOcode SARL Ankadikely Ilafy Capital 2.500.000MGA</div>
                <div><i className="fas fa-phone fa-rotate-90" />&nbsp; <i style={{ color: '#6c757d', fontStyle: 'normal' }}>+261 34 85 450 42</i> &nbsp;</div>
                <div><i className="fas fa-envelope" />&nbsp; <a href="mailto:contact@lanzocode.com">contact@lanzocode.com</a> &nbsp;</div>
                <div><i className="fas fa-globe" />&nbsp; <a href="#Accueil">www.lanzocode.com</a> &nbsp;</div>
            </div>
            <div className='text-start p-2'>
                <div>NIF : 10111703209</div>
                <div>STAT : 62011 11 2022 0 10255 </div>
                <div>RCS : 2022B00246</div>
            </div>
            <div className='text-start p-2'>
                <div><a href="/cgv" target="_blank">Conditions Générales de Vente</a></div>
                <div><a href="/pdc" target="_blank">Politique de confidentialité</a></div>
            </div>
            <div className='text-start p-2'>
                <div className='copyright'><i className="fas fa-copyright" />&nbsp; Proudly powered by LANZOcode 2022 <a target="_blank" className="ms-3" rel="noreferrer" href="https://facebook.com/LANZOcode"><i className="fab fa-facebook footer-facebook" /></a></div>
            </div>
        </footer>
    )
}

export default Footer