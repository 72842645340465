
import { useContext } from "react";
import { MONTHNAMES, MONTHNAMESFR } from "../Constants";
import { StateContext } from "./AppContext";
import { startOfWeek } from "date-fns";

function ViewTitle() {
    const stateCont = useContext(StateContext)
    if (stateCont.selectedView === "Day") {
        return (
            <span className="text-view-title">{stateCont.day + ' ' + MONTHNAMESFR[stateCont.month] + ' ' + stateCont.year}</span>
        );
    } else if (stateCont.selectedView === "Week") {
        let date = new Date(MONTHNAMES[stateCont.month] + ' ' + stateCont.day + ', ' + stateCont.year);
        let startDate = startOfWeek(date);
        startDate.setDate(startDate.getDate() + 1);
        let startDay = String(startDate.getDate()).padStart(2, '0');
        let startMonth = MONTHNAMESFR[startDate.getMonth()];
        let startYear = startDate.getFullYear();
        let endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        let endDay = String(endDate.getDate()).padStart(2, '0');
        let endMonth = MONTHNAMESFR[endDate.getMonth()];
        let endYear = endDate.getFullYear();
        return (
            <span className="text-view-title mb-0">{parseInt(startDay) + ' ' + startMonth + ' ' + startYear} - {parseInt(endDay) + ' ' + endMonth + ' ' + endYear}</span>
        );
    } else if (stateCont.selectedView === "Month") {
        return (
            <span className="text-view-title mb-0">{MONTHNAMESFR[stateCont.month] + ' ' + stateCont.year}</span>
        );
    } else if (stateCont.selectedView === "Year") {
        return (
            <span className="text-view-title mb-0">{stateCont.year}</span>
        );
    }
}
export default ViewTitle;
