import React from 'react';
import RadioButton from './RadioButton';
import InputDate from './InputDate';
import InputText from './InputText';

export default class BlockUntilDate extends React.Component {

    constructor(props) {
        super(props);

        this.untilDateHandlerChange = this.untilDateHandlerChange.bind(this);
        this.handleChangeUntilDateTextField = this.handleChangeUntilDateTextField.bind(this);
        this.handleChangeUntilDateAfterOccurrence = this.handleChangeUntilDateAfterOccurrence.bind(this);
    }

    untilDateHandlerChange(value) {
        this.props.untilDateHandlerChange(value);
    }

    handleChangeUntilDateTextField(value) {
        this.props.handleChangeUntilDateTextField(value);
    }

    handleChangeUntilDateAfterOccurrence(value) {
        this.props.handleChangeUntilDateAfterOccurrence(value);
    }


    render() {

        if (!this.props.show) {
            return null;
        }

        const untilDate = this.props.untilDate;
        const untilDateTextFieldDisabled = (untilDate === 'on' ? false : true);
        const untilDateTextField = this.props.untilDateTextField;

        const ocurrenceTextFieldDisabled = (untilDate === 'afterOccurence' ? false : true);
        const untilDateAfterOccurrence = this.props.untilDateAfterOccurrence;
        return (
            <div>
                <span>Fin de répétition:</span><br />

                <div className="row mb-3">
                    <div className="col-auto">
                        <RadioButton name="UNTIL" id="UNTIL1" value="never" checked={untilDate === 'never'} parentHandlerChange={this.untilDateHandlerChange} label="Jamais" isInline="true" />
                    </div>
                    <div className="col-auto"></div>
                </div>

                <div className="row mb-3">
                    <div className="col-auto">
                        <RadioButton name="UNTIL" id="UNTIL2" value="on" checked={untilDate === 'on'} parentHandlerChange={this.untilDateHandlerChange} label="Le" isInline="true" wrapperElemAddClasses="pt-2" />
                    </div>
                    <div className="col-auto">
                        <InputDate name="untilDate" id="untilDate" value={untilDateTextField} handleChange={this.handleChangeUntilDateTextField} disabled={untilDateTextFieldDisabled} />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-auto">
                        <RadioButton name="UNTIL" id="UNTIL3" value="afterOccurence" checked={untilDate === 'afterOccurence'} parentHandlerChange={this.untilDateHandlerChange} label="Après" isInline="true" wrapperElemAddClasses="pt-2" />
                    </div>
                    <div className="col-auto">
                        <InputText name="ocurrence" id="ocurrence" value={untilDateAfterOccurrence} handleChange={this.handleChangeUntilDateAfterOccurrence} disabled={ocurrenceTextFieldDisabled} addClasses="d-inline mx-5 my-1 mr-sm-2 w-25" />
                        occurences
                    </div>
                </div>
            </div>
        );
    }
}