import React from 'react';
import EventSquare from './EventSquare';
import EventBorder from './EventBorder';
import { StateContext } from './AppContext';
// import '../../assets/Cell.css';

export default class EventContentCell extends React.Component {

    static contextType = StateContext;

    render() {
        // Cell height:156px ,width:156px
        const { position, nbCell } = this.props;
        const { arrEventsToDisplay } = this.context;
        let eventSquares = [];
        let eventBorders = [];
        let nbMore = 0;
        let threeMore = '';
        let displayEvent = [];
        let newDisplayEvent = [];
        if (typeof arrEventsToDisplay !== 'undefined') {
            if (typeof arrEventsToDisplay[position] !== 'undefined') {
                const events = arrEventsToDisplay[position].events;
                if (Array.isArray(events) && events.length > 0) {
                    events.forEach((event, index) => {
                        if (event.allDay === 0) {
                            eventSquares.push(
                                <EventSquare nbCell={nbCell} position={position} key={index} event={event} />
                            );
                        }
                        if (event.allDay === 1) {
                            eventBorders.push(
                                <EventBorder nbCell={nbCell} position={position} key={index} event={event} />
                            );
                        }
                    });
                    if (events.length > 3) {
                        nbMore = events.length - 3;
                        threeMore = <div className="more text-left"> {nbMore} More</div>;
                    }
                }
            }
            eventSquares.sort(function (a, b) {
                // console.log('a', Number(a.props.event.eventDaysetHourMin.split('_')[1].split(':')[0]), 'b', Number(b.props.event.eventDaysetHourMin.split('_')[1].split(':')[0]));
                return Number(a.props.event.eventDaysetHourMin.split(' ')[1].split(':')[0]) - Number(b.props.event.eventDaysetHourMin.split(' ')[1].split(':')[0]);
            });

            let eventBorderLength = eventBorders.length;
            let allEvents = [...eventBorders, ...eventSquares];

            if (allEvents.length > 0) {
                displayEvent = (allEvents.length < 4) ? allEvents : allEvents.slice(0, 3);
            }
            newDisplayEvent = displayEvent.map((t, i) => {
                let x = React.cloneElement(t, { numero: i, nbEventBorder: eventBorderLength });
                return x
            });
        }
        return (
            <React.Fragment>
                {newDisplayEvent}
                {threeMore}
            </React.Fragment >
        );
    }
}